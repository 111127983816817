import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

type PublicPagesHeaderProps = {};

const PublicPagesHeader: FunctionComponent<PublicPagesHeaderProps> = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <AppBar position="static" className="bg-white">
      <Container maxWidth="xl">
        <Toolbar disableGutters className="d-flex justify-content-between w-100">
          <Link to="/" className={"d-flex align-items-center  link-dark text-decoration-none"}>
            <img src={"/bpventures.jpg"} alt={"BP Pay"} />
            <span className={"fs-5 ms-2 fw-semibold"}>BP Pay</span>
          </Link>
          <Button variant={"contained"} color={"primary"} onClick={() => loginWithRedirect()}>
            {isAuthenticated ? "Dashboard" : "SignUp / Login"}
          </Button>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default PublicPagesHeader;

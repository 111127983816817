import DeleteIcon from "@mui/icons-material/Delete";
import { Button, CircularProgress, Tooltip } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import classnames from "classnames";
import React, { FunctionComponent, useEffect, useState } from "react";
import RemitoneOutboundHookStatus from "../../../types/RemitoneOutboundHookStatus";
import useStellarPay from "../../../useStellarPay";
import IOSSwitch from "../../IOSSwitch";

type SettingsRemitoneInboundProps = {
  className?: string;
  showSettingsRemitoneOutboundForm: boolean;
  createRemitoneOutboundHookLoading: boolean;
  Set_createRemitoneOutboundHookLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  Set_showSettingsRemitoneOutboundForm: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  Set_enableLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const SettingsRemitoneOutbound: FunctionComponent<
  SettingsRemitoneInboundProps
> = ({
  showSettingsRemitoneOutboundForm,
  createRemitoneOutboundHookLoading,
  Set_showSettingsRemitoneOutboundForm,
  Set_createRemitoneOutboundHookLoading,
  Set_enableLoading,
}) => {
  const {
    remitoneOutboundHook,
    remitoneOutboundHookStatus,
    updateRemitoneOutboundHook,
    deleteRemitoneOutboundHook,
    deleteRemitoneOutboundCredentials,
    showAlertError,
    showAlertSuccess,
  } = useStellarPay();
  const [enabled, Set_enabled] = useState<boolean>(false);
  const [showDisableDeleteDialog, Set_showDisableDeleteDialog] =
    useState(false);

  useEffect(() => {
    Set_enabled(!!remitoneOutboundHook?.enabled);
  }, [showSettingsRemitoneOutboundForm, remitoneOutboundHook]);

  return (
    <div
      className={classnames("pb-4", {
        disabled:
          remitoneOutboundHookStatus === RemitoneOutboundHookStatus.PENDING ||
          remitoneOutboundHookStatus === RemitoneOutboundHookStatus.FETCHING ||
          createRemitoneOutboundHookLoading,
      })}
    >
      <div className={"row align-items-start"}>
        <div className={"col-8"}>
          <h4 className={"fs-6 mt-1 mb-0"}>RemitONE Outbound Integration</h4>
          <h5
            style={{
              fontSize: "0.8em",
              fontWeight: "normal",
              lineHeight: "1.2rem",
              marginTop: "0.5rem",
            }}
          >
            Receive payments in RemitONE and send them as Stellar payments
          </h5>
        </div>
        <div className={"col-1"}>
          <IOSSwitch
            sx={{ m: 1 }}
            checked={enabled ?? false}
            onChange={async (event, checked) => {
              Set_enableLoading(true);
              try {
                if (remitoneOutboundHook) {
                  if (checked) {
                    await updateRemitoneOutboundHook({
                      ...remitoneOutboundHook,
                      enabled: true,
                    });
                  } else {
                    await updateRemitoneOutboundHook({
                      ...remitoneOutboundHook,
                      enabled: false,
                    });
                  }
                }
                Set_enabled(checked);
                !remitoneOutboundHook &&
                  Set_showSettingsRemitoneOutboundForm(checked);
              } catch (e) {
                showAlertError(`${e}`);
              }
              Set_enableLoading(false);
            }}
          />
        </div>
        {enabled && remitoneOutboundHook && (
          <div className={"row mt-2"}>
            <div className={"col d-flex align-items-center"}>
              <Tooltip title="Delete the RemitONE Admin WebServices Outbound credentials (username, password, PIN) from your account">
                <Button
                  variant={"contained"}
                  color={"error"}
                  endIcon={<DeleteIcon className={"mb-1"} />}
                  onClick={() => Set_showDisableDeleteDialog(true)}
                >
                  DISABLE & DELETE CREDENTIALS
                </Button>
              </Tooltip>
              {createRemitoneOutboundHookLoading && (
                <CircularProgress size={"1.5rem"} className={"ms-2"} />
              )}
              <Dialog
                open={showDisableDeleteDialog}
                onClose={() => Set_showDisableDeleteDialog(false)}
                aria-labelledby="disable-delete-dialog-title"
                aria-describedby="disable-delete-dialog-description"
              >
                <DialogTitle id="disable-delete-dialog-title">
                  {"Confirm Deleting RemitONE Outbound Credentials"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="disable-delete-dialog-description">
                    This action will disable RemitONE Outbound integration and
                    delete the RemitONE Admin WebServices Outbound credentials
                    from your account. Re-enabling RemitONE Outbound integration
                    will require you to provide the credentials again.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"error"}
                    onClick={() => Set_showDisableDeleteDialog(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color={"primary"}
                    onClick={async () => {
                      Set_showDisableDeleteDialog(false);
                      Set_createRemitoneOutboundHookLoading(true);
                      Set_enableLoading(true);
                      try {
                        await deleteRemitoneOutboundHook();
                        await deleteRemitoneOutboundCredentials();
                        showAlertSuccess(
                          "RemitONE Outbound integration disabled"
                        );
                      } catch (e) {
                        showAlertError(`${e}`);
                      }
                      Set_createRemitoneOutboundHookLoading(false);
                      Set_enableLoading(false);
                    }}
                  >
                    CONFIRM
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsRemitoneOutbound;

import { Button, Skeleton } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import ScheduledPayout from "../../../types/ScheduledPayout";
import useStellarPay from "../../../useStellarPay";
import { shortPubkey } from "../../../utils";

type ScheduledPayoutsProps = {
  onNewClick: (e: unknown) => void;
};

const ScheduledPayouts: FunctionComponent<ScheduledPayoutsProps> = ({
  onNewClick,
}) => {
  const { fetchScheduledPayouts } = useStellarPay();
  const [error, Set_error] = useState<any | undefined>(undefined);
  const [scheduledPayouts, Set_scheduledPayouts] = useState<
    ScheduledPayout[] | undefined
  >(undefined);
  useEffect(() => {
    fetchScheduledPayouts()
      .then((payouts) => Set_scheduledPayouts(payouts))
      .catch((e) => Set_error(JSON.stringify(e)));
  }, [fetchScheduledPayouts]);
  return (
    <div
      className={"col"}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      {error !== undefined ? (
        <div className={"row g-0"}>
          <div
            className={"col d-flex justify-content-center text-danger pb-4"}
          >{`${error}`}</div>
        </div>
      ) : scheduledPayouts === undefined ? (
        <Skeleton
          variant="rectangular"
          width={"90%"}
          height={200}
          className={"m-2"}
        />
      ) : (
        <>
          <div className={"row g-0"}>
            <div className={"col-6 d-flex align-items-center"}>
              <h2 className={"fs-5 fw-bold"}>Scheduled Payouts</h2>
            </div>
            <div className={"col-6"}>
              <div
                className={"d-flex justify-content-end align-items-center"}
                style={{ flexDirection: "row" }}
              >
                <Button
                  variant={"contained"}
                  onClick={(e: any) => onNewClick(e)}
                >
                  NEW SCHEDULED PAYOUT
                </Button>
              </div>
            </div>
          </div>
          <div className={"row g-0"}>
            <table className={"table text-break"}>
              <thead>
                <tr>
                  <th scope={"col"}>Stellar Account</th>
                  <th scope={"col"}>Interval</th>
                  <th scope={"col"}>Balance Threshold</th>
                  <th scope={"col"}>Account</th>
                </tr>
              </thead>
              <tbody>
                {scheduledPayouts.map((scheduledPayout) => {
                  return (
                    <tr key={scheduledPayout.id}>
                      <td>{shortPubkey(scheduledPayout.stellar_account)}</td>
                      <td>{scheduledPayout.interval}</td>
                      <td>{scheduledPayout.balance_threshold}</td>
                      <td>{scheduledPayout.account.alias}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default ScheduledPayouts;

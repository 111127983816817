import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import InvalidPayloadError from "../../../errors/InvalidPayloadError";
import RemitoneHookStatus from "../../../types/RemitoneHookStatus";
import useStellarPay from "../../../useStellarPay";
import {
  fetchHorizonAccount,
  getAssetKey,
  getHorizonUrl,
  isUsdc,
} from "../../../utils";
import { IconButtonToggle } from "../../custom-styled-components";
import IOSSwitch from "../../IOSSwitch";

const _ = require("lodash");

declare var StellarSdk: any;

type SettingsRemitoneInboundFormProps = {
  className?: string;
  showSettingsRemitoneInboundForm: boolean;
  enableLoading: boolean;
  createRemitoneHookLoading: boolean;
  onBack: (e: unknown) => void;
  Set_createRemitoneHookLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const SettingsRemitoneInboundForm: FunctionComponent<
  SettingsRemitoneInboundFormProps
> = ({
  showSettingsRemitoneInboundForm,
  enableLoading,
  createRemitoneHookLoading,
  Set_createRemitoneHookLoading,
  onBack,
}) => {
  const {
    remitoneHook,
    remitoneHookStatus,
    createRemitoneHook,
    hasRemitoneCredentials,
    createRemitoneCredentials,
    showAlertError,
    showAlertSuccess,
  } = useStellarPay();
  const [enabled, Set_enabled] = useState<boolean>(false);
  const [stellarAccount, Set_stellarAccount] = useState<string | undefined>(
    undefined
  );
  const [testnet, Set_testnet] = useState<boolean>(false);
  const [stellarAccountError, Set_stellarAccountError] = useState<
    string | undefined
  >(undefined);
  const [horizonAccount, Set_horizonAccount] = useState<any | undefined>(
    undefined
  );
  const [asset, Set_asset] = useState<string | undefined>(undefined);
  const [paymentReceivedEmailEnabled, Set_paymentReceivedEmailEnabled] =
    useState<boolean>(false);
  const [paymentReceivedEmail, Set_paymentReceivedEmail] = useState<
    string | undefined
  >(undefined);
  const [paymentInstructionsEmailEnabled, Set_paymentInstructionsEmailEnabled] =
    useState<boolean>(false);
  const [memoPrefix, Set_memoPrefix] = useState<string | undefined>(undefined);
  const [url, Set_url] = useState<string | undefined>(undefined);
  const [currency, Set_currency] = useState<string | undefined>(undefined);
  const [username, Set_username] = useState<string | undefined>(undefined);
  const [password, Set_password] = useState<string | undefined>(undefined);
  const [showPassword, Set_showPassword] = useState<boolean>(false);
  const [pin, Set_pin] = useState<string | undefined>(undefined);
  const [showPin, Set_showPin] = useState<boolean>(false);
  const [createRemitoneHookError, Set_createRemitoneHookError] = useState<
    InvalidPayloadError | undefined
  >(undefined);
  const [createRemitoneCredentialsError, Set_createRemitoneCredentialsError] =
    useState<InvalidPayloadError | undefined>(undefined);

  useEffect(() => {
    Set_enabled(!!remitoneHook?.enabled);
  }, [showSettingsRemitoneInboundForm, remitoneHook]);

  useEffect(() => {
    Set_stellarAccount(undefined);
    Set_stellarAccountError(undefined);
    Set_horizonAccount(undefined);
    Set_asset(undefined);
    Set_paymentReceivedEmailEnabled(false);
    Set_paymentReceivedEmail("");
    Set_memoPrefix(undefined);
    Set_url(undefined);
    Set_currency(undefined);
    Set_username(undefined);
    Set_password(undefined);
    Set_pin(undefined);
    Set_createRemitoneHookError(undefined);
    Set_createRemitoneCredentialsError(undefined);
    Set_createRemitoneHookLoading(false);
    Set_createRemitoneHookError(undefined);
  }, [enabled, showSettingsRemitoneInboundForm, Set_createRemitoneHookLoading]);

  useEffect(() => {
    Set_stellarAccountError(undefined);
    Set_horizonAccount(undefined);
    Set_asset(undefined);
    Set_paymentReceivedEmailEnabled(false);
    Set_paymentReceivedEmail("");
    Set_memoPrefix(undefined);
    Set_url(undefined);
    Set_currency(undefined);
    Set_username(undefined);
    Set_password(undefined);
    Set_pin(undefined);
    Set_createRemitoneHookLoading(false);
    Set_createRemitoneHookError(undefined);

    (async function () {
      if (stellarAccount) {
        fetchHorizonAccount(getHorizonUrl(testnet), stellarAccount)
          .then((account) => {
            Set_stellarAccountError(undefined);
            Set_horizonAccount(account);
            account.balances
              .filter((balance: any, index: number) => {
                return (
                  balance.asset_type === "credit_alphanum4" ||
                  balance.asset_type === "credit_alphanum12"
                );
              })
              .forEach((balance: any) => {
                if (isUsdc(testnet, balance.asset_code, balance.asset_issuer)) {
                  Set_asset(getAssetKey(balance));
                }
              });
          })
          .catch((e) => {
            if (e instanceof StellarSdk.NotFoundError) {
              Set_stellarAccountError(
                "Account does not exist in Stellar chain. If this is a testnet account, make sure to enable TESTNET"
              );
            } else {
              Set_stellarAccountError(`${e}`);
            }
          });
      } else {
        Set_horizonAccount(undefined);
      }
    })();
  }, [stellarAccount, testnet, Set_createRemitoneHookLoading]);

  const assetCodeFromAsset = (asset: string) => {
    return asset.split(":")[0];
  };

  return (
    <div
      className={classnames("pb-4  ", {
        disabled:
          remitoneHookStatus === RemitoneHookStatus.PENDING ||
          remitoneHookStatus === RemitoneHookStatus.FETCHING ||
          createRemitoneHookLoading,
      })}
    >
      <div className={"row g-0 pb-3"}>
        <div className={"col"}>
          <div className="arrow-title-container">
            <div onClick={(e: any) => onBack(e)}>
              <ArrowBackIcon className="arrow-back-icon" />
            </div>
            <h1 className={"fs-5 fw-bold"}>Connect RemitONE Inbound Account</h1>
          </div>
        </div>
      </div>

      {showSettingsRemitoneInboundForm && !enableLoading && !remitoneHook && (
        <div className={"col-8 w-100 mt-2 mb-4  "}>
          <Box borderRadius={"4px"} border={"1px solid lightgray"}>
            <div className={"row g-0 align-items-start"}>
              <div className={"col p-3"}>
                <div
                  className={
                    "d-flex justify-content-between align-items-baseline g-0"
                  }
                >
                  <TextField
                    label={"Enter the Stellar account to receive the payments"}
                    style={{ width: "80%", marginBottom: "1rem" }}
                    error={
                      !!stellarAccountError ||
                      !!createRemitoneHookError?.account
                    }
                    helperText={
                      stellarAccountError
                        ? stellarAccountError
                        : "For example: GCC...Y53. When a payment is received in this Stellar account, we'll match it to a RemitONE transfer " +
                          "and automatically mark the transfer as cleared. Payments are matched by memo, currency and amount."
                    }
                    autoFocus={true}
                    onChange={(event) => {
                      Set_createRemitoneHookError(
                        _.omit(createRemitoneHookError, "account")
                      );
                      if (
                        StellarSdk.StrKey.isValidEd25519PublicKey(
                          event.target.value
                        )
                      ) {
                        Set_stellarAccountError(undefined);
                        Set_stellarAccount(event.target.value);
                      } else if (!event.target.value) {
                        Set_stellarAccountError(undefined);
                        Set_stellarAccount(undefined);
                      } else {
                        Set_stellarAccountError(
                          "Please enter a valid Stellar account. E.g. GCC...Y53"
                        );
                        Set_stellarAccount(undefined);
                      }
                    }}
                  />

                  <FormControlLabel
                    control={
                      <IOSSwitch
                        checked={testnet}
                        onChange={async (event, checked) =>
                          Set_testnet(checked)
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="caption"
                        display="block"
                        className="mt-1"
                      >
                        TESTNET
                      </Typography>
                    }
                    labelPlacement="bottom"
                  />
                </div>

                {stellarAccount && !horizonAccount && !stellarAccountError && (
                  <LinearProgress />
                )}

                {horizonAccount && (
                  <>
                    <FormControl
                      style={{
                        width: "99.5%",
                        marginBottom: "1rem",
                      }}
                    >
                      <InputLabel id="remitone-asset-label">
                        Stellar Asset
                      </InputLabel>
                      <Select
                        labelId="remitone-asset-label"
                        id="remitone-asset"
                        value={asset ?? ""}
                        label={
                          createRemitoneHookError?.asset_code
                            ? createRemitoneHookError?.asset_code
                            : createRemitoneHookError?.asset_issuer
                            ? createRemitoneHookError?.asset_issuer
                            : "Stellar Asset"
                        }
                        error={
                          !!createRemitoneHookError?.asset_code ||
                          !!createRemitoneHookError?.asset_issuer
                        }
                        onChange={(e) => {
                          Set_createRemitoneHookError(
                            _.omit(
                              _.omit(createRemitoneHookError, "asset_issuer"),
                              "asset_code"
                            )
                          );
                          Set_asset(e.target.value);
                        }}
                      >
                        {horizonAccount.balances
                          .filter((balance: any, index: number) => {
                            return (
                              balance.asset_type === "credit_alphanum4" ||
                              balance.asset_type === "credit_alphanum12"
                            );
                          })
                          .map((balance: any, index: number) => {
                            const value = getAssetKey(balance);
                            return (
                              <MenuItem value={value} key={index}>
                                {balance.asset_code}
                                &nbsp;
                                <span className={"text-muted"}>
                                  {balance.asset_issuer}
                                </span>
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>Stellar asset</FormHelperText>
                    </FormControl>

                    <div
                      className={
                        "d-flex align-items-center justify-content-start"
                      }
                      style={{ width: "99.5%", marginBottom: "1rem" }}
                    >
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={paymentReceivedEmailEnabled ?? false}
                        onChange={async (event, checked) =>
                          Set_paymentReceivedEmailEnabled(checked)
                        }
                      />
                      <span>
                        Send email notification when a
                        {asset ? ` ${assetCodeFromAsset(asset)}` : " "} Stellar
                        payment is received
                      </span>
                    </div>
                    {paymentReceivedEmailEnabled && (
                      <TextField
                        required
                        label={"Email to receive payment notification"}
                        helperText={
                          createRemitoneHookError?.payment_received_notif_email
                            ? createRemitoneHookError?.payment_received_notif_email
                            : "E.g. myemail@mydomain.com"
                        }
                        error={
                          !!createRemitoneHookError?.payment_received_notif_email
                        }
                        style={{ width: "99.5%", marginBottom: "1rem" }}
                        onChange={(e) => {
                          Set_createRemitoneHookError(
                            _.omit(
                              createRemitoneHookError,
                              "payment_received_notif_email"
                            )
                          );
                          Set_paymentReceivedEmail(e.target.value);
                        }}
                      />
                    )}
                    <div
                      className={
                        "d-flex align-items-center justify-content-start"
                      }
                      style={{ width: "99.5%", marginBottom: "1rem" }}
                    >
                      <IOSSwitch
                        sx={{ m: 1 }}
                        checked={paymentInstructionsEmailEnabled ?? false}
                        onChange={async (event, checked) =>
                          Set_paymentInstructionsEmailEnabled(checked)
                        }
                      />
                      <span>Send payment instructions to remitter email</span>
                    </div>
                    <div className={"row g-0 align-items-start"}>
                      <TextField
                        required
                        label={"Admin WebServices URL"}
                        helperText={
                          createRemitoneCredentialsError?.url
                            ? createRemitoneCredentialsError?.url
                            : "E.g. https://test4.remit.by/mycompanytest/ws"
                        }
                        error={!!createRemitoneCredentialsError?.url}
                        style={{ width: "99.5%", marginBottom: "1rem" }}
                        onChange={(e) => {
                          Set_createRemitoneCredentialsError(
                            _.omit(createRemitoneCredentialsError, "url")
                          );
                          Set_url(e.target.value);
                        }}
                      />
                      <div className="row g-0">
                        <div className="col-12 col-md-6 ">
                          <TextField
                            required
                            label={"Currency"}
                            helperText={
                              createRemitoneHookError?.r1_currency
                                ? createRemitoneHookError?.r1_currency
                                : `RemitONE currency name corresponding to Stellar asset ${
                                    asset?.split(":")[0]
                                  }. E.g. USD, EUR, IDR, JPY.`
                            }
                            style={{ width: "99.5%", marginBottom: "1rem" }}
                            error={!!createRemitoneHookError?.r1_currency}
                            onChange={(e) => {
                              Set_createRemitoneHookError(
                                _.omit(createRemitoneHookError, "r1_currency")
                              );
                              Set_currency(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 pe-md-1">
                        <TextField
                          required
                          label={"Admin WebServices Username"}
                          style={{ width: "99.5%", marginBottom: "1rem" }}
                          error={!!createRemitoneCredentialsError?.username}
                          helperText={
                            createRemitoneCredentialsError?.username
                              ? createRemitoneCredentialsError?.username
                              : "RemitONE Admin WebServices Username"
                          }
                          onChange={(e) => {
                            Set_createRemitoneCredentialsError(
                              _.omit(createRemitoneCredentialsError, "username")
                            );
                            Set_username(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-6 ps-md-1">
                        <FormControl
                          style={{ width: "99.5%", marginBottom: "1rem" }}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="remitone-password">
                            {!!createRemitoneCredentialsError?.password ? (
                              <span className={"text-danger"}>
                                {createRemitoneCredentialsError?.password}
                              </span>
                            ) : (
                              <span>Admin WebServices Password</span>
                            )}
                          </InputLabel>
                          <OutlinedInput
                            required
                            id="remitone-password"
                            type={showPassword ? "text" : "password"}
                            aria-describedby="remitone-password-helper-text"
                            onChange={(e) => {
                              Set_createRemitoneCredentialsError(
                                _.omit(
                                  createRemitoneCredentialsError,
                                  "password"
                                )
                              );
                              Set_password(e.target.value);
                            }}
                            error={!!createRemitoneCredentialsError?.password}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle remitone password visibility"
                                  onClick={() =>
                                    Set_showPassword((prevValue) => !prevValue)
                                  }
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                  color={"primary"}
                                  sx={{ borderRadius: 2 }}
                                >
                                  {showPassword ? (
                                    <IconButtonToggle>Hide</IconButtonToggle>
                                  ) : (
                                    <IconButtonToggle>Show</IconButtonToggle>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Admin WebServices Password"
                          />
                          <FormHelperText id={"remitone-password-helper-text"}>
                            {createRemitoneCredentialsError?.password ? (
                              <span className={"text-danger"}>
                                {createRemitoneCredentialsError.password}
                              </span>
                            ) : (
                              "RemitONE Admin WebServices Password"
                            )}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className="col-12 col-md-6 ">
                        <FormControl
                          style={{ width: "99.5%", marginBottom: "1rem" }}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="remitone-pin">
                            {!!createRemitoneCredentialsError?.pin ? (
                              <span className={"text-danger"}>
                                {createRemitoneCredentialsError.pin}
                              </span>
                            ) : (
                              <span>Admin WebServices PIN</span>
                            )}
                          </InputLabel>
                          <OutlinedInput
                            required
                            id="remitone-pin"
                            type={showPin ? "text" : "password"}
                            aria-describedby="remitone-pin-helper-text"
                            onChange={(e) => {
                              Set_createRemitoneCredentialsError(
                                _.omit(createRemitoneCredentialsError, "pin")
                              );
                              Set_pin(e.target.value);
                            }}
                            error={!!createRemitoneCredentialsError?.pin}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle remitone pin visibility"
                                  onClick={() =>
                                    Set_showPin((prevValue) => !prevValue)
                                  }
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                  color={"primary"}
                                  sx={{ borderRadius: 2 }}
                                >
                                  {showPin ? (
                                    <IconButtonToggle>Hide</IconButtonToggle>
                                  ) : (
                                    <IconButtonToggle>Show</IconButtonToggle>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Admin WebServices PIN"
                          />
                          <FormHelperText id={"remitone-pin-helper-text"}>
                            {createRemitoneCredentialsError?.pin ? (
                              <span className={"text-danger"}>
                                {createRemitoneCredentialsError.pin}
                              </span>
                            ) : (
                              "RemitONE Admin WebServices PIN"
                            )}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <TextField
                        label={"Stellar memo prefix (optional)"}
                        helperText={
                          createRemitoneCredentialsError?.memo_prefix
                            ? createRemitoneCredentialsError?.memo_prefix
                            : "Only match Stellar transactions in which the memo text begins with this prefix"
                        }
                        error={!!createRemitoneCredentialsError?.memo_prefix}
                        style={{ width: "99.5%", marginBottom: "1rem" }}
                        onChange={(e) => {
                          Set_createRemitoneCredentialsError(
                            _.omit(
                              createRemitoneCredentialsError,
                              "memo_prefix"
                            )
                          );
                          Set_memoPrefix(e.target.value);
                        }}
                      />
                    </div>
                    <div className="d-grid gap-2 d-md-flex justify-content-end align-items-center">
                      {createRemitoneHookLoading && (
                        <CircularProgress size={"1.5rem"} className={"me-2"} />
                      )}

                      <Button
                        variant={"contained"}
                        color={"error"}
                        onClick={() => {
                          Set_enabled(false);
                          onBack(false);
                        }}
                      >
                        CANCEL
                      </Button>
                      <Button
                        variant={"contained"}
                        onClick={async () => {
                          Set_createRemitoneHookLoading(true);

                          let hasCredentials = false;
                          try {
                            hasCredentials = await hasRemitoneCredentials();
                          } catch (e) {
                            Set_createRemitoneHookLoading(false);
                            showAlertError(`${e}`);
                            return;
                          }
                          if (!hasCredentials) {
                            try {
                              await createRemitoneCredentials({
                                url: url ?? "",
                                username: username ?? "",
                                password: password ?? "",
                                pin: pin ?? "",
                              });
                            } catch (e) {
                              Set_createRemitoneHookLoading(false);
                              if (e instanceof InvalidPayloadError) {
                                Set_createRemitoneCredentialsError(e);
                              } else {
                                showAlertError(`${e}`);
                              }
                              return;
                            }
                          }

                          try {
                            await createRemitoneHook({
                              enabled: true,
                              testnet: testnet,
                              account: stellarAccount ?? "",
                              asset_code: asset ? asset?.split(":")[0] : "",
                              asset_issuer: asset ? asset.split(":")[1] : "",
                              r1_currency: currency ?? "",
                              memo_prefix: memoPrefix ?? "",
                              payment_received_email_enabled:
                                paymentReceivedEmailEnabled,
                              payment_received_email:
                                paymentReceivedEmail ?? "",
                              payment_instructions_email_enabled:
                                paymentInstructionsEmailEnabled,
                            });
                            Set_createRemitoneHookLoading(false);
                            showAlertSuccess(
                              "RemitONE Inbound integration enabled"
                            );
                            onBack(true);
                          } catch (e) {
                            Set_createRemitoneHookLoading(false);
                            if (e instanceof InvalidPayloadError) {
                              Set_createRemitoneHookError(e);
                            } else {
                              showAlertError(`${e}`);
                            }
                          }
                        }}
                      >
                        DONE
                      </Button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default SettingsRemitoneInboundForm;

import { Navigate } from "react-router-dom";
import * as React from "react";
import useStellarPay from "../useStellarPay";
import RemitoneHookStatus from "../types/RemitoneHookStatus";
import { Skeleton } from "@mui/material";
import RemitonePayments from "../components/RemitonePayments";
import RemitoneOutboundHookStatus from "../types/RemitoneOutboundHookStatus";
import RemitoneOutboundPayments from "../components/RemitoneOutboundPayments";

const RemitonePage = () => {
  const {
    remitoneHook,
    remitoneHookStatus,
    remitoneHookError,
    remitoneOutboundHook,
    remitoneOutboundHookStatus,
    remitoneOutboundHookError,
  } = useStellarPay();
  return (
    <>
      {remitoneHookStatus === RemitoneHookStatus.ERROR && remitoneHookError && (
        <div className={"row g-0"}>
          <div className={"col px-5 pt-4 pb-0"}>
            <span className={"text-danger"}>{remitoneHookError}</span>
          </div>
        </div>
      )}
      {remitoneOutboundHookStatus === RemitoneOutboundHookStatus.ERROR &&
        remitoneOutboundHookError && (
          <div className={"row g-0"}>
            <div className={"col px-5 pt-4 pb-0"}>
              <span className={"text-danger"}>{remitoneOutboundHookError}</span>
            </div>
          </div>
        )}
      {remitoneHookStatus === RemitoneHookStatus.FETCHED &&
        !remitoneHook?.enabled &&
        remitoneOutboundHookStatus === RemitoneOutboundHookStatus.FETCHED &&
        !remitoneOutboundHook?.enabled && <Navigate to="/wallet" />}
      {(remitoneHookStatus === RemitoneHookStatus.PENDING ||
        remitoneHookStatus === RemitoneHookStatus.FETCHING) && (
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={200}
          className={"m-4"}
        />
      )}
      {remitoneHookStatus === RemitoneHookStatus.FETCHED &&
        remitoneHook?.enabled && (
          <RemitonePayments className={"component-card col"} />
        )}
      {(remitoneOutboundHookStatus === RemitoneOutboundHookStatus.PENDING ||
        remitoneOutboundHookStatus === RemitoneOutboundHookStatus.FETCHING) && (
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={200}
          className={"m-4"}
        />
      )}
      {remitoneOutboundHookStatus === RemitoneOutboundHookStatus.FETCHED &&
        remitoneOutboundHook?.enabled && (
          <RemitoneOutboundPayments className={"component-card col"} />
        )}
    </>
  );
};

export default RemitonePage;

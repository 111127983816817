import React, { FunctionComponent } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { generateApikey } from "../auth0_utils";
import { Button } from "@mui/material";

type ButtonGenerateApiKeyProps = {
  label: string;
  onGenerating: () => void;
  onSuccess: (apiKey: string) => void;
  onError: (e: unknown) => void;
};

const ButtonGenerateApiKey: FunctionComponent<ButtonGenerateApiKeyProps> = ({
  label,
  onGenerating,
  onSuccess,
  onError,
}) => {
  const { getAccessTokenSilently } = useAuth0();

  return (
    <Button
      variant={"contained"}
      onClick={async () => {
        onGenerating();
        try {
          const token = await getAccessTokenSilently();
          const apikey = await generateApikey(token);
          onSuccess(apikey);
        } catch (e) {
          onError(e);
        }
      }}
    >
      {label}
    </Button>
  );
};

export default ButtonGenerateApiKey;

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Skeleton } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import useStellarPay from "../useStellarPay";
import {
  ellipsisString,
  exportToCsv,
  memoizedFetchStellarExpertAccount,
  shortPubkey,
} from "../utils";
import { ReactComponent as Stellar } from "../Stellar.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faShuffle } from "@fortawesome/free-solid-svg-icons";
import RemitonePayment from "../types/RemitonePayment";
import RefreshIcon from "@mui/icons-material/Refresh";

const RemitonePayments = ({ ...props }) => {
  const { ...rest } = props;
  const { remitoneHook, fetchRemitonePayments } = useStellarPay();
  const [remitonePayments, Set_remitonePayments] = useState<
    RemitonePayment[] | undefined
  >(undefined);
  const [stellarExpertAccounts, Set_stellarExpertAccounts] = useState<
    any | undefined
  >(undefined);
  const [allFetched, Set_allFetched] = useState<boolean>(false);
  const [error, Set_error] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (
      remitoneHook?.enabled &&
      remitonePayments === undefined &&
      error === undefined
    ) {
      fetchRemitonePayments()
        .then((remitonePayments) => {
          Set_remitonePayments(remitonePayments);
          if (remitoneHook.testnet) {
            Set_allFetched(true);
          }
        })
        .catch((e) => Set_error(JSON.stringify(e)));
    }
  }, [remitoneHook, remitonePayments, error, fetchRemitonePayments]);

  useEffect(() => {
    if (
      remitoneHook?.enabled &&
      remitonePayments !== undefined &&
      error === undefined
    ) {
      if (!remitoneHook.testnet && stellarExpertAccounts === undefined) {
        Set_stellarExpertAccounts({});
        remitonePayments.forEach((remitonePayment) => {
          for (const account of [
            remitonePayment.horizon_payment.from_account,
            remitonePayment.horizon_payment.to_account,
          ]) {
            memoizedFetchStellarExpertAccount(account)
              .then((stellarExpertAccount) => {
                Set_stellarExpertAccounts((prev: any) => {
                  return {
                    ...prev,
                    [account]: stellarExpertAccount,
                  };
                });
              })
              .catch((e) => Set_error(JSON.stringify(e)));
          }
        });
      }
      if (stellarExpertAccounts !== undefined) {
        let _allFetched = true;
        remitonePayments.forEach((remitonePayment) => {
          for (const account of [
            remitonePayment.horizon_payment.from_account,
            remitonePayment.horizon_payment.to_account,
          ]) {
            if (!(account in stellarExpertAccounts)) {
              _allFetched = false;
            }
          }
        });
        if (_allFetched) {
          Set_allFetched(true);
        }
      }
    }
  }, [stellarExpertAccounts, remitoneHook, remitonePayments, error]);

  const refresh = () => {
    if (remitoneHook?.enabled) {
      Set_allFetched(false);
      fetchRemitonePayments()
        .then((remitonePayments) => {
          Set_remitonePayments(remitonePayments);
          if (remitoneHook.testnet) {
            Set_allFetched(true);
          }
        })
        .catch((e) => Set_error(JSON.stringify(e)));
    }
  };

  const exportRemitonePaymentsToCsv = () => {
    if (remitonePayments) {
      const payments = [];
      for (const payment of remitonePayments) {
        payments.push({
          id: payment.id,
          created_at: payment.horizon_payment.created_at,
          status: payment.status,
          from_account: payment.horizon_payment.from_account,
          to_account: payment.horizon_payment.to_account,
          type: payment.horizon_payment.type,
          asset_type: payment.horizon_payment.asset_type,
          asset_code: payment.horizon_payment.asset_code,
          asset_issuer: payment.horizon_payment.asset_issuer,
          amount: payment.horizon_payment.amount,
          memo: payment.horizon_payment.transaction.memo,
          transaction_hash: payment.horizon_payment.transaction_hash,
          stellar_payment_id: payment.horizon_payment.stellar_payment_id,
        });
      }

      const now = new Date();
      const filename = `remitone-inbound-transactions-${dateFormat(
        now,
        "yyyy-mm-dd_HH-MM-ss"
      )}.csv`;
      exportToCsv(filename, payments);
    }
  };

  return (
    <div {...rest}>
      <div className={"row g-0"}>
        <div className={"col-3 pb-2"}>
          <h2 className={"fs-5 p-3"}>Inbound Transactions</h2>
        </div>
        <div className={"col-9"}>
          {allFetched && (
            <div
              className={"d-flex justify-content-end align-items-center"}
              style={{ flexDirection: "row" }}
            >
              <RefreshIcon
                className={"me-3"}
                style={{ cursor: "pointer" }}
                onClick={() => refresh()}
              />
              {remitonePayments !== undefined &&
                remitonePayments.length > 0 && (
                  <SaveAltIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => exportRemitonePaymentsToCsv()}
                  />
                )}
            </div>
          )}
        </div>
      </div>
      {error ? (
        <div className={"row g-0"}>
          <div className={"col d-flex justify-content-center text-danger pb-4"}>
            {`${error}`}
          </div>
        </div>
      ) : (
        <>
          {remitoneHook === undefined ||
          remitonePayments === undefined ||
          !allFetched ? (
            <div className={"row g-0"}>
              <div className={"col"}>
                <Skeleton
                  variant="rectangular"
                  width={"95%"}
                  height={50}
                  className={"my-2 ms-3"}
                />
                <Skeleton
                  variant="rectangular"
                  width={"95%"}
                  height={50}
                  className={"my-2 ms-3"}
                />
              </div>
            </div>
          ) : (
            <div className={"row g-0"}>
              <div className={"col ps-3 pe-2"}>
                <table className={"table"}>
                  <thead>
                    <tr>
                      <th scope={"col"}>Date</th>
                      <th scope={"col"}>Status</th>
                      <th scope={"col"}>From</th>
                      <th scope={"col"}>To</th>
                      <th scope={"col"}>Asset</th>
                      <th scope={"col"}>Amount</th>
                      <th scope={"col"}>Memo</th>
                      <th scope={"col"}>Links</th>
                    </tr>
                  </thead>
                  <tbody>
                    {remitonePayments
                      .filter((payment) => {
                        return (
                          (payment.horizon_payment.asset_type ===
                            "credit_alphanum4" ||
                            payment.horizon_payment.asset_type ===
                              "credit_alphanum12" ||
                            payment.horizon_payment.asset_type === "native") &&
                          payment.horizon_payment.from_account !==
                            payment.horizon_payment.to_account &&
                          payment.horizon_payment.to_account ===
                            remitoneHook.account //TODO allow filtering received or all payments
                        );
                      })
                      .map((payment, index) => {
                        const fromAccountName = remitoneHook.testnet
                          ? undefined
                          : stellarExpertAccounts[
                              payment.horizon_payment.from_account
                            ].name;
                        const toAccountName = remitoneHook.testnet
                          ? undefined
                          : stellarExpertAccounts[
                              payment.horizon_payment.to_account
                            ].name;
                        return (
                          <tr key={index}>
                            <td>
                              <div
                                className={
                                  "row g-0 d-flex align-items-center justify-content-between"
                                }
                              >
                                <div style={{ width: "80%" }}>
                                  {payment.horizon_payment.created_at}
                                </div>
                                {payment.horizon_payment.from_account ===
                                  remitoneHook.account && (
                                  <div
                                    style={{ width: 15 }}
                                    className={
                                      "d-flex p-0 align-items-center justify-content-end"
                                    }
                                  >
                                    <Stellar
                                      width={15}
                                      height={15}
                                      fill={"darkslategray"}
                                      title={
                                        payment.horizon_payment.from_account ===
                                        remitoneHook.account
                                          ? "This is your Wallet account"
                                          : ""
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>{payment.status}</td>
                            <td style={{ paddingRight: 0 }}>
                              <div className={"col"}>
                                <div
                                  className={
                                    "row g-0 d-flex align-items-center justify-content-between"
                                  }
                                >
                                  <div
                                    className={
                                      "d-flex p-0 align-items-center justify-content-between"
                                    }
                                    style={{ width: "70%" }}
                                  >
                                    <span>
                                      {shortPubkey(
                                        payment.horizon_payment.from_account
                                      )}
                                    </span>
                                    {payment.horizon_payment.type ===
                                      "payment" && (
                                      <div
                                        style={{ width: 15, display: "block" }}
                                        className={
                                          "d-flex p-0 ps-1 align-items-center"
                                        }
                                      >
                                        <FontAwesomeIcon
                                          size={"1x"}
                                          icon={faArrowRight}
                                          title={"Payment"}
                                        />
                                      </div>
                                    )}
                                    {payment.horizon_payment.type ===
                                      "path_payment_strict_send" && (
                                      <div
                                        style={{ width: 15 }}
                                        className={
                                          "d-flex p-0 ps-1 align-items-center"
                                        }
                                      >
                                        <FontAwesomeIcon
                                          size={"1x"}
                                          icon={faShuffle}
                                          title={"Path Payment"}
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div
                                    className={
                                      "d-flex p-0 align-items-center justify-content-end"
                                    }
                                    style={{ width: "30%" }}
                                  >
                                    {payment.horizon_payment.to_account ===
                                      remitoneHook.account && (
                                      <div
                                        style={{ width: 20 }}
                                        className={
                                          "d-flex p-0 align-items-center justify-content-end"
                                        }
                                      >
                                        <Stellar
                                          width={15}
                                          height={15}
                                          fill={"darkslategray"}
                                          title={
                                            payment.horizon_payment
                                              .to_account ===
                                            remitoneHook.account
                                              ? "This is your Wallet account"
                                              : ""
                                          }
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {fromAccountName && (
                                  <div
                                    className={
                                      "row g-0 d-flex align-items-center justify-content-between"
                                    }
                                    title="Account name"
                                  >
                                    <span
                                      className={"text-muted"}
                                      style={{ fontSize: "0.8em" }}
                                    >
                                      {ellipsisString(fromAccountName, 50)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className={"col"}>
                                <div
                                  className={
                                    "row g-0 d-flex align-items-center justify-content-between"
                                  }
                                  title="To Stellar account"
                                >
                                  {shortPubkey(
                                    payment.horizon_payment.to_account
                                  )}
                                </div>
                                {toAccountName && (
                                  <div
                                    className={
                                      "row g-0 d-flex align-items-center justify-content-between"
                                    }
                                    title="Account name"
                                  >
                                    <span
                                      className={"text-muted"}
                                      style={{ fontSize: "0.8em" }}
                                    >
                                      {ellipsisString(toAccountName, 50)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td>
                              {payment.horizon_payment.asset_type ===
                              "native" ? (
                                "XLM"
                              ) : (
                                <span>
                                  {payment.horizon_payment.asset_code}
                                </span>
                              )}
                            </td>
                            <td>{payment.horizon_payment.amount}</td>
                            <td>{payment.horizon_payment.transaction.memo}</td>
                            <td>
                              <a
                                href={`https://stellar.expert/explorer/${
                                  payment.horizon_payment.testnet
                                    ? "testnet"
                                    : "public"
                                }/op/${
                                  payment.horizon_payment.stellar_payment_id
                                }`}
                                rel="noopener noreferrer"
                                target="_blank"
                                title={"View in Stellar.Expert"}
                              >
                                <img
                                  alt="Stellar Expert"
                                  height={18}
                                  src={"/stellar-expert-blue.svg"}
                                />
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RemitonePayments;

import { Button, Skeleton } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import PayoutAccount from "../../../types/PayoutAccount";
import useStellarPay from "../../../useStellarPay";
import { capitalizeFirstLetters } from "../../../utils";

type PayoutAccountsProps = {
  onAddClick: (e: unknown) => void;
};

const PayoutAccounts: FunctionComponent<PayoutAccountsProps> = ({
  onAddClick,
}) => {
  const { fetchPayoutAccounts } = useStellarPay();
  const [error, Set_error] = useState<any | undefined>(undefined);
  const [payoutAccounts, Set_payoutAccounts] = useState<
    PayoutAccount[] | undefined
  >(undefined);
  useEffect(() => {
    fetchPayoutAccounts()
      .then((payoutAccounts) => Set_payoutAccounts(payoutAccounts))
      .catch((e) => Set_error(JSON.stringify(e)));
  }, [fetchPayoutAccounts]);
  return (
    <div
      className={"col"}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      {error !== undefined ? (
        <div className={"row g-0"}>
          <div
            className={"col d-flex justify-content-center text-danger pb-4"}
          >{`${error}`}</div>
        </div>
      ) : payoutAccounts === undefined ? (
        <Skeleton
          variant="rectangular"
          width={"90%"}
          height={200}
          className={"m-2"}
        />
      ) : (
        <>
          <div className={"row g-0 pb-2"}>
            <div className={"col-6 d-flex align-items-center"}>
              <h2 className={"fs-5 fw-bold"}>Payout Accounts</h2>
            </div>
            <div className={"col-6"}>
              <div
                className={"d-flex justify-content-end align-items-center"}
                style={{ flexDirection: "row" }}
              >
                <Button
                  variant={"contained"}
                  onClick={(e: any) => onAddClick(e)}
                >
                  ADD PAYOUT ACCOUNT
                </Button>
              </div>
            </div>
          </div>
          <div className={"row g-0"}>
            <table className={"table text-break"}>
              <thead>
                <tr>
                  <th scope={"col"}>Alias</th>
                  <th scope={"col"}>Type</th>
                  <th scope={"col"}>Account</th>
                  <th scope={"col"}>Status</th>
                </tr>
              </thead>
              <tbody>
                {payoutAccounts.map((payoutAccount) => {
                  let status: string = payoutAccount.status.toString();
                  if (
                    status === "PENDING" ||
                    status === "CREATING" ||
                    status === "PENDING_EXTERNAL" ||
                    status === "CHECKING_EXTERNAL"
                  ) {
                    status = "Processing";
                  } else if (status === "CONFIRMED") {
                    status = capitalizeFirstLetters(
                      payoutAccount.status.toLowerCase().replaceAll("_", " ")
                    );
                  }
                  return (
                    <tr key={payoutAccount.alias}>
                      <td>{payoutAccount.alias}</td>
                      <td>
                        {capitalizeFirstLetters(
                          payoutAccount.type.toLowerCase().replaceAll("_", " ")
                        )}
                      </td>
                      {payoutAccount.type === "BANK_WIRE" && (
                        <td>{payoutAccount.bank_account_number}</td>
                      )}
                      <td>{status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default PayoutAccounts;

import axios from "axios";
import React, { useCallback, useEffect, useMemo, useReducer } from "react";
import { invalidPayloadErrorFromJsonResponse } from "./errors/InvalidPayloadError";
import StellarPayContext from "./StellarPayContext";
import StellarPayState, { initialStellarPayState } from "./StellarPayState";
import AccountSnapshot from "./types/AccountSnapshot";
import BankAccount from "./types/BankAccount";
import CreateBankAccount from "./types/CreateBankAccount";
import CreatePayoutAccount from "./types/CreatePayoutAccount";
import CreateRemitoneCredentials from "./types/CreateRemitoneCredentials";
import CreateRemitoneHook from "./types/CreateRemitoneHook";
import CreateRemitoneOutboundCredentials from "./types/CreateRemitoneOutboundCredentials";
import CreateRemitoneOutboundHook from "./types/CreateRemitoneOutboundHook";
import CreateScheduledPayout from "./types/CreateScheduledPayout";
import CreateWallet from "./types/CreateWallet";
import Payment from "./types/Payment";
import PayoutAccount from "./types/PayoutAccount";
import Profile from "./types/Profile";
import ProfileStatus from "./types/ProfileStatus";
import RemitoneHook from "./types/RemitoneHook";
import RemitoneHookStatus from "./types/RemitoneHookStatus";
import RemitoneOutboundHook from "./types/RemitoneOutboundHook";
import RemitoneOutboundHookStatus from "./types/RemitoneOutboundHookStatus";
import RemitoneOutboundPayment from "./types/RemitoneOutboundPayment";
import RemitonePayment from "./types/RemitonePayment";
import ScheduledPayout from "./types/ScheduledPayout";
import Wallet from "./types/Wallet";
import WalletStatus from "./types/WalletStatus";

type StellarPayAction =
  | { type: "WALLET_FETCHING" }
  | { type: "WALLET_CREATING" }
  | {
      type: "WALLET_FETCHED";
      wallet?: Wallet;
    }
  | {
      type: "WALLET_ERROR";
      walletError: any;
    }
  | { type: "REMITONEHOOK_FETCHING" }
  | { type: "REMITONEHOOK_CREATING" }
  | {
      type: "REMITONEHOOK_FETCHED";
      remitoneHook?: RemitoneHook;
    }
  | {
      type: "REMITONEHOOK_ERROR";
      remitoneHookError: any;
    }
  | { type: "REMITONEHOOK_OUTBOUND_FETCHING" }
  | { type: "REMITONEHOOK_OUTBOUND_CREATING" }
  | {
      type: "REMITONEHOOK_OUTBOUND_FETCHED";
      remitoneOutboundHook?: RemitoneOutboundHook;
    }
  | {
      type: "REMITONEHOOK_OUTBOUND_ERROR";
      remitoneOutboundHookError: any;
    }
  | { type: "PROFILE_FETCHING" }
  | {
      type: "PROFILE_FETCHED";
      profile?: Profile;
    }
  | {
      type: "PROFILE_ERROR";
      profileError: any;
    }
  | {
      type: "ALERT_SUCCESS";
      alertSuccess: string;
    }
  | {
      type: "ALERT_ERROR";
      alertError: string;
    }
  | {
      type: "ALERT_RESET";
    };

export const reducer = (
  state: StellarPayState,
  action: StellarPayAction
): StellarPayState => {
  switch (action.type) {
    case "WALLET_FETCHING":
      return {
        ...state,
        walletStatus: WalletStatus.FETCHING,
        walletError: undefined,
        wallet: undefined,
      };
    case "WALLET_CREATING":
      return {
        ...state,
        walletStatus: WalletStatus.CREATING,
        walletError: undefined,
        wallet: undefined,
      };
    case "WALLET_FETCHED":
      return {
        ...state,
        walletStatus: WalletStatus.FETCHED,
        walletError: undefined,
        wallet: action.wallet,
      };
    case "WALLET_ERROR":
      return {
        ...state,
        walletStatus: WalletStatus.ERROR,
        walletError: action.walletError,
        wallet: undefined,
      };
    case "REMITONEHOOK_FETCHING":
      return {
        ...state,
        remitoneHookStatus: RemitoneHookStatus.FETCHING,
        remitoneHookError: undefined,
        remitoneHook: undefined,
      };
    case "REMITONEHOOK_CREATING":
      return {
        ...state,
        remitoneHookStatus: RemitoneHookStatus.CREATING,
        remitoneHookError: undefined,
        remitoneHook: undefined,
      };
    case "REMITONEHOOK_FETCHED":
      return {
        ...state,
        remitoneHookStatus: RemitoneHookStatus.FETCHED,
        remitoneHookError: undefined,
        remitoneHook: action.remitoneHook,
      };
    case "REMITONEHOOK_ERROR":
      return {
        ...state,
        remitoneHookStatus: RemitoneHookStatus.ERROR,
        remitoneHookError: action.remitoneHookError,
        remitoneHook: undefined,
      };
    case "REMITONEHOOK_OUTBOUND_FETCHING":
      return {
        ...state,
        remitoneOutboundHookStatus: RemitoneOutboundHookStatus.FETCHING,
        remitoneOutboundHookError: undefined,
        remitoneOutboundHook: undefined,
      };
    case "REMITONEHOOK_OUTBOUND_CREATING":
      return {
        ...state,
        remitoneOutboundHookStatus: RemitoneOutboundHookStatus.CREATING,
        remitoneOutboundHookError: undefined,
        remitoneOutboundHook: undefined,
      };
    case "REMITONEHOOK_OUTBOUND_FETCHED":
      return {
        ...state,
        remitoneOutboundHookStatus: RemitoneOutboundHookStatus.FETCHED,
        remitoneOutboundHookError: undefined,
        remitoneOutboundHook: action.remitoneOutboundHook,
      };
    case "REMITONEHOOK_OUTBOUND_ERROR":
      return {
        ...state,
        remitoneOutboundHookStatus: RemitoneOutboundHookStatus.ERROR,
        remitoneOutboundHookError: action.remitoneOutboundHookError,
        remitoneOutboundHook: undefined,
      };
    case "PROFILE_FETCHING":
      return {
        ...state,
        profileStatus: ProfileStatus.FETCHING,
        profileError: undefined,
        profile: undefined,
      };
    case "PROFILE_FETCHED":
      return {
        ...state,
        profileStatus: ProfileStatus.FETCHED,
        profileError: undefined,
        profile: action.profile,
      };
    case "PROFILE_ERROR":
      return {
        ...state,
        profileStatus: ProfileStatus.ERROR,
        profileError: action.profileError,
        profile: undefined,
      };
    case "ALERT_SUCCESS":
      return {
        ...state,
        alertSuccess: action.alertSuccess,
      };
    case "ALERT_ERROR":
      return {
        ...state,
        alertError: action.alertError,
      };
    case "ALERT_RESET":
      return {
        ...state,
        alertSuccess: undefined,
        alertError: undefined,
      };
  }
};

export interface StellarPayProviderProps {
  children?: React.ReactNode;
  accessToken: string;
}

type RequestApiJsonWithAccessTokenParams = {
  body?: any;
  handleInvalidPayload?: boolean;
  ignoreResponseData?: boolean;
};

const StellarPayProvider = (props: StellarPayProviderProps): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialStellarPayState);
  console.log(props.accessToken);

  const requestApiJsonWithAccessToken = useCallback(
    async (
      method: string,
      url: string,
      params?: RequestApiJsonWithAccessTokenParams
    ): Promise<any> => {
      if (!url.startsWith("http")) {
        if (!url.startsWith("/")) {
          url = "/" + url;
        }
        url = `${process.env.REACT_APP_API_URL}${url}`;
      }
      try {
        const headers: any = {
          Accept: "application/json",
          Authorization: `Bearer ${props.accessToken}`,
        };
        if (!!params?.body) {
          headers["Content-Type"] = "application/json";
        }
        const resp = await fetch(url, {
          method: method,
          headers: headers,
          body: JSON.stringify(params?.body),
        });
        if (params?.ignoreResponseData) {
          if (!resp.ok) {
            return Promise.reject(resp);
          }
          return undefined;
        }
        const respJson = await resp.json();
        if (!resp.ok) {
          if (respJson.detail) {
            return Promise.reject(respJson.detail);
          } else if (!!params?.body && params?.handleInvalidPayload) {
            const error = invalidPayloadErrorFromJsonResponse(
              params.body,
              respJson
            );
            return Promise.reject(error);
          }
          return Promise.reject(respJson);
        } else {
          return respJson;
        }
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    [props.accessToken]
  );
  const fetchWallet = async (): Promise<void> => {
    dispatch({ type: "WALLET_FETCHING" });
    try {
      const resp = await requestApiJsonWithAccessToken("GET", "/api/wallet/");
      if (resp.length === 0) {
        dispatch({ type: "WALLET_FETCHED" });
      } else {
        const wallet: Wallet = resp[0];
        dispatch({ type: "WALLET_FETCHED", wallet: wallet });
      }
    } catch (e: any) {
      dispatch({
        type: "WALLET_ERROR",
        walletError: `${e}`,
      });
      return;
    }
  };

  const fetchRemitoneHook = async (): Promise<void> => {
    dispatch({ type: "REMITONEHOOK_FETCHING" });
    try {
      const resp = await requestApiJsonWithAccessToken(
        "GET",
        "/api/remitone/hook/"
      );
      if (resp.length === 0) {
        dispatch({ type: "REMITONEHOOK_FETCHED" });
      } else {
        const remitoneHook: RemitoneHook = resp[0];
        dispatch({ type: "REMITONEHOOK_FETCHED", remitoneHook: remitoneHook });
      }
    } catch (e: any) {
      dispatch({
        type: "REMITONEHOOK_ERROR",
        remitoneHookError: `${e}`,
      });
      return;
    }
  };

  const fetchRemitoneOutboundHook = async (): Promise<void> => {
    dispatch({ type: "REMITONEHOOK_OUTBOUND_FETCHING" });
    try {
      const resp = await requestApiJsonWithAccessToken(
        "GET",
        "/api/remitone/outbound-hook/"
      );
      if (resp.length === 0) {
        dispatch({ type: "REMITONEHOOK_OUTBOUND_FETCHED" });
      } else {
        const remitoneOutboundHook: RemitoneOutboundHook = resp[0];
        dispatch({
          type: "REMITONEHOOK_OUTBOUND_FETCHED",
          remitoneOutboundHook: remitoneOutboundHook,
        });
      }
    } catch (e: any) {
      dispatch({
        type: "REMITONEHOOK_OUTBOUND_ERROR",
        remitoneOutboundHookError: `${e}`,
      });
      return;
    }
  };

  const createWallet = useCallback(
    async (payload: CreateWallet): Promise<Wallet> => {
      dispatch({ type: "WALLET_CREATING" });
      try {
        const wallet: Wallet = await requestApiJsonWithAccessToken(
          "POST",
          "/api/wallet/",
          { body: payload, handleInvalidPayload: true }
        );
        dispatch({ type: "WALLET_FETCHED", wallet: wallet });
        return wallet;
      } catch (e: any) {
        dispatch({
          type: "WALLET_ERROR",
          walletError: `${e}`,
        });
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const deleteWallet = useCallback(async (): Promise<void> => {
    if (!state.wallet) {
      return;
    }
    try {
      await requestApiJsonWithAccessToken(
        "DELETE",
        `/api/wallet/${state.wallet.id}/`,
        { ignoreResponseData: true }
      );
      dispatch({ type: "WALLET_FETCHED", wallet: undefined });
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [state.wallet, requestApiJsonWithAccessToken]);

  const createRemitoneHook = useCallback(
    async (payload: CreateRemitoneHook): Promise<void> => {
      dispatch({ type: "REMITONEHOOK_CREATING" });
      try {
        const remitoneHook: RemitoneHook = await requestApiJsonWithAccessToken(
          "POST",
          "/api/remitone/hook/",
          { body: payload, handleInvalidPayload: true }
        );
        dispatch({
          type: "REMITONEHOOK_FETCHED",
          remitoneHook: remitoneHook,
        });
      } catch (e: any) {
        dispatch({
          type: "REMITONEHOOK_ERROR",
          remitoneHookError: `${e}`,
        });
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const updateRemitoneHook = useCallback(
    async (payload: RemitoneHook): Promise<void> => {
      if (!state.remitoneHook) {
        return;
      }
      dispatch({ type: "REMITONEHOOK_FETCHING" });
      try {
        const remitoneHook: RemitoneHook = await requestApiJsonWithAccessToken(
          "PUT",
          `${process.env.REACT_APP_API_URL}/api/remitone/hook/${payload.id}/`,
          { body: payload, handleInvalidPayload: true }
        );
        dispatch({
          type: "REMITONEHOOK_FETCHED",
          remitoneHook: remitoneHook,
        });
      } catch (e: any) {
        dispatch({ type: "REMITONEHOOK_ERROR", remitoneHookError: e });
        return Promise.reject(e);
      }
    },
    [state.remitoneHook, requestApiJsonWithAccessToken]
  );

  const deleteRemitoneHook = useCallback(async (): Promise<void> => {
    if (!state.remitoneHook) {
      return;
    }
    try {
      await requestApiJsonWithAccessToken(
        "DELETE",
        `/api/remitone/hook/${state.remitoneHook.id}/`,
        { ignoreResponseData: true }
      );
      dispatch({ type: "REMITONEHOOK_FETCHED" });
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [state.remitoneHook, requestApiJsonWithAccessToken]);

  const hasRemitoneCredentials = useCallback(async (): Promise<boolean> => {
    try {
      const resp = await requestApiJsonWithAccessToken(
        "GET",
        "/api/remitone/credential/"
      );
      return resp.has_credentials;
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const createRemitoneCredentials = useCallback(
    async (payload: CreateRemitoneCredentials): Promise<void> => {
      try {
        return await requestApiJsonWithAccessToken(
          "POST",
          "/api/remitone/credential/",
          { body: payload, handleInvalidPayload: true }
        );
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const deleteRemitoneCredentials = useCallback(async (): Promise<void> => {
    try {
      await requestApiJsonWithAccessToken(
        "DELETE",
        `${process.env.REACT_APP_API_URL}/api/remitone/credential/`,
        { ignoreResponseData: true }
      );
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const createRemitoneOutboundHook = useCallback(
    async (payload: CreateRemitoneOutboundHook): Promise<void> => {
      dispatch({ type: "REMITONEHOOK_OUTBOUND_CREATING" });
      try {
        const remitoneOutboundHook: RemitoneOutboundHook =
          await requestApiJsonWithAccessToken(
            "POST",
            "/api/remitone/outbound-hook/",
            { body: payload, handleInvalidPayload: true }
          );
        dispatch({
          type: "REMITONEHOOK_OUTBOUND_FETCHED",
          remitoneOutboundHook: remitoneOutboundHook,
        });
      } catch (e: any) {
        dispatch({
          type: "REMITONEHOOK_OUTBOUND_ERROR",
          remitoneOutboundHookError: `${e}`,
        });
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const updateRemitoneOutboundHook = useCallback(
    async (payload: RemitoneOutboundHook): Promise<void> => {
      if (!state.remitoneOutboundHook) {
        return;
      }
      dispatch({ type: "REMITONEHOOK_OUTBOUND_FETCHING" });
      try {
        const remitoneOutboundHook: RemitoneOutboundHook =
          await requestApiJsonWithAccessToken(
            "PUT",
            `/api/remitone/outbound-hook/${payload.id}/`,
            { body: payload, handleInvalidPayload: true }
          );
        dispatch({
          type: "REMITONEHOOK_OUTBOUND_FETCHED",
          remitoneOutboundHook: remitoneOutboundHook,
        });
      } catch (e: any) {
        dispatch({
          type: "REMITONEHOOK_OUTBOUND_ERROR",
          remitoneOutboundHookError: e,
        });
        return Promise.reject(e);
      }
    },
    [state.remitoneOutboundHook, requestApiJsonWithAccessToken]
  );

  const deleteRemitoneOutboundHook = useCallback(async (): Promise<void> => {
    if (!state.remitoneOutboundHook) {
      return;
    }
    try {
      await requestApiJsonWithAccessToken(
        "DELETE",
        `/api/remitone/outbound-hook/${state.remitoneOutboundHook.id}/`,
        { ignoreResponseData: true }
      );
      dispatch({ type: "REMITONEHOOK_OUTBOUND_FETCHED" });
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [state.remitoneOutboundHook, requestApiJsonWithAccessToken]);

  const hasRemitoneOutboundCredentials =
    useCallback(async (): Promise<boolean> => {
      try {
        const resp = await requestApiJsonWithAccessToken(
          "GET",
          "/api/remitone/outbound-credential/"
        );
        return resp.has_credentials;
      } catch (e: any) {
        return Promise.reject(e);
      }
    }, [requestApiJsonWithAccessToken]);

  const createRemitoneOutboundCredentials = useCallback(
    async (payload: CreateRemitoneOutboundCredentials): Promise<void> => {
      try {
        return await requestApiJsonWithAccessToken(
          "POST",
          "/api/remitone/outbound-credential/",
          { body: payload, handleInvalidPayload: true }
        );
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const deleteRemitoneOutboundCredentials =
    useCallback(async (): Promise<void> => {
      try {
        await requestApiJsonWithAccessToken(
          "DELETE",
          `${process.env.REACT_APP_API_URL}/api/remitone/outbound-credential/`,
          { ignoreResponseData: true }
        );
      } catch (e: any) {
        return Promise.reject(e);
      }
    }, [requestApiJsonWithAccessToken]);

  const fetchAccountSnapshots = useCallback(async (): Promise<
    AccountSnapshot[]
  > => {
    if (!state.wallet) {
      return Promise.reject("Wallet is undefined");
    }
    try {
      return await requestApiJsonWithAccessToken(
        "GET",
        `/api/horizon/account-snapshot/?account=${
          state.wallet.account
        }&testnet=${state.wallet.testnet ? "true" : "false"}`
      );
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [state.wallet, requestApiJsonWithAccessToken]);

  const fetchPayments = useCallback(async (): Promise<Payment[]> => {
    try {
      return await requestApiJsonWithAccessToken("GET", "/api/wallet/payment/");
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const fetchRemitonePayments = useCallback(async (): Promise<
    RemitonePayment[]
  > => {
    try {
      return await requestApiJsonWithAccessToken(
        "GET",
        "/api/remitone/payment/"
      );
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const fetchRemitoneOutboundPayments = useCallback(async (): Promise<
    RemitoneOutboundPayment[]
  > => {
    try {
      return await requestApiJsonWithAccessToken(
        "GET",
        "/api/remitone/outbound-payment/"
      );
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const fetchBankAccounts = useCallback(async (): Promise<BankAccount[]> => {
    try {
      return await requestApiJsonWithAccessToken(
        "GET",
        "/api/bankaccount/bankaccount/"
      );
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const createBankAccount = useCallback(
    async (payload: CreateBankAccount): Promise<BankAccount> => {
      try {
        return await requestApiJsonWithAccessToken(
          "POST",
          "/api/bankaccount/bankaccount/",
          { body: payload, handleInvalidPayload: true }
        );
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const fetchPayoutAccounts = useCallback(async (): Promise<
    PayoutAccount[]
  > => {
    try {
      return await requestApiJsonWithAccessToken("GET", "/api/payout/account/");
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const createPayoutAccount = useCallback(
    async (payload: CreatePayoutAccount): Promise<PayoutAccount> => {
      try {
        return await requestApiJsonWithAccessToken(
          "POST",
          "/api/payout/account/",
          { body: payload, handleInvalidPayload: true }
        );
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const fetchScheduledPayouts = useCallback(async (): Promise<
    ScheduledPayout[]
  > => {
    try {
      return await requestApiJsonWithAccessToken(
        "GET",
        "/api/payout/scheduled-payout/"
      );
    } catch (e: any) {
      return Promise.reject(e);
    }
  }, [requestApiJsonWithAccessToken]);

  const createScheduledPayout = useCallback(
    async (payload: CreateScheduledPayout): Promise<ScheduledPayout> => {
      try {
        return await requestApiJsonWithAccessToken(
          "POST",
          "/api/payout/scheduled-payout/",
          { body: payload, handleInvalidPayload: true }
        );
      } catch (e: any) {
        return Promise.reject(e);
      }
    },
    [requestApiJsonWithAccessToken]
  );

  const fetchProfile = useCallback(async (): Promise<void> => {
    dispatch({ type: "PROFILE_FETCHING" });
    try {
      const resp = await requestApiJsonWithAccessToken("GET", "/api/profile/");
      if (resp.length === 0) {
        const profile: Profile = await requestApiJsonWithAccessToken(
          "POST",
          "/api/profile/",
          { body: {}, handleInvalidPayload: true }
        );
        dispatch({ type: "PROFILE_FETCHED", profile: profile });
      } else {
        const profile: Profile = resp[0];
        dispatch({ type: "PROFILE_FETCHED", profile: profile });
      }
    } catch (e: any) {
      dispatch({
        type: "PROFILE_ERROR",
        profileError: `${e}`,
      });
      return;
    }
  }, [requestApiJsonWithAccessToken]);

  const partialUpdateProfile = useCallback(
    async (id: string, payload: any): Promise<Profile> => {
      const profile: Profile = await requestApiJsonWithAccessToken(
        "PATCH",
        `/api/profile/${id}/`,
        { body: payload, handleInvalidPayload: true }
      );
      dispatch({ type: "PROFILE_FETCHED", profile: profile });
      return profile;
    },
    [requestApiJsonWithAccessToken]
  );

  const uploadProfileFile = useCallback(
    async (file: File, field: string): Promise<Profile> => {
      const formData = new FormData();
      formData.append("file", file);
      const resp = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profile/upload-file/${field}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${props.accessToken}`,
          },
        }
      );
      const profile: Profile = resp.data;
      dispatch({ type: "PROFILE_FETCHED", profile: profile });
      return profile;
    },
    [props.accessToken]
  );

  const showAlertSuccess = useCallback(
    async (alertSuccess: string): Promise<void> => {
      dispatch({ type: "ALERT_SUCCESS", alertSuccess: alertSuccess });
      setTimeout(() => dispatch({ type: "ALERT_RESET" }), 3000);
    },
    []
  );

  const showAlertError = useCallback(
    async (alertError: string): Promise<void> => {
      dispatch({ type: "ALERT_ERROR", alertError: alertError });
      setTimeout(() => dispatch({ type: "ALERT_RESET" }), 3000);
    },
    []
  );

  const hideAlert = useCallback(async (): Promise<void> => {
    dispatch({ type: "ALERT_RESET" });
  }, []);

  const getAccessToken = useCallback((): string => {
    return props.accessToken;
  }, [props.accessToken]);

  useEffect(() => {
    fetchWallet();
    fetchRemitoneHook();
    fetchRemitoneOutboundHook();
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue = useMemo(() => {
    return {
      ...state,
      createWallet,
      deleteWallet,
      createRemitoneHook,
      updateRemitoneHook,
      deleteRemitoneHook,
      hasRemitoneCredentials,
      createRemitoneCredentials,
      deleteRemitoneCredentials,
      createRemitoneOutboundHook,
      updateRemitoneOutboundHook,
      deleteRemitoneOutboundHook,
      hasRemitoneOutboundCredentials,
      createRemitoneOutboundCredentials,
      deleteRemitoneOutboundCredentials,
      fetchAccountSnapshots,
      fetchPayments,
      fetchRemitonePayments,
      fetchRemitoneOutboundPayments,
      fetchBankAccounts,
      createBankAccount,
      fetchPayoutAccounts,
      createPayoutAccount,
      createScheduledPayout,
      fetchScheduledPayouts,
      fetchProfile,
      uploadProfileFile,
      partialUpdateProfile,
      showAlertSuccess,
      showAlertError,
      hideAlert,
      getAccessToken,
    };
  }, [
    state,
    createWallet,
    deleteWallet,
    createRemitoneHook,
    updateRemitoneHook,
    deleteRemitoneHook,
    hasRemitoneCredentials,
    createRemitoneCredentials,
    deleteRemitoneCredentials,
    createRemitoneOutboundHook,
    updateRemitoneOutboundHook,
    deleteRemitoneOutboundHook,
    hasRemitoneOutboundCredentials,
    createRemitoneOutboundCredentials,
    deleteRemitoneOutboundCredentials,
    fetchAccountSnapshots,
    fetchPayments,
    fetchRemitonePayments,
    fetchRemitoneOutboundPayments,
    fetchBankAccounts,
    createBankAccount,
    fetchPayoutAccounts,
    createPayoutAccount,
    createScheduledPayout,
    fetchScheduledPayouts,
    fetchProfile,
    uploadProfileFile,
    partialUpdateProfile,
    showAlertSuccess,
    showAlertError,
    hideAlert,
    getAccessToken,
  ]);

  return (
    <StellarPayContext.Provider value={contextValue}>
      {props.children}
    </StellarPayContext.Provider>
  );
};

export default StellarPayProvider;

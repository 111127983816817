import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Tooltip,
} from "@mui/material";
import classnames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import RemitoneHookStatus from "../../../types/RemitoneHookStatus";
import useStellarPay from "../../../useStellarPay";
import { shortPubkey } from "../../../utils";
import IOSSwitch from "../../IOSSwitch";

type SettingsRemitoneInboundProps = {
  className?: string;
  createRemitoneHookLoading: boolean;
  showSettingsRemitoneInboundForm: boolean;
  Set_createRemitoneHookLoading: React.Dispatch<React.SetStateAction<boolean>>;
  Set_showSettingsRemitoneInboundForm: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  Set_enableLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const SettingsRemitoneInbound: FunctionComponent<
  SettingsRemitoneInboundProps
> = ({
  showSettingsRemitoneInboundForm,
  Set_showSettingsRemitoneInboundForm,
  Set_enableLoading,
  createRemitoneHookLoading,
  Set_createRemitoneHookLoading,
}) => {
  const {
    remitoneHook,
    remitoneHookStatus,
    showAlertError,
    updateRemitoneHook,
    deleteRemitoneHook,
    showAlertSuccess,
    deleteRemitoneCredentials,
  } = useStellarPay();
  const [enabled, Set_enabled] = useState<boolean>(false);
  const [pubkeyCopied, Set_pubkeyCopied] = useState<boolean>(false);
  const [showDisableDeleteDialog, Set_showDisableDeleteDialog] =
    useState(false);

  useEffect(() => {
    Set_enabled(!!remitoneHook?.enabled);
  }, [remitoneHook, showSettingsRemitoneInboundForm]);

  const onPubKeyCopy = () => {
    Set_pubkeyCopied(true);
    setTimeout(() => Set_pubkeyCopied(false), 3000);
  };

  return (
    <div
      className={classnames("pb-4", {
        disabled:
          remitoneHookStatus === RemitoneHookStatus.PENDING ||
          remitoneHookStatus === RemitoneHookStatus.FETCHING,
      })}
    >
      <div className={"row align-items-start"}>
        <div className={"col-8"}>
          <h4 className={"fs-6 mt-1 mb-0"}>RemitONE Inbound Integration</h4>
          <h5
            style={{
              fontSize: "0.8em",
              fontWeight: "normal",
              lineHeight: "1.2rem",
              marginTop: "0.5rem",
            }}
          >
            Receive payments from Stellar chain into RemitONE
          </h5>
          <div
            className={classnames("ms-auto d-flex align-items-center ", {
              "d-none": !remitoneHook?.account || !remitoneHook?.enabled,
            })}
          >
            <div
              className={
                "bg-light border p-1 px-2 rounded-2 me-4 public-key-wrapper d-flex align-items-center"
              }
            >
              <div
                role="button"
                className={"public-key-full"}
                onClick={() => {
                  navigator.clipboard.writeText(remitoneHook?.account ?? "");
                  onPubKeyCopy();
                }}
                title={"Hotwallet account - Click to copy"}
              >
                {remitoneHook?.account ?? ""}
              </div>
              <span className={"fs-6 public-key-partial"}>
                {shortPubkey(remitoneHook?.account ?? "")}
              </span>
              <div
                title={"Copy Hotwallet account"}
                className={"d-flex align-items-center ms-1"}
              >
                <CopyToClipboard
                  text={remitoneHook?.account ?? ""}
                  onCopy={onPubKeyCopy}
                >
                  {pubkeyCopied ? (
                    <CheckIcon className={"public-key-copy"} />
                  ) : (
                    <ContentCopyIcon className={"public-key-copy"} />
                  )}
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-1"}>
          <IOSSwitch
            sx={{ m: 1 }}
            checked={enabled ?? false}
            onChange={async (event, checked) => {
              Set_enableLoading(true);
              try {
                if (remitoneHook) {
                  if (checked) {
                    await updateRemitoneHook({
                      ...remitoneHook,
                      enabled: true,
                    });
                  } else {
                    await updateRemitoneHook({
                      ...remitoneHook,
                      enabled: false,
                    });
                  }
                }
                Set_enabled(checked);
                !remitoneHook && Set_showSettingsRemitoneInboundForm(checked);
              } catch (e) {
                showAlertError(`${e}`);
              }
              Set_enableLoading(false);
            }}
          />
        </div>
        {enabled && remitoneHook && (
          <div className={"row mt-2"}>
            <div className={"col d-flex align-items-center"}>
              <Tooltip title="Delete the RemitONE Admin WebServices Inbound credentials (username, password, PIN) from your account">
                <Button
                  variant={"contained"}
                  color={"error"}
                  endIcon={<DeleteIcon className={"mb-1"} />}
                  onClick={() => Set_showDisableDeleteDialog(true)}
                >
                  DISABLE & DELETE CREDENTIALS
                </Button>
              </Tooltip>
              {createRemitoneHookLoading && (
                <CircularProgress size={"1.5rem"} className={"ms-2"} />
              )}
              <Dialog
                open={showDisableDeleteDialog}
                onClose={() => Set_showDisableDeleteDialog(false)}
                aria-labelledby="disable-delete-dialog-title"
                aria-describedby="disable-delete-dialog-description"
              >
                <DialogTitle id="disable-delete-dialog-title">
                  {"Confirm Deleting RemitONE Outbound Credentials"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="disable-delete-dialog-description">
                    This action will disable RemitONE Inbound integration and
                    delete the RemitONE Admin WebServices Inbound credentials
                    from your account. Re-enabling RemitONE Inbound integration
                    will require you to provide the credentials again.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"error"}
                    onClick={() => Set_showDisableDeleteDialog(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color={"primary"}
                    onClick={async () => {
                      Set_showDisableDeleteDialog(false);
                      Set_createRemitoneHookLoading(true);
                      Set_enableLoading(true);
                      try {
                        await deleteRemitoneHook();
                        await deleteRemitoneCredentials();
                        showAlertSuccess(
                          "RemitONE Outbound integration disabled"
                        );
                      } catch (e) {
                        showAlertError(`${e}`);
                      }
                      Set_createRemitoneHookLoading(false);
                      Set_enableLoading(false);
                    }}
                  >
                    CONFIRM
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsRemitoneInbound;

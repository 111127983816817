import { faArrowRight, faShuffle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { FunctionComponent, ReactElement } from "react";
import { ReactComponent as Stellar } from "../Stellar.svg";
import useStellarPay from "../useStellarPay";
import { ellipsisString, shortPubkey } from "../utils";
import "./Navbar.css";

type PaymentCardProps = {
  className?: string;
  fromAccountName: string;
  toAccountName: undefined | string;
  payment: any;
};

type CardRowProps = {
  label: string;
  value: ReactElement | string;
};

const CardRow: FunctionComponent<CardRowProps> = ({ label, value }) => (
  <>
    <Typography sx={{ fontSize: 14 }} color="text.secondary">
      {label}
    </Typography>
    <Typography variant="h6" component="div">
      {value ? value : "---"}
    </Typography>
  </>
);
const PaymentCard: FunctionComponent<PaymentCardProps> = ({ fromAccountName, toAccountName, payment, className }) => {
  const { wallet } = useStellarPay();

  return (
    <Box className={className}>
      <Card variant="outlined" className="text-break">
        <React.Fragment>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CardRow label="Date" value={payment?.created_at} />
              </Grid>
              <Grid item xs={6}>
                <CardRow label="Asset" value={payment.asset_type === "native" ? "XLM" : payment.asset_code} />
              </Grid>
              <Grid item xs={6}>
                <CardRow
                  label="From"
                  value={
                    <div className="d-flex justify-content-between">
                      {payment.from_account === wallet?.account && (
                        <div style={{ width: 15 }} className={"d-flex p-0 align-items-center justify-content-end"}>
                          <Stellar
                            width={15}
                            height={15}
                            fill={"darkslategray"}
                            title={payment.from_account === wallet?.account ? "This is your Wallet account" : ""}
                          />
                        </div>
                      )}

                      {shortPubkey(payment.from_account)}
                      {payment.from_account === wallet?.account && (
                        <div style={{ width: 15 }} className={"d-flex p-0 align-items-center justify-content-end"}>
                          <Stellar
                            width={15}
                            height={15}
                            fill={"darkslategray"}
                            title={payment.from_account === wallet?.account ? "This is your Wallet account" : ""}
                          />
                        </div>
                      )}
                      <div className={"row g-0 d-flex me-3  align-items-center"}>
                        <div className={"d-flex p-0 align-items-center "} style={{ width: "70%" }}>
                          {payment.type === "payment" && (
                            <div
                              style={{ width: 15, display: "block" }}
                              className={"d-flex p-0 ps-1 align-items-center"}
                            >
                              <FontAwesomeIcon size={"1x"} icon={faArrowRight} title={"Payment"} />
                            </div>
                          )}
                          {payment.type === "path_payment_strict_send" && (
                            <div style={{ width: 15 }} className={"d-flex p-0 ps-1 align-items-center"}>
                              <FontAwesomeIcon size={"1x"} icon={faShuffle} title={"Path Payment"} />
                            </div>
                          )}
                          {fromAccountName && (
                            <div
                              className={"row g-0 d-flex align-items-center justify-content-between"}
                              title="Account name"
                            >
                              <span className={"text-muted"} style={{ fontSize: "0.8em" }}>
                                {ellipsisString(fromAccountName, 50)}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <CardRow
                  label="To"
                  value={
                    <div className={"col"}>
                      <div className={"row g-0 d-flex "} title="To Stellar account">
                        {payment.to_account === wallet?.account && (
                          <div style={{ width: 20 }} className={"d-flex p-0 me-2 align-items-center "}>
                            <Stellar
                              width={15}
                              height={15}
                              fill={"darkslategray"}
                              title={payment.to_account === wallet?.account ? "This is your Wallet account" : ""}
                            />
                          </div>
                        )}
                        {shortPubkey(payment.to_account)}
                      </div>
                      {toAccountName && (
                        <div
                          className={"row g-0 d-flex align-items-center justify-content-between"}
                          title="Account name"
                        >
                          <span className={"text-muted"} style={{ fontSize: "0.8em" }}>
                            {ellipsisString(toAccountName, 50)}
                          </span>
                        </div>
                      )}
                    </div>
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <CardRow label="Amount" value={payment.amount} />
              </Grid>
              <Grid item xs={6}>
                <CardRow label="Memo" value={payment.transaction.memo} />
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontSize: 14 }} color="text.secondary">
                  Links
                </Typography>
                <a
                  href={`https://stellar.expert/explorer/${payment.testnet ? "testnet" : "public"}/op/${
                    payment.stellar_payment_id
                  }`}
                  rel="noopener noreferrer"
                  target="_blank"
                  title={"View in Stellar.Expert"}
                >
                  <img alt="Stellar Expert" height={18} src={"/stellar-expert-blue.svg"} />
                </a>
              </Grid>
            </Grid>
          </CardContent>
        </React.Fragment>
      </Card>
    </Box>
  );
};

export default PaymentCard;

import { Box } from "@mui/material";
import SidebarContextProvider from "../SidebarContext";
import Sidebar from "./Sidebar";
import "./Sidebar.css";

const drawerWidth = 251;

const Layout = (props: any) => {
  const { children } = props;

  // Code based on https://getbootstrap.com/docs/5.0/examples/
  return (
    <SidebarContextProvider>
      <Box sx={{ display: "flex" }}>
        <Sidebar />
        <Box  sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
          {children}
        </Box>
      </Box>
    </SidebarContextProvider>
  );
};
export default Layout;

import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FunctionComponent, useState } from "react";
import { COUNTRIES } from "../../../countries";
import InvalidPayloadError from "../../../errors/InvalidPayloadError";
import Country from "../../../types/Country";
import PayoutAccountBankAccountHolderType from "../../../types/PayoutAccountBankAccountHolderType";
import PayoutAccountBankAccountType from "../../../types/PayoutAccountBankAccountType";
import PayoutAccountType from "../../../types/PayoutAccountType";
import UsState from "../../../types/UsState";
import { US_STATES } from "../../../us-states";
import useStellarPay from "../../../useStellarPay";
import "./AddPayoutBankWireUsForm.css";

const _ = require("lodash");

type AddPayoutBankWireUsFormProps = {
  onBack: (e: unknown) => void;
  onSaved: () => void;
  onSaving: () => void;
  onError: (e: any) => void;
};

const AddPayoutBankWireUsForm: FunctionComponent<
  AddPayoutBankWireUsFormProps
> = ({ onBack, onSaved, onSaving, onError }) => {
  const { createPayoutAccount, showAlertSuccess, showAlertError } =
    useStellarPay();
  const [alias, Set_alias] = useState<string | undefined>(undefined);
  const [benefFirstName, Set_benefFirstName] = useState<string | undefined>(
    undefined
  );
  const [benefLastName, Set_benefLastName] = useState<string | undefined>(
    undefined
  );
  const [benefAddress1, Set_benefAddress1] = useState<string | undefined>(
    undefined
  );
  const [benefAddress2, Set_benefAddress2] = useState<string | undefined>(
    undefined
  );
  const [benefCity, Set_benefCity] = useState<string | undefined>(undefined);
  const [benefState, Set_benefState] = useState<string | undefined>(undefined);
  const [benefPostalCode, Set_benefPostalCode] = useState<string | undefined>(
    undefined
  );
  const [benefCountry, Set_benefCountry] = useState<string | undefined>(
    undefined
  );
  const [bankAccountNumber, Set_bankAccountNumber] = useState<
    string | undefined
  >(undefined);
  const [bankRoutingCode, Set_bankRoutingCode] = useState<string | undefined>(
    undefined
  );
  const [saving, Set_saving] = useState<boolean>(false);
  const [createBankWireError, Set_createBankWireError] = useState<
    InvalidPayloadError | undefined
  >(undefined);

  return (
    <>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6">
          <TextField
            label={"Alias"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.alias}
            helperText={
              createBankWireError?.alias
                ? createBankWireError?.alias
                : "An alias of your choice for this payout account. E.g.: MySavingsAccount1"
            }
            onChange={(event: any) => {
              Set_createBankWireError(_.omit(createBankWireError, "alias"));
              Set_alias(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12">
          <h2>Billing Information</h2>
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6 pe-3">
          <TextField
            label={"First Name"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.benef_first_name}
            helperText={
              createBankWireError?.benef_first_name
                ? createBankWireError?.benef_first_name
                : "First name"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "benef_first_name")
              );
              Set_benefFirstName(event.target.value);
            }}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            label={"Last Name"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.benef_last_name}
            helperText={
              createBankWireError?.benef_last_name
                ? createBankWireError?.benef_last_name
                : "Last name"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "benef_last_name")
              );
              Set_benefLastName(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12">
          <TextField
            label={"Address Line 1"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.benef_address1}
            helperText={
              createBankWireError?.benef_address1
                ? createBankWireError?.benef_address1
                : "Residence address line 1"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "benef_address1")
              );
              Set_benefAddress1(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12">
          <TextField
            label={"Address Line 2 (Optional)"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.benef_address2}
            helperText={
              createBankWireError?.benef_address2
                ? createBankWireError?.benef_address2
                : "Residence address line 2"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "benef_address2")
              );
              Set_benefAddress2(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6 pe-3">
          <TextField
            label={"City"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.benef_city}
            helperText={
              createBankWireError?.benef_city
                ? createBankWireError?.benef_city
                : "City of residence"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "benef_city")
              );
              Set_benefCity(event.target.value);
            }}
          />
        </div>
        <div className="col-12 col-sm-6">
          <FormControl className={"mb-3 w-100"}>
            <InputLabel id="add-payout-bank-wire-us-form-benef-state-label">
              State
            </InputLabel>
            <Select
              labelId="add-payout-bank-wire-us-form-benef-state-label"
              id="add-payout-bank-wire-us-form-benef-state"
              value={benefState ?? ""}
              error={!!createBankWireError?.benef_state}
              label="State"
              onChange={(e) => {
                Set_createBankWireError(
                  _.omit(createBankWireError, "benef_state")
                );
                Set_benefState(e.target.value);
              }}
            >
              {US_STATES.map((usState: UsState, index: number) => {
                return (
                  <MenuItem value={usState.postalAbbrev} key={index}>
                    {usState.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {createBankWireError?.benef_state ? (
                <span className="text-danger">
                  {createBankWireError?.benef_state}
                </span>
              ) : (
                "State"
              )}
            </FormHelperText>
          </FormControl>
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6">
          <FormControl className={"mb-3 w-100"}>
            <InputLabel id="add-payout-bank-wire-us-form-benef-country-label">
              Country of Residence
            </InputLabel>
            <Select
              labelId="add-payout-bank-wire-us-form-benef-country-label"
              id="add-payout-bank-wire-us-form-benef-country"
              value={benefCountry ?? ""}
              label="Country of Residence"
              error={!!createBankWireError?.benef_country}
              onChange={(e) => {
                Set_createBankWireError(
                  _.omit(createBankWireError, "benef_country")
                );
                Set_benefCountry(e.target.value);
              }}
            >
              {COUNTRIES.map((country: Country, index: number) => {
                return (
                  <MenuItem value={country.iso3} key={index}>
                    {country.name} ({country.iso2})
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              {createBankWireError?.benef_country ? (
                <span className="text-danger">
                  {createBankWireError?.benef_country}
                </span>
              ) : (
                "Country of Residence"
              )}
            </FormHelperText>
          </FormControl>
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6">
          <TextField
            label={"Postal Code"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.benef_postal_code}
            helperText={
              createBankWireError?.benef_postal_code
                ? createBankWireError?.benef_postal_code
                : "Postal Code"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "benef_postal_code")
              );
              Set_benefPostalCode(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12">
          <h2>Bank Account Information</h2>
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6 pe-3">
          <TextField
            label={"Account Number"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.bank_account_number}
            helperText={
              createBankWireError?.bank_account_number
                ? createBankWireError?.bank_account_number
                : "Bank account number"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "bank_account_number")
              );
              Set_bankAccountNumber(event.target.value);
            }}
          />
        </div>
        <div className="col-12 col-sm-6">
          <TextField
            label={"Routing Code"}
            className={"mb-3 w-100"}
            error={!!createBankWireError?.bank_routing_code}
            helperText={
              createBankWireError?.bank_routing_code
                ? createBankWireError?.bank_routing_code
                : "Bank routing code"
            }
            onChange={(event: any) => {
              Set_createBankWireError(
                _.omit(createBankWireError, "bank_routing_code")
              );
              Set_bankRoutingCode(event.target.value);
            }}
          />
        </div>
      </div>
      <div className={"row g-0 pt-3 pb-5"}>
        <div className="d-grid gap-2 d-md-flex justify-content-end align-items-center">
          {saving && (
            <CircularProgress size={"1.5rem"} className={"me-2"} />
          )}

          <Button
            variant={"contained"}
            color={"error"}
            onClick={(e: any) => onBack(e)}
          >
            CANCEL
          </Button>
          <Button
            variant={"contained"}
            onClick={async () => {
              Set_saving(true);
              onSaving();

              try {
                await createPayoutAccount({
                  alias: alias ?? "",
                  type: PayoutAccountType.BANK_WIRE,
                  benef_first_name: benefFirstName ?? "",
                  benef_last_name: benefLastName ?? "",
                  benef_company_name: "",
                  benef_address1: benefAddress1 ?? "",
                  benef_address2: benefAddress2 ?? "",
                  benef_city: benefCity ?? "",
                  benef_state: benefState ?? "",
                  benef_postal_code: benefPostalCode ?? "",
                  benef_phone_number: "",
                  benef_country: benefCountry ?? "",
                  bank_country: "USA",
                  bank_account_type: PayoutAccountBankAccountType.CHECKINGS,
                  bank_account_holder_type:
                    PayoutAccountBankAccountHolderType.INDIVIDUAL,
                  bank_account_number: bankAccountNumber ?? "",
                  bank_swift: "",
                  bank_routing_code: bankRoutingCode ?? "",
                  bank_code: "",
                  bank_branch_code: "",
                  bank_branch_telephone: "",
                });
                Set_saving(false);
                showAlertSuccess("Bank connected");
                onSaved();
              } catch (e) {
                Set_saving(false);
                onError(e);
                if (e instanceof InvalidPayloadError) {
                  Set_createBankWireError(e);
                } else {
                  showAlertError(`${e}`);
                }
              }
            }}
          >
            DONE
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddPayoutBankWireUsForm;

import { Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./index.css";
import App from "./routes/App";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import LandingPage from "./routes/LandingPage";
import TermsAndConditions from "./routes/TermsAndConditions";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container!);

const requiredEnvVars = [
  "REACT_APP_AUTH0_DOMAIN",
  "REACT_APP_AUTH0_CLIENT_ID",
  "REACT_APP_AUTH0_AUDIENCE",
  "REACT_APP_AUTH0_SCOPE",
  "REACT_APP_API_URL",
];
let hasError = false;
for (const s of requiredEnvVars) {
  if (!process.env[s]) {
    console.error(`Missing ${s} from environment`);
    hasError = true;
  }
}
if (hasError) {
  throw Error("Errors encountered, see log above");
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#0226b5",
      contrastText: "white",
    },
  },
  typography: {
    fontFamily: ["Cairo", "sans-serif"].join(","),
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <Auth0Provider
      cacheLocation="localstorage"
      domain={process.env.REACT_APP_AUTH0_DOMAIN!}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
      redirectUri={window.location.origin + "/wallet"}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      scope={process.env.REACT_APP_AUTH0_SCOPE}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/wallet" element={<App path="/wallet" />} />
          <Route path="/get-started" element={<App path="/get-started" />} />
          <Route path="/remitone" element={<App path="/remitone" />} />
          <Route path="/settings" element={<App path="/settings" />} />
        </Routes>
      </BrowserRouter>
    </Auth0Provider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import Profile from "./types/Profile";
import ProfileStatus from "./types/ProfileStatus";
import RemitoneHook from "./types/RemitoneHook";
import RemitoneHookStatus from "./types/RemitoneHookStatus";
import RemitoneOutboundHook from "./types/RemitoneOutboundHook";
import RemitoneOutboundHookStatus from "./types/RemitoneOutboundHookStatus";
import Wallet from "./types/Wallet";
import WalletStatus from "./types/WalletStatus";

interface StellarPayState {
  wallet?: Wallet;
  walletStatus: WalletStatus;
  walletError?: any;
  remitoneHook?: RemitoneHook;
  remitoneHookStatus: RemitoneHookStatus;
  remitoneHookError?: any;
  remitoneOutboundHook?: RemitoneOutboundHook;
  remitoneOutboundHookStatus: RemitoneOutboundHookStatus;
  remitoneOutboundHookError?: any;
  profile?: Profile;
  profileStatus: ProfileStatus;
  profileError?: any;
  alertSuccess?: string;
  alertError?: string;
}

export const initialStellarPayState: StellarPayState = {
  walletStatus: WalletStatus.PENDING,
  remitoneHookStatus: RemitoneHookStatus.PENDING,
  remitoneOutboundHookStatus: RemitoneOutboundHookStatus.PENDING,
  profileStatus: ProfileStatus.PENDING,
};

export default StellarPayState;

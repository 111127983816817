import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Button, LinearProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, Navigate } from "react-router-dom";
import WalletStatus from "../../../types/WalletStatus";
import useStellarPay from "../../../useStellarPay";

const SettingsWallet = () => {
  const { wallet, deleteWallet, walletStatus } = useStellarPay();
  const [error, Set_error] = useState<any | undefined>(undefined);
  const [pubkeyCopied, Set_pubkeyCopied] = useState(false);
  const [deletingWallet, Set_deletingWallet] = useState(false);
  const [navigateToGetStarted, Set_navigateToGetStarted] = useState(false);
  const [showDisconnectWalletDialog, Set_showDisconnectWalletDialog] =
    useState(false);

  return (
    <div
      className={"col-8"}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      {navigateToGetStarted && <Navigate to="/get-started" />}
      {walletStatus === WalletStatus.FETCHED && wallet && !deletingWallet && (
        <>
          <div className={"row g-0 mb-4"}>
            <div className={"col"}>
              <h3 className={"fs-6 pb-3"}>Your Wallet Stellar account:</h3>
              <span
                className={"p-2 me-3"}
                style={{ backgroundColor: "lightgray" }}
              >
                {wallet.account}
              </span>
              <CopyToClipboard
                text={wallet.account}
                onCopy={() => {
                  Set_pubkeyCopied(true);
                  setTimeout(() => Set_pubkeyCopied(false), 3000);
                }}
              >
                {pubkeyCopied ? (
                  <span>
                    <CheckIcon
                      style={{ cursor: "pointer", marginBottom: "0.1em" }}
                    />
                    <span className={"ps-1"} style={{ cursor: "pointer" }}>
                      Copied
                    </span>
                  </span>
                ) : (
                  <span>
                    <ContentCopyIcon
                      style={{ cursor: "pointer", marginBottom: "0.1em" }}
                    />
                    <span className={"ps-1"} style={{ cursor: "pointer" }}>
                      Copy
                    </span>
                  </span>
                )}
              </CopyToClipboard>
            </div>
          </div>
          <div className={"row g-0 mt-2"}>
            <div className={"col"}>
              <Button
                variant={"contained"}
                color={"error"}
                onClick={() => {
                  Set_showDisconnectWalletDialog(true);
                }}
              >
                DISCONNECT STELLAR ACCOUNT
              </Button>
              <Dialog
                open={showDisconnectWalletDialog}
                onClose={() => Set_showDisconnectWalletDialog(false)}
                aria-labelledby="disconnect-wallet-dialog-title"
                aria-describedby="disconnect-wallet-dialog-description"
              >
                <DialogTitle id="disconnect-wallet-dialog-title">
                  {"Confirm Disconnecting Stellar Wallet"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="disconnect-wallet-dialog-description">
                    This action will disconnect your Stellar account from this
                    Dashboard. Are you sure you want to continue?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    color={"error"}
                    onClick={() => Set_showDisconnectWalletDialog(false)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    color={"primary"}
                    onClick={async () => {
                      Set_showDisconnectWalletDialog(false);
                      Set_deletingWallet(true);
                      deleteWallet()
                        .then(() => {
                          Set_navigateToGetStarted(true);
                        })
                        .catch((e) => Set_error(JSON.stringify(e)))
                        .finally(() => Set_deletingWallet(false));
                    }}
                  >
                    CONFIRM
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </>
      )}
      {walletStatus === WalletStatus.FETCHED && !wallet && (
        <div className={"row g-0"}>
          <div className={"col"}>
            <h3 className={"fs-6"}>
              Get started by{" "}
              <Link to={"/get-started"}>connecting a Stellar account.</Link>
            </h3>
          </div>
        </div>
      )}
      {error && (
        <div className={"row g-0 mt-2"}>
          <div className={"col"}>
            <span className={"text-danger"}>{`${error}`}</span>
          </div>
        </div>
      )}
      {(walletStatus === WalletStatus.FETCHING || deletingWallet) && (
        <LinearProgress className={"mb-3"} />
      )}
    </div>
  );
};

export default SettingsWallet;

import UsState from "./types/UsState";

// Obtained at 2022-08-07 from https://en.wikipedia.org/wiki/List_of_states_and_territories_of_the_United_States
export const US_STATES: UsState[] = [
  { name: "Alabama", postalAbbrev: "AL" },
  { name: "Alaska", postalAbbrev: "AK" },
  { name: "Arizona", postalAbbrev: "AZ" },
  { name: "Arkansas", postalAbbrev: "AR" },
  { name: "California", postalAbbrev: "CA" },
  { name: "Colorado", postalAbbrev: "CO" },
  { name: "Connecticut", postalAbbrev: "CT" },
  { name: "Delaware", postalAbbrev: "DE" },
  { name: "Florida", postalAbbrev: "FL" },
  { name: "Georgia", postalAbbrev: "GA" },
  { name: "Hawaii", postalAbbrev: "HI" },
  { name: "Idaho", postalAbbrev: "ID" },
  { name: "Illinois", postalAbbrev: "IL" },
  { name: "Indiana", postalAbbrev: "IN" },
  { name: "Iowa", postalAbbrev: "IA" },
  { name: "Kansas", postalAbbrev: "KS" },
  { name: "Kentucky", postalAbbrev: "KY" },
  { name: "Louisiana", postalAbbrev: "LA" },
  { name: "Maine", postalAbbrev: "ME" },
  { name: "Maryland", postalAbbrev: "MD" },
  { name: "Massachusetts", postalAbbrev: "MA" },
  { name: "Michigan", postalAbbrev: "MI" },
  { name: "Minnesota", postalAbbrev: "MN" },
  { name: "Mississippi", postalAbbrev: "MS" },
  { name: "Missouri", postalAbbrev: "MO" },
  { name: "Montana", postalAbbrev: "MT" },
  { name: "Nebraska", postalAbbrev: "NE" },
  { name: "Nevada", postalAbbrev: "NV" },
  { name: "New Hampshire", postalAbbrev: "NH" },
  { name: "New Jersey", postalAbbrev: "NJ" },
  { name: "New Mexico", postalAbbrev: "NM" },
  { name: "New York", postalAbbrev: "NY" },
  { name: "North Carolina", postalAbbrev: "NC" },
  { name: "North Dakota", postalAbbrev: "ND" },
  { name: "Ohio", postalAbbrev: "OH" },
  { name: "Oklahoma", postalAbbrev: "OK" },
  { name: "Oregon", postalAbbrev: "OR" },
  { name: "Pennsylvania", postalAbbrev: "PA" },
  { name: "Rhode Island", postalAbbrev: "RI" },
  { name: "South Carolina", postalAbbrev: "SC" },
  { name: "South Dakota", postalAbbrev: "SD" },
  { name: "Tennessee", postalAbbrev: "TN" },
  { name: "Texas", postalAbbrev: "TX" },
  { name: "Utah", postalAbbrev: "UT" },
  { name: "Vermont", postalAbbrev: "VT" },
  { name: "Virginia", postalAbbrev: "VA" },
  { name: "Washington", postalAbbrev: "WA" },
  { name: "West Virginia", postalAbbrev: "WV" },
  { name: "Wisconsin", postalAbbrev: "WI" },
  { name: "Wyoming", postalAbbrev: "WY" },
];

import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import useStellarPay from "../useStellarPay";
import * as React from "react";
import { useEffect, useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem, Select, Skeleton } from "@mui/material";
import { fetchAssetResults, getAssetKey } from "../utils";
import AccountSnapshot from "../types/AccountSnapshot";
import AssetResults from "../types/AssetResults";
import _ from "lodash";
import IOSSwitch from "./IOSSwitch";
import RefreshIcon from "@mui/icons-material/Refresh";

export class Balance {
  [index: string]: string;
}

const filterAssets = ["XLM", "USDC", "USDT", "BTC", "BTCLN", "AQIL", "CLPX", "IDRT", "XCHF", "BRL"];

type LineColors = {
  [index: string]: string;
};

const BalanceGraph = ({ ...props }) => {
  const { ...rest } = props;
  const { wallet, fetchAccountSnapshots, getAccessToken } = useStellarPay();
  const [error, Set_error] = useState<any | undefined>(undefined);
  const [, Set_horizonAccount] = useState<any | undefined>(undefined);
  const [allAssetsFetched, Set_allAssetsFetched] = useState<boolean>(false);
  const [balances, Set_balances] = useState<Balance[] | undefined>(undefined);
  const [balanceKeys, Set_balanceKeys] = useState<string[] | undefined>(undefined);
  const [lineColors, Set_lineColors] = useState<LineColors>({});
  const [assetResults, Set_assetResults] = useState<AssetResults>({});
  const [selectedBalanceKey, Set_selectedBalanceKey] = useState<string>("all");
  const [period, Set_period] = useState<string>("hour");
  useEffect(() => {
    if (wallet !== undefined && error === undefined) {
      fetchAssetResults(
        true,
        getAccessToken(),
        wallet.testnet,
        wallet.account,
        Set_horizonAccount,
        Set_assetResults,
        Set_error,
        undefined
      );
    }
  }, [wallet, error, getAccessToken]);

  const refresh = async () => {
    if (wallet) {
      Set_allAssetsFetched(false);
      Set_balances(undefined);
      Set_balanceKeys(undefined);
      Set_horizonAccount(undefined);
      Set_assetResults({});
      Set_error(undefined);
      fetchAssetResults(
        false,
        getAccessToken(),
        wallet.testnet,
        wallet.account,
        Set_horizonAccount,
        Set_assetResults,
        Set_error,
        undefined
      );
    }
  };

  useEffect(() => {
    if (assetResults !== undefined && error === undefined && !allAssetsFetched) {
      let _allAssetsFetched = true;
      if (!Object.keys(assetResults).length) {
        _allAssetsFetched = false;
      } else {
        for (const assetKey of Object.keys(assetResults)) {
          const assetResult = assetResults[assetKey];
          if (assetKey !== "XLM:native" && !assetResult.asset) {
            _allAssetsFetched = false;
          }
        }
      }
      if (_allAssetsFetched) {
        Set_allAssetsFetched(true);
        fetchAccountSnapshots()
          .then((accountSnapshots: AccountSnapshot[]) => {
            const _balances: Balance[] = [];
            let _balanceKeys: string[] = [];
            accountSnapshots.forEach((accountSnapshot: AccountSnapshot) => {
              const balance: Balance = {
                timestamp: accountSnapshot.timestamp,
              };
              accountSnapshot.snapshot.balances.forEach((snapshotBalance: any) => {
                const assetKey = getAssetKey(snapshotBalance);
                if (assetResults[assetKey] === undefined) {
                  // skip assets not in current balance
                  return;
                }
                let balanceKey = assetKey;
                if (
                  snapshotBalance.asset_type === "credit_alphanum4" ||
                  snapshotBalance.asset_type === "credit_alphanum12"
                ) {
                  const homeDomain = assetResults[assetKey].asset!.home_domain;
                  if (homeDomain) {
                    balanceKey = `${snapshotBalance.asset_code}:${homeDomain}`;
                  }
                }
                balance[balanceKey] = snapshotBalance.balance;
                _balanceKeys.push(balanceKey);
              });
              _balances.push(balance);
            });
            _balanceKeys = _.uniq(_balanceKeys);
            Set_balances(_balances);
            Set_balanceKeys(_balanceKeys);
          })
          .catch((e) => {
            Set_error(JSON.stringify(e));
          });
      }
    }
  }, [assetResults, error, fetchAccountSnapshots, allAssetsFetched]);

  return (
    <div {...rest}>
      <div className={"row g-0 d-flex align-content-between"}>
        <div className={"col-12 col-md-3 "}>
          <h2 className={"fs-5 p-3"}>Balance Activity</h2>
        </div>
        <div className={"col-12 col-md-9 align-items-center d-flex"}>
          {!error && balanceKeys && (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1}>
                <RefreshIcon style={{ cursor: "pointer" }} onClick={() => refresh()} />
              </Grid>
              <Grid item xs={6} sm={5}>
                <FormControl className="w-100">
                  <InputLabel id="balance-graph-asset-label">Asset</InputLabel>
                  <Select
                    labelId="balance-graph-asset-label"
                    id="balance-graph-asset"
                    value={selectedBalanceKey}
                    label={"Asset"}
                    onChange={(e) => Set_selectedBalanceKey(e.target.value)}
                    size={"small"}
                  >
                    <MenuItem value="all">
                      <span>All</span>
                    </MenuItem>
                    {balanceKeys.map((balanceKey, index) => (
                      <MenuItem value={balanceKey} key={index}>
                        {balanceKey.split(":")[0]}
                        &nbsp;
                        <span className={"text-muted"}>{balanceKey.split(":")[1]}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={3}>
                <FormControl className="w-100">
                  <InputLabel id="balance-graph-asset-label">Period</InputLabel>
                  <Select
                    disabled={true}
                    labelId="balance-graph-period-label"
                    id="balance-graph-period"
                    value={period}
                    label={"Last"}
                    onChange={(e) => Set_period(e.target.value)}
                    size={"small"}
                  >
                    <MenuItem value="year">
                      <span>Year</span>
                    </MenuItem>
                    <MenuItem value="month">
                      <span>Month</span>
                    </MenuItem>
                    <MenuItem value="week">
                      <span>Week</span>
                    </MenuItem>
                    <MenuItem value="day">
                      <span>Day</span>
                    </MenuItem>
                    <MenuItem value="hour">
                      <span>Hour</span>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <IOSSwitch disabled={true} checked={false} onChange={async (event, checked) => {}} />
                <span style={{ fontSize: "0.8em", paddingLeft: "0.5rem" }}>USDC Balance</span>
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      {error ? (
        <div className={"row g-0 d-flex align-content-between"}>
          <div className={"col d-flex justify-content-center text-danger pb-4"}>{`${error}`}</div>
        </div>
      ) : (
        <>
          {balanceKeys === undefined && (
            <Skeleton variant="rectangular" width={"80%"} height={200} className={"mx-4"} />
          )}
          {balanceKeys !== undefined && !balanceKeys.length && (
            <div className={"px-3"}>
              <span>
                No activity yet. It can take a few hours for the activity to be displayed here, please try again later.
              </span>
            </div>
          )}
          {balanceKeys !== undefined && !!balanceKeys.length && (
            <ResponsiveContainer width={"99%"} aspect={3}>
              <LineChart
                data={balances}
                margin={{
                  top: 5,
                  right: 100,
                  left: 50,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray={"3 3"} />
                <YAxis tickMargin={50} orientation={"right"}>
                  <Label
                    style={{
                      textAnchor: "middle",
                      fontSize: "1em",
                      fill: "black",
                      fontFamily: "Cairo",
                    }}
                    angle={270}
                    value={"Balance"}
                  />
                </YAxis>
                <XAxis dataKey={"timestamp"} />
                <Tooltip />

                <Legend height={50}  />

                {balanceKeys
                  .filter(
                    (balanceKey, index) =>
                      (selectedBalanceKey !== "all" && selectedBalanceKey === balanceKey) ||
                      (selectedBalanceKey === "all" && filterAssets.includes(balanceKey.split(":")[0]))
                  )
                  .map((balanceKey, index) => {
                    let lineColor: string;
                    if (balanceKey in lineColors) {
                      lineColor = lineColors[balanceKey];
                    } else {
                      lineColor = `#${Math.floor(Math.random() * 0xbb0abb).toString(16)}`;
                      Set_lineColors((prevLineColors: LineColors) => {
                        return {
                          ...prevLineColors,
                          [balanceKey]: lineColor,
                        };
                      });
                    }
                    return (
                      <Line
                        type={"linear"}
                        dataKey={balanceKey}
                        stroke={lineColor}
                        activeDot={{ r: 5 }}
                        strokeWidth={3}
                        key={index}
                      />
                    );
                  })}
              </LineChart>
            </ResponsiveContainer>
          )}
        </>
      )}
    </div>
  );
};
export default BalanceGraph;

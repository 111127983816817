enum RemitoneOutboundHookStatus {
  "PENDING" = "PENDING",
  "FETCHING" = "FETCHING",
  "FETCHED" = "FETCHED",
  "CREATING" = "CREATING",
  "DELETING" = "DELETING",
  "ERROR" = "ERROR",
}

export default RemitoneOutboundHookStatus;

import styled from "styled-components";

export const SmallSpan = styled.span`
  line-height: 1;
`;

export const AssetCard = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid lightgray;
`;

export const IconButtonToggle = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  color: inherit;
  font-family: Cairo,sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 8px;
  border-radius: 4px;
`;
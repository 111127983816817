import _ from "lodash";
import { SetStateAction } from "react";
import Asset from "./types/Asset";

const FileSaver = require("file-saver");
const { Parser } = require("json2csv");

const toml = require("toml");

declare var StellarSdk: any;

export const fetchHorizonAccount = async (
  horizonUrl: string,
  accountId: string
): Promise<any> => {
  const server = new StellarSdk.Server(horizonUrl);
  return await server.loadAccount(accountId);
};

export const memoizedFetchHorizonAccount = _.memoize(
  fetchHorizonAccount,
  (...args: any[]) => JSON.stringify(args)
);

export const fetchHorizonAsset = async (
  horizonUrl: string,
  assetCode: string,
  assetIssuer: string
): Promise<any> => {
  const server = new StellarSdk.Server(horizonUrl);
  return (
    await server.assets().forCode(assetCode).forIssuer(assetIssuer).call()
  ).records[0];
};

export const memoizedFetchHorizonAsset = _.memoize(
  fetchHorizonAsset,
  (...args: any[]) => JSON.stringify(args)
);

export const fetchStrictSendRate = async (
  horizonUrl: string,
  sourceAsset: any, // StellarSdk.Asset
  sourceAmount: string,
  destAssets: any[] // StellarSdk.Asset[]
): Promise<any> => {
  const server = new StellarSdk.Server(horizonUrl);
  const records: any[] = (
    await server.strictSendPaths(sourceAsset, sourceAmount, destAssets).call()
  ).records;
  if (records.length === 0) {
    return undefined;
  }
  records.sort(function (a: any, b: any) {
    const keyA = Number(a.destination_amount);
    const keyB = Number(b.destination_amount);
    if (keyA < keyB) return 1;
    if (keyA > keyB) return -1;
    return 0;
  });
  const record = records[0];
  return Number(record.destination_amount / record.source_amount);
};

export const memoizedFetchStrictSendRate = _.memoize(
  fetchStrictSendRate,
  (...args: any[]) => JSON.stringify(args)
);

export const fetchToml = async (homeDomain: string): Promise<any> => {
  const resp = await fetch(`https://${homeDomain}/.well-known/stellar.toml`);
  const respText = await resp.text();
  return toml.parse(respText);
};

export const memoizedFetchToml = _.memoize(fetchToml, (...args: any[]) =>
  JSON.stringify(args)
);

export const fetchTomlCurrency = async (
  homeDomain: string,
  code: string,
  issuer: string
): Promise<any> => {
  const assetToml = await memoizedFetchToml(homeDomain);
  for (const currency of assetToml.CURRENCIES) {
    if (currency.code === code && currency.issuer === issuer) {
      return currency;
    }
  }
};

export const memoizedFetchTomlCurrency = _.memoize(
  fetchTomlCurrency,
  (...args: any[]) => JSON.stringify(args)
);

export const getHorizonUrl = (testnet: boolean) => {
  if (testnet) {
    return "https://horizon-testnet.stellar.org";
  } else {
    return "https://horizon.stellar.org";
  }
};

export const getAssetKey = (balance: any): string => {
  if (balance.asset_type === "native") {
    return "XLM:native";
  } else if (
    balance.asset_type === "credit_alphanum4" ||
    balance.asset_type === "credit_alphanum12"
  ) {
    return `${balance.asset_code}:${balance.asset_issuer}`;
  } else if (balance.asset_type === "liquidity_pool_shares") {
    return balance.liquidity_pool_id;
  } else {
    throw new Error("Unable to determine asset key");
  }
};

export const setAssetResultValues = (
  Set_assetResults: SetStateAction<any>,
  assetKey: string,
  values: any
) => {
  Set_assetResults((prevAssets: any) => {
    const assetResult = prevAssets[assetKey];
    return {
      ...prevAssets,
      [assetKey]: {
        ...assetResult,
        ...values,
      },
    };
  });
};

export const fetchAssetResults = (
  memoized: boolean,
  accessToken: string,
  testnet: boolean,
  account: string,
  Set_horizonAccount: SetStateAction<any>,
  Set_assetResults: SetStateAction<any>,
  Set_error: SetStateAction<any>,
  marketAsset?: any // StellarSdk.Asset
) => {
  let _fetchHorizonAccount = fetchHorizonAccount;
  if (memoized) {
    _fetchHorizonAccount = memoizedFetchHorizonAccount;
  }
  _fetchHorizonAccount(getHorizonUrl(testnet), account)
    .then((account) => {
      Set_horizonAccount(account);
      account.balances
        .filter((balance: any, index: number) => {
          return (
            balance.asset_type === "credit_alphanum4" ||
            balance.asset_type === "credit_alphanum12" ||
            balance.asset_type === "native"
          );
        })
        .map((balance: any, index: number) => {
          const assetKey = getAssetKey(balance);
          setAssetResultValues(Set_assetResults, assetKey, {
            asset: undefined,
            error: undefined,
            marketAssetRate: undefined,
          });
          if (
            balance.asset_type === "credit_alphanum4" ||
            balance.asset_type === "credit_alphanum12"
          ) {
            let _fetchAsset = fetchAsset;
            if (memoized) {
              _fetchAsset = memoizedFetchAsset;
            }
            _fetchAsset(
              accessToken,
              testnet,
              balance.asset_code,
              balance.asset_issuer
            )
              .then((asset: Asset) => {
                setAssetResultValues(Set_assetResults, assetKey, {
                  asset: asset,
                });
              })
              .catch((e: any) =>
                setAssetResultValues(Set_assetResults, assetKey, { error: e })
              );
          }
          if (
            marketAsset &&
            (balance.asset_type === "credit_alphanum4" ||
              balance.asset_type === "credit_alphanum12" ||
              balance.asset_type === "native")
          ) {
            let _fetchStrictSendRate = fetchStrictSendRate;
            if (memoized) {
              _fetchStrictSendRate = memoizedFetchStrictSendRate;
            }
            _fetchStrictSendRate(
              getHorizonUrl(testnet),
              balance.asset_type === "native"
                ? StellarSdk.Asset.native()
                : new StellarSdk.Asset(
                    balance.asset_code,
                    balance.asset_issuer
                  ),
              "1",
              [marketAsset]
            )
              .then((marketAssetRate) => {
                if (marketAssetRate === undefined) {
                  setAssetResultValues(Set_assetResults, assetKey, {
                    marketAssetRate: "0",
                  });
                } else {
                  setAssetResultValues(Set_assetResults, assetKey, {
                    marketAssetRate: marketAssetRate,
                  });
                }
              })
              .catch((e) =>
                setAssetResultValues(Set_assetResults, assetKey, { error: e })
              );
          }
          return undefined;
        });
    })
    .catch((e) => Set_error(e));
};

export const shortPubkey = (pubkey: string) => {
  return `${pubkey.substring(0, 3)}...${pubkey.substring(
    pubkey.length - 3,
    pubkey.length
  )}`;
};

export const ellipsisString = (s: string, maxLength: number) => {
  return s.length > 50 ? s.substring(0, 50 - 3) + "..." : s;
};

export const memoizedFetchStellarExpertAccount = _.memoize(
  async (account: string): Promise<any> => {
    const resp = await fetch(
      `https://api.stellar.expert/explorer/directory/${account}`
    );
    if (!resp.ok) {
      return Promise.reject(resp);
    }
    return await resp.json();
  },
  (...args: any[]) => JSON.stringify(args)
);

const fetchAsset = async (
  accessToken: string,
  testnet: boolean,
  code: string,
  issuer?: string
): Promise<Asset> => {
  try {
    const resp = await fetch(
      `${process.env.REACT_APP_API_URL}/api/horizon/asset/?testnet=${
        testnet ? "true" : "false"
      }&code=${code}&issuer=${issuer ? issuer : ""}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!resp.ok) {
      const respJson = await resp.json();
      return Promise.reject(respJson);
    } else {
      return await resp.json();
    }
  } catch (e: any) {
    return Promise.reject(e);
  }
};

export const memoizedFetchAsset = _.memoize(fetchAsset, (...args: any[]) =>
  JSON.stringify(args)
);

export const isUsdc = (
  testnet: boolean,
  code: string,
  issuer: string
): boolean => {
  if (testnet) {
    return false;
  } else {
    return (
      code === "USDC" &&
      issuer === "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN"
    );
  }
};

export const exportToCsv = (
  filename: string,
  data: any[],
  fields?: string[]
) => {
  let parser;
  if (fields !== undefined) {
    parser = new Parser({ fields });
  } else {
    parser = new Parser();
  }
  const csv = parser.parse(data);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  FileSaver.saveAs(blob, filename);
};

export const capitalizeFirstLetter = (s: string): string => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const capitalizeFirstLetters = (s: string): string => {
  const words = s.split(" ");
  const capitalizedWords: string[] = [];
  words.forEach((word: string) =>
    capitalizedWords.push(word.charAt(0).toUpperCase() + word.slice(1))
  );
  return capitalizedWords.join(" ");
};

import classnames from "classnames";
import { useState } from "react";
import SettingsRemitoneInbound from "./SettingsRemitoneInbound";
import SettingsRemitoneInboundForm from "./SettingsRemitoneInboundForm";
import SettingsRemitoneOutbound from "./SettingsRemitoneOutbound";
import SettingsRemitoneOutboundForm from "./SettingsRemitoneOutboundForm";

const SettingsRemitone = () => {
  const [showSettingsRemitoneInboundForm, Set_showSettingsRemitoneInboundForm] =
    useState<boolean>(false);
  const [createRemitoneHookLoading, Set_createRemitoneHookLoading] =
    useState<boolean>(false);
  const [
    createRemitoneOutboundHookLoading,
    Set_createRemitoneOutboundHookLoading,
  ] = useState<boolean>(false);
  const [
    showSettingsRemitoneOutboundForm,
    Set_showSettingsRemitoneOutboundForm,
  ] = useState<boolean>(false);
  const [enableLoading, Set_enableLoading] = useState<boolean>(false);

  return (
    <div
      className={"col-8"}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      <div
        className={classnames({
          "d-none":
            showSettingsRemitoneInboundForm || showSettingsRemitoneOutboundForm,
        })}
      >
        <SettingsRemitoneInbound
          showSettingsRemitoneInboundForm={showSettingsRemitoneInboundForm}
          createRemitoneHookLoading={createRemitoneHookLoading}
          Set_enableLoading={Set_enableLoading}
          Set_showSettingsRemitoneInboundForm={
            Set_showSettingsRemitoneInboundForm
          }
          Set_createRemitoneHookLoading={Set_createRemitoneHookLoading}
        />
        <SettingsRemitoneOutbound
          showSettingsRemitoneOutboundForm={showSettingsRemitoneOutboundForm}
          createRemitoneOutboundHookLoading={createRemitoneOutboundHookLoading}
          Set_enableLoading={Set_enableLoading}
          Set_createRemitoneOutboundHookLoading={
            Set_createRemitoneOutboundHookLoading
          }
          Set_showSettingsRemitoneOutboundForm={
            Set_showSettingsRemitoneOutboundForm
          }
        />
      </div>
      <div
        className={classnames({
          "d-none": !showSettingsRemitoneInboundForm,
        })}
      >
        <SettingsRemitoneInboundForm
          onBack={() => Set_showSettingsRemitoneInboundForm(false)}
          enableLoading={enableLoading}
          createRemitoneHookLoading={createRemitoneHookLoading}
          showSettingsRemitoneInboundForm={showSettingsRemitoneInboundForm}
          Set_createRemitoneHookLoading={Set_createRemitoneHookLoading}
        />
      </div>
      <div
        className={classnames({
          "d-none": !showSettingsRemitoneOutboundForm,
        })}
      >
        <SettingsRemitoneOutboundForm
          onBack={() => Set_showSettingsRemitoneOutboundForm(false)}
          enableLoading={enableLoading}
          createRemitoneOutboundHookLoading={createRemitoneOutboundHookLoading}
          Set_createRemitoneOutboundHookLoading={
            Set_createRemitoneOutboundHookLoading
          }
          showSettingsRemitoneOutboundForm={showSettingsRemitoneOutboundForm}
        />
      </div>
    </div>
  );
};

export default SettingsRemitone;

import * as React from "react";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Button, CircularProgress, Skeleton } from "@mui/material";
import { ReactComponent as Stellar } from "../Stellar.svg";
import useStellarPay from "../useStellarPay";
import classnames from "classnames";
import InvalidPayloadError from "../errors/InvalidPayloadError";
import WalletStatus from "../types/WalletStatus";
import { Navigate } from "react-router-dom";
import { fetchHorizonAccount, getHorizonUrl } from "../utils";
import IOSSwitch from "../components/IOSSwitch";

const _ = require("lodash");

declare var StellarSdk: any;

const GetStartedPage = () => {
  const { wallet, walletStatus, createWallet, showAlertError } =
    useStellarPay();

  const [account, Set_account] = useState<string | undefined>(undefined);
  const [loadingHorizonAccount, Set_loadingHorizonAccount] = useState(false);
  const [accountError, Set_accountError] = useState<string | undefined>(
    undefined
  );
  const [testnet, Set_testnet] = useState<boolean>(false);
  const [createWalletError, Set_createWalletError] = useState<
    InvalidPayloadError | undefined
  >(undefined);

  useEffect(() => {
    (async () => {
      if (account) {
        try {
          Set_loadingHorizonAccount(true);
          await fetchHorizonAccount(getHorizonUrl(testnet), account);
          Set_accountError(undefined);
        } catch (e) {
          if (e instanceof StellarSdk.NotFoundError) {
            Set_accountError(
              "Account does not exist in Stellar chain. If this is a testnet account, make sure to enable TESTNET"
            );
          } else {
            showAlertError(`${e}`);
          }
        } finally {
          Set_loadingHorizonAccount(false);
        }
      }
    })();
  }, [account, showAlertError, testnet, accountError]);

  return (
    <>
      {!!wallet && <Navigate to="/wallet" />}
      {(walletStatus === WalletStatus.PENDING ||
        walletStatus === WalletStatus.FETCHING) && (
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={200}
          className={"m-4"}
        />
      )}
      {(walletStatus === WalletStatus.FETCHED ||
        walletStatus === WalletStatus.CREATING ||
        walletStatus === WalletStatus.ERROR) &&
        !wallet && (
          <div
            className={classnames("col", {
              disabled: walletStatus === WalletStatus.CREATING,
            })}
          >
            <div className={"row g-0"}>
              <div className={"col-xl-12 col-xxl-9 col-lg-12 col-12 px-5"}>
                <h2 className={"fs-5 pt-5 pb-2"}>Connect a Stellar account</h2>
                <h5
                  style={{
                    fontSize: "0.8em",
                    fontWeight: "normal",
                    lineHeight: "1.2rem",
                    paddingTop: "0.5rem",
                  }}
                >
                  Connect a Stellar account to get access to our Dashboard,
                  Wallet and Activity features.
                  <br />
                  You can connect any valid Stellar account by entering the
                  account public key below. Once it's connected, you'll get
                  access to our Dashboard features, and also disconnect/connect
                  another Stellar account at any time.
                </h5>
                <div className={"row g-0 mt-4 align-items-center"}>
                  <TextField
                    required
                    label={"Stellar account"}
                    helperText={
                      accountError
                        ? accountError
                        : createWalletError?.account
                        ? createWalletError?.account
                        : "Stellar account to connect. E.g. GCCG...Y53J"
                    }
                    error={!!createWalletError?.account || !!accountError}
                    style={{ width: "80%" }}
                    onChange={(event) => {
                      Set_createWalletError(
                        _.omit(createWalletError, "account")
                      );
                      Set_accountError(undefined);
                      if (
                        StellarSdk.StrKey.isValidEd25519PublicKey(
                          event.target.value
                        )
                      ) {
                        Set_account(event.target.value);
                      } else if (!event.target.value) {
                        Set_account(undefined);
                      } else {
                        Set_accountError(
                          "Please enter a valid Stellar account. E.g. GCCG...Y53J"
                        );
                        Set_account(undefined);
                      }
                    }}
                  />
                  <CircularProgress
                    size={"1em"}
                    style={{
                      marginLeft: "-2.5rem",
                      marginRight: "2.5rem",
                      marginBottom: "1.1rem",
                      visibility: loadingHorizonAccount ? "visible" : "hidden",
                    }}
                  />
                  <div
                    style={{
                      width: "15%",
                      display: "flex",
                      paddingBottom: "1.1rem",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <IOSSwitch
                      checked={testnet}
                      onChange={async (event, checked) => Set_testnet(checked)}
                    />
                    <span
                      style={{
                        fontSize: "0.8em",
                        paddingLeft: "0.5rem",
                        paddingRight: "0.5rem",
                      }}
                    >
                      TESTNET
                    </span>
                  </div>
                </div>
                <div
                  className="d-grid gap-2 pt-3 d-md-flex justify-content-end align-items-center"
                  style={{ width: "80%" }}
                >
                  {walletStatus === WalletStatus.CREATING && (
                    <CircularProgress size={"1.5rem"} className={"me-2"} />
                  )}
                  <Button
                    variant={"contained"}
                    endIcon={<Stellar width={24} height={24} fill={"white"} />}
                    onClick={async () => {
                      try {
                        await createWallet({
                          testnet: testnet,
                          account: account ?? "",
                        });
                      } catch (e) {
                        if (e instanceof InvalidPayloadError) {
                          Set_createWalletError(e);
                        } else {
                          showAlertError(`${e}`);
                        }
                      }
                    }}
                  >
                    CONNECT
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
    </>
  );
};

export default GetStartedPage;

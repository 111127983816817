import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Skeleton } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import dateFormat from "dateformat";
import useStellarPay from "../useStellarPay";
import { exportToCsv, shortPubkey } from "../utils";
import RemitoneOutboundPayment from "../types/RemitoneOutboundPayment";
import RefreshIcon from "@mui/icons-material/Refresh";

const RemitoneOutboundPayments = ({ ...props }) => {
  const { ...rest } = props;
  const { remitoneOutboundHook, fetchRemitoneOutboundPayments } =
    useStellarPay();
  const [remitoneOutboundPayments, Set_remitoneOutboundPayments] = useState<
    RemitoneOutboundPayment[] | undefined
  >(undefined);
  const [error, Set_error] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (
      remitoneOutboundHook?.enabled &&
      remitoneOutboundPayments === undefined &&
      error === undefined
    ) {
      fetchRemitoneOutboundPayments()
        .then((remitoneOutboundPayments) => {
          Set_remitoneOutboundPayments(remitoneOutboundPayments);
        })
        .catch((e) => Set_error(JSON.stringify(e)));
    }
  }, [
    remitoneOutboundHook,
    remitoneOutboundPayments,
    error,
    fetchRemitoneOutboundPayments,
  ]);

  const refresh = () => {
    if (remitoneOutboundHook?.enabled) {
      fetchRemitoneOutboundPayments()
        .then((remitoneOutboundPayments) => {
          Set_remitoneOutboundPayments(remitoneOutboundPayments);
        })
        .catch((e) => Set_error(JSON.stringify(e)));
    }
  };

  const exportRemitoneOutboundPaymentsToCsv = () => {
    if (remitoneOutboundPayments) {
      const outboundPayments = [];
      for (const outboundPayment of remitoneOutboundPayments) {
        outboundPayments.push({
          id: outboundPayment.id,
          created: outboundPayment.created,
          r1_trans_ref: outboundPayment.r1_trans_ref,
          status: outboundPayment.status,
          stellar_testnet: outboundPayment.stellar_testnet,
          stellar_from_account: outboundPayment.stellar_from_account,
          stellar_to_account: outboundPayment.stellar_to_account,
          stellar_asset_code: outboundPayment.stellar_asset_code,
          stellar_asset_issuer: outboundPayment.stellar_asset_issuer,
          stellar_amount: outboundPayment.stellar_amount,
          stellar_tx_hash: outboundPayment.stellar_tx_hash,
        });
      }

      const now = new Date();
      const filename = `remitone-inbound-transactions-${dateFormat(
        now,
        "yyyy-mm-dd_HH-MM-ss"
      )}.csv`;
      exportToCsv(filename, outboundPayments);
    }
  };

  return (
    <div {...rest}>
      <div className={"row g-0"}>
        <div className={"col-3 pb-2"}>
          <h2 className={"fs-5 p-3"}>Outbound Transactions</h2>
        </div>
        <div className={"col-9"}>
          {remitoneOutboundPayments !== undefined && (
            <div
              className={"d-flex justify-content-end align-items-center"}
              style={{ flexDirection: "row" }}
            >
              <RefreshIcon
                className={"me-3"}
                style={{ cursor: "pointer" }}
                onClick={() => refresh()}
              />
              {remitoneOutboundPayments.length > 0 && (
                <SaveAltIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => exportRemitoneOutboundPaymentsToCsv()}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {error ? (
        <div className={"row g-0"}>
          <div className={"col d-flex justify-content-center text-danger pb-4"}>
            {`${error}`}
          </div>
        </div>
      ) : (
        <>
          {remitoneOutboundHook === undefined ||
          remitoneOutboundPayments === undefined ? (
            <div className={"row g-0"}>
              <div className={"col"}>
                <Skeleton
                  variant="rectangular"
                  width={"95%"}
                  height={50}
                  className={"my-2 ms-3"}
                />
                <Skeleton
                  variant="rectangular"
                  width={"95%"}
                  height={50}
                  className={"my-2 ms-3"}
                />
              </div>
            </div>
          ) : (
            <div className={"row g-0"}>
              <div className={"col ps-3 pe-2"}>
                <table className={"table"}>
                  <thead>
                    <tr>
                      <th scope={"col"}>Date</th>
                      <th scope={"col"}>Ref</th>
                      <th scope={"col"}>Status</th>
                      <th scope={"col"}>From</th>
                      <th scope={"col"}>To</th>
                      <th scope={"col"}>Asset</th>
                      <th scope={"col"}>Amount</th>
                      <th scope={"col"}>Links</th>
                    </tr>
                  </thead>
                  <tbody>
                    {remitoneOutboundPayments.map((outboundPayment, index) => {
                      return (
                        <tr key={index}>
                          <td>{outboundPayment.created}</td>
                          <td>{outboundPayment.r1_trans_ref}</td>
                          <td>{outboundPayment.status}</td>
                          <td>
                            {outboundPayment.stellar_from_account
                              ? shortPubkey(
                                  outboundPayment.stellar_from_account
                                )
                              : ""}
                          </td>
                          <td>
                            {outboundPayment.stellar_to_account
                              ? shortPubkey(outboundPayment.stellar_to_account)
                              : ""}
                          </td>
                          <td>{outboundPayment.stellar_asset_code}</td>
                          <td>{outboundPayment.stellar_amount}</td>
                          <td>
                            <a
                              href={`https://stellar.expert/explorer/${
                                outboundPayment.stellar_testnet
                                  ? "testnet"
                                  : "public"
                              }/op/${outboundPayment.stellar_tx_hash}`}
                              rel="noopener noreferrer"
                              target="_blank"
                              title={"View in Stellar.Expert"}
                            >
                              <img
                                alt="Stellar Expert"
                                height={18}
                                src={"/stellar-expert-blue.svg"}
                              />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RemitoneOutboundPayments;

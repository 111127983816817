import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  FormControl,
  FormHelperText,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
} from "@mui/material";
import classnames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import InvalidPayloadError from "../../../errors/InvalidPayloadError";
import PayoutAccount from "../../../types/PayoutAccount";
import ScheduledPayoutType from "../../../types/ScheduledPayoutType";
import useStellarPay from "../../../useStellarPay";
import "./AddPayoutAccountForm.css";

const _ = require("lodash");

type NewScheduledPayoutProps = {
  onBack: (e: unknown) => void;
  onSaved: () => void;
};

const NewScheduledPayout: FunctionComponent<NewScheduledPayoutProps> = ({
  onBack,
  onSaved,
}) => {
  const {
    fetchPayoutAccounts,
    createScheduledPayout,
    showAlertError,
    showAlertSuccess,
  } = useStellarPay();
  const [saving, Set_saving] = useState<boolean>(false);
  const [createScheduledPayoutError, Set_createScheduledPayoutError] = useState<
    InvalidPayloadError | undefined
  >(undefined);
  const [stellarAccount, Set_stellarAccount] = useState<string | undefined>(
    undefined
  );
  const [scheduleType, Set_scheduleType] = useState<string | undefined>(
    undefined
  );
  const [interval, Set_interval] = useState<string | undefined>(undefined);
  const [balanceThreshold, Set_balanceThreshold] = useState<string | undefined>(
    undefined
  );
  const [destinationPayoutId, Set_destinationPayoutId] = useState<
    string | undefined
  >(undefined);
  const [payoutAccounts, Set_payoutAccounts] = useState<
    PayoutAccount[] | undefined
  >(undefined);
  const [payoutAccountsError, Set_payoutAccountsError] = useState<
    any | undefined
  >(undefined);
  useEffect(() => {
    fetchPayoutAccounts()
      .then((accounts) => Set_payoutAccounts(accounts))
      .catch((e) => Set_payoutAccountsError(JSON.stringify(e)));
  }, [fetchPayoutAccounts]);
  return (
    <div
      className={classnames("col-8", {
        disabled: saving,
      })}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      <div className={"row g-0 pb-3"}>
        <div className={"col"}>
          <div className="arrow-title-container">
            <div onClick={(e: any) => onBack(e)}>
              <ArrowBackIcon className="arrow-back-icon" />
            </div>
            <h1 className={"fs-5 fw-bold"}>New Scheduled Payout</h1>
          </div>
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12">
          <TextField
            label={"Source Stellar Account"}
            className={"mb-3 w-100"}
            error={!!createScheduledPayoutError?.stellar_account}
            helperText={
              createScheduledPayoutError?.alias
                ? createScheduledPayoutError?.alias
                : "Stellar Account to send the payout from"
            }
            onChange={(event: any) => {
              Set_createScheduledPayoutError(
                _.omit(createScheduledPayoutError, "stellar_account")
              );
              Set_stellarAccount(event.target.value);
            }}
          />
        </div>
      </div>
      {payoutAccountsError !== undefined ? (
        <div className={"row g-0"}>
          <div
            className={"col d-flex justify-content-center text-danger pb-4"}
          >{`${payoutAccountsError}`}</div>
        </div>
      ) : payoutAccounts === undefined ? (
        <Skeleton
          variant="rectangular"
          width={"90%"}
          height={60}
          className={"mb-4"}
        />
      ) : (
        <div className={"row g-0"}>
          <div className="col-12 col-sm-6">
            <FormControl className={"mb-3 w-100"}>
              <InputLabel id="new-scheduled-payout-destination-label">
                Destination Payout Account
              </InputLabel>
              <Select
                labelId="new-scheduled-payout-destination-label"
                id="new-scheduled-payout-destination"
                value={destinationPayoutId ?? ""}
                label="Destination Payout Account"
                onChange={(e) => Set_destinationPayoutId(e.target.value)}
              >
                {payoutAccounts?.map(
                  (payoutAccount: PayoutAccount, index: number) => {
                    return (
                      <MenuItem value={payoutAccount.id} key={payoutAccount.id}>
                        {payoutAccount.alias}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
              <FormHelperText>Destination Payout Account</FormHelperText>
            </FormControl>
          </div>
        </div>
      )}
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6 pe-3">
          <FormControl className={"mb-3 w-100"}>
            <InputLabel id="new-scheduled-payout-schedule-type-label">
              Schedule Type
            </InputLabel>
            <Select
              labelId="new-scheduled-payout-schedule-type-label"
              id="new-scheduled-payout-schedule-type"
              value={scheduleType ?? ""}
              label="Schedule Type"
              onChange={(e) => Set_scheduleType(e.target.value)}
            >
              <MenuItem value={"INTERVAL"}>By Interval</MenuItem>
              <MenuItem value={"THRESHOLD"}>By Threshold</MenuItem>
            </Select>
            <FormHelperText>Schedule type</FormHelperText>
          </FormControl>
        </div>
        {scheduleType === "INTERVAL" && (
          <div className="col-12 col-sm-6">
            <FormControl className={"mb-3 w-100"}>
              <InputLabel id="new-scheduled-payout-interval-label">
                Interval
              </InputLabel>
              <Select
                labelId="new-scheduled-payout-interval-label"
                id="new-scheduled-payout-interval"
                value={interval ?? ""}
                error={!!createScheduledPayoutError?.interval}
                label="Interval"
                onChange={(e) => {
                  Set_createScheduledPayoutError(
                    _.omit(createScheduledPayoutError, "i")
                  );
                  Set_interval(e.target.value);
                }}
              >
                <MenuItem value={"DAILY"}>Daily</MenuItem>
                <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
              </Select>
              <FormHelperText>
                {createScheduledPayoutError?.interval ? (
                  <span className="text-danger">
                    {createScheduledPayoutError?.interval}
                  </span>
                ) : (
                  "Interval to automatically send the payout"
                )}
              </FormHelperText>
            </FormControl>
          </div>
        )}
        {scheduleType === "THRESHOLD" && (
          <div className="col-12 col-sm-6">
            <TextField
              label={"USDC Balance Threshold"}
              className={"mb-3 w-100"}
              error={!!createScheduledPayoutError?.balance_threshold}
              helperText={
                createScheduledPayoutError?.balance_threshold
                  ? createScheduledPayoutError?.balance_threshold
                  : "Send payout when USDC balance is equal or higher than this amount. E.g. 20.38"
              }
              onChange={(event: any) => {
                Set_createScheduledPayoutError(
                  _.omit(createScheduledPayoutError, "balance_threshold")
                );
                Set_balanceThreshold(event.target.value);
              }}
            />
          </div>
        )}
      </div>
      <div className={"row g-0 pt-3 pb-5"}>
        <div className="d-grid gap-2 d-md-flex justify-content-end align-items-center">
          {saving && <CircularProgress size={"1.5rem"} className={"me-2"} />}

          <Button
            variant={"contained"}
            color={"error"}
            onClick={(e: any) => onBack(e)}
          >
            CANCEL
          </Button>
          <Button
            variant={"contained"}
            onClick={async () => {
              Set_saving(true);

              try {
                if (scheduleType === undefined) {
                  const error = new InvalidPayloadError();
                  error["type"] = "This field is required";
                  throw error;
                }
                await createScheduledPayout({
                  type: ScheduledPayoutType[
                    scheduleType as keyof typeof ScheduledPayoutType
                  ],
                  account_id: destinationPayoutId ?? "",
                  interval: interval ?? "",
                  balance_threshold: balanceThreshold ?? "",
                  stellar_account: stellarAccount ?? "",
                });
                Set_saving(false);
                showAlertSuccess("Scheduled Payout created");
                onSaved();
              } catch (e) {
                Set_saving(false);
                if (e instanceof InvalidPayloadError) {
                  Set_createScheduledPayoutError(e);
                } else {
                  showAlertError(`${e}`);
                }
              }
            }}
          >
            DONE
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewScheduledPayout;

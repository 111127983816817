import { Alert } from "@mui/material";
import { FunctionComponent } from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";
import useStellarPay from "../useStellarPay";
import GetStartedPage from "./GetStartedPage";
import LandingPage from "./LandingPage";
import RemitonePage from "./RemitonePage";
import SettingsPage from "./SettingsPage";
import WalletPage from "./WalletPage";

type AppWithContextProps = {
  path: string;
};

const TopAlert = styled(Alert)`
  position: fixed;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`;

const AppWithContext: FunctionComponent<AppWithContextProps> = ({ path }) => {
  const { alertSuccess, alertError, hideAlert } = useStellarPay();

  return (
    <div className={"App"}>
      {alertSuccess && (
        <TopAlert severity="success">
          {alertSuccess}
          <span
            style={{ cursor: "pointer", marginLeft: "1rem" }}
            onClick={hideAlert}
          >
            &#10006;
          </span>
        </TopAlert>
      )}
      {alertError && (
        <TopAlert severity="error">
          {alertError}
          <span
            style={{ cursor: "pointer", marginLeft: "1rem" }}
            onClick={hideAlert}
          >
            &#10006;
          </span>
        </TopAlert>
      )}
      <main>
        <div className={"row g-0 flex-nowrap bg-light"}>
          {path === "/" && <LandingPage />}
          {path !== "/" && (
            <Layout>
              <div className={"col"}>
                <div className={"row g-0"}>
                  {path === "/wallet" && <Navbar title={"Wallet"} />}
                  {path === "/get-started" && <Navbar title={"Get Started"} />}
                  {path === "/remitone" && <Navbar title={"RemitONE"} />}
                  {path === "/settings" && <Navbar title={"Settings"} />}
                </div>
                <div className="row g-0" style={{ minHeight: "100vh" }}>
                  {path === "/wallet" && <WalletPage />}
                  {path === "/get-started" && <GetStartedPage />}
                  {path === "/remitone" && <RemitonePage />}
                  {path === "/settings" && <SettingsPage />}
                </div>
              </div>
            </Layout>
          )}
        </div>
      </main>
    </div>
  );
};

export default AppWithContext;

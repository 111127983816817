//import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import KeyIcon from "@mui/icons-material/Key";
import { useState } from "react";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsApiKey from "../components/settings/apikey/SettingsApiKey";
import SettingsPayouts from "../components/settings/payout/SettingsPayouts";
import SettingsRemitone from "../components/settings/remitone/SettingsRemitone";
import SettingsMenuItem from "../components/settings/SettingsMenuItem";
import SettingsWallet from "../components/settings/wallet/SettingsWallet";
import SettingsIntegrations from "../components/settings/integrations/SettingsIntegrations";
import SettingsAccount from "../components/settings/account/SettingsAccount";
import { ReactComponent as Stellar } from "../Stellar.svg";
import "./SettingsPage.css";

enum SelectedItem {
  None = 0,
  Account = 1,
  ApiKey = 2,
  Wallet = 3,
  Remitone = 4,
  BankAccounts = 5,
  Payouts = 6,
  Integrations = 7,
}

const SettingsPage = () => {
  const [selectedItem, Set_selectedItem] = useState(SelectedItem.None);
  return (
    <div className={"row g-0"}>
      <nav style={{ width: 270 }} className={"border-end"}>
        <ul
          className={"d-flex"}
          style={{
            listStyleType: "none",
            flexDirection: "column",
            paddingTop: 15,
            paddingLeft: 30,
            paddingRight: 15,
          }}
        >
          <SettingsMenuItem
            onClick={() => Set_selectedItem(SelectedItem.Account)}
            isSelected={selectedItem === SelectedItem.Account}
            icon={<AccountCircleIcon sx={{ fontSize: 35 }} />}
            title={"Account"}
            arrow={true}
          />
          <SettingsMenuItem
            onClick={() => Set_selectedItem(SelectedItem.ApiKey)}
            isSelected={selectedItem === SelectedItem.ApiKey}
            icon={<KeyIcon sx={{ fontSize: 35 }} />}
            title={"API Key"}
            arrow={true}
          />
          <SettingsMenuItem
            onClick={() => Set_selectedItem(SelectedItem.Wallet)}
            isSelected={selectedItem === SelectedItem.Wallet}
            icon={
              <Stellar
                width={35}
                height={35}
                fill={
                  selectedItem === SelectedItem.Wallet ? "#0226b5" : "black"
                }
              />
            }
            title={"Wallet"}
            arrow={true}
          />
          <SettingsMenuItem
            onClick={() => Set_selectedItem(SelectedItem.Remitone)}
            isSelected={selectedItem === SelectedItem.Remitone}
            icon={
              <img
                alt={"RemitONE"}
                src={`/remitone-${
                  selectedItem === SelectedItem.Remitone ? "blue" : "black"
                }.png`}
                height={35}
              />
            }
            title={"RemitONE"}
            arrow={true}
          />
          {/*<SettingsMenuItem
            onClick={() => Set_selectedItem(SelectedItem.Payouts)}
            isSelected={selectedItem === SelectedItem.Payouts}
            icon={<AccountBalanceIcon sx={{ fontSize: 35 }} />}
            title={"USDC Payouts"}
            arrow={true}
          />*/}
          <SettingsMenuItem
            onClick={() => Set_selectedItem(SelectedItem.Integrations)}
            isSelected={selectedItem === SelectedItem.Integrations}
            icon={<SyncAltIcon sx={{ fontSize: 35 }} />}
            title={"Integrations"}
            arrow={true}
          />
        </ul>
      </nav>
      {selectedItem === SelectedItem.Account && <SettingsAccount />}
      {selectedItem === SelectedItem.ApiKey && <SettingsApiKey />}
      {selectedItem === SelectedItem.Wallet && <SettingsWallet />}
      {selectedItem === SelectedItem.Remitone && <SettingsRemitone />}
      {selectedItem === SelectedItem.Payouts && <SettingsPayouts />}
      {selectedItem === SelectedItem.Integrations && <SettingsIntegrations />}
    </div>
  );
};

export default SettingsPage;

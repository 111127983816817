import {
  Button,
  CircularProgress,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import { ReactNode, useState } from "react";
import ProfileStatus from "../../../types/ProfileStatus";
import useStellarPay from "../../../useStellarPay";

const _ = require("lodash");

const SettingsAccount = () => {
  const { profile, profileStatus, uploadProfileFile, partialUpdateProfile } =
    useStellarPay();
  const [updateProfileError, Set_updateProfileError] = useState<
    any | undefined
  >(undefined);
  const [loading, Set_loading] = useState<boolean>(false);
  const [brandName, Set_brandName] = useState<string | undefined>(undefined);
  const [brandIconIndicator, Set_brandIconIndicator] = useState<
    ReactNode | undefined
  >(undefined);

  return (
    <div
      className={"col-8"}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      {profileStatus === ProfileStatus.ERROR ? (
        <></>
      ) : profileStatus === ProfileStatus.PENDING ||
        profileStatus === ProfileStatus.FETCHING ? (
        <LinearProgress />
      ) : (
        <div
          className={classnames({ disabled: loading || profile === undefined })}
        >
          <div className="row">
            <h2 className={"fs-5 fw-bold"}>Branding</h2>
          </div>
          <Stack direction="column">
            <h6>Brand Icon:</h6>
            <Stack direction="row" className="align-items-center">
              {profile?.icon_45x45 ? (
                <>
                  <img
                    alt="Brand Icon"
                    src={profile?.icon_45x45}
                    style={{ width: 45, height: 45 }}
                  />
                </>
              ) : (
                <>
                  <img
                    alt="Brand Icon"
                    src="/bpventures.jpg"
                    style={{ width: 45, height: 45 }}
                  />
                </>
              )}
              <Button variant="contained" component="label" className="ms-2">
                CHANGE...
                <input
                  hidden
                  name="icon"
                  type="file"
                  onChange={async (event) => {
                    try {
                      if (event.target.files) {
                        const formData = new FormData();
                        formData.append("file", event.target.files[0]);
                        Set_brandIconIndicator(
                          <CircularProgress
                            size={"1.2rem"}
                            className={"ms-2"}
                          />
                        );
                        Set_loading(true);
                        await uploadProfileFile(
                          event.target.files[0],
                          "icon_45x45"
                        );
                        Set_brandIconIndicator(undefined);
                      }
                    } catch (e: any) {
                      Set_brandIconIndicator(
                        <span className="text-danger">{`${e}`}</span>
                      );
                    } finally {
                      Set_loading(false);
                    }
                  }}
                />
              </Button>
              {brandIconIndicator === undefined ? <></> : brandIconIndicator}
            </Stack>
            <Typography
              className="mt-1"
              sx={{ fontSize: "0.7em" }}
              color="text.secondary"
            >
              Recommended image size: 45x45 pixels
            </Typography>
            <TextField
              required
              label={"Brand Name"}
              helperText={
                updateProfileError?.brand_name
                  ? updateProfileError?.brand_name
                  : "E.g. My Company"
              }
              error={!!updateProfileError?.brand_name}
              className="col-12 col-sm-6 col-md-4 mt-4"
              defaultValue={profile?.brand_name ?? ""}
              onChange={(event: any) => {
                Set_updateProfileError(
                  _.omit(updateProfileError, "brand_name")
                );
                Set_brandName(event.target.value);
              }}
            />
          </Stack>
          <div className="row g-0">
            <div className="col-12 col-sm-6 col-md-4 d-grid gap-2 d-md-flex justify-content-end align-items-center">
              <Button
                variant="contained"
                className="ms-2"
                onClick={async () => {
                  Set_loading(true);
                  try {
                    await partialUpdateProfile(profile?.id ?? "", {
                      brand_name: brandName,
                    });
                  } catch (e: any) {
                    Set_updateProfileError(e);
                  } finally {
                    Set_loading(false);
                  }
                }}
              >
                SUBMIT
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsAccount;

import CheckIcon from "@mui/icons-material/Check";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import { LinearProgress } from "@mui/material";
import { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { fetchApikey } from "../../../auth0_utils";
import ExternalLinkCard from "../../../components/ExternalLinkCard";
import useStellarPay from "../../../useStellarPay";
import ButtonGenerateApiKey from "../../ButtonGenerateApiKey";

const SettingsApiKey = () => {
  const { getAccessToken } = useStellarPay();
  const [loadingApiKey, Set_loadingApiKey] = useState<boolean>(true);
  const [apiKey, Set_apiKey] = useState<string | undefined>(undefined);
  const [error, Set_error] = useState<any | undefined>(undefined);
  const [apiKeyCopied, Set_apiKeyCopied] = useState<boolean>(false);

  useEffect(() => {
    fetchApikey(getAccessToken())
      .then((apiKey) => Set_apiKey(apiKey))
      .catch((e) => Set_error(JSON.stringify(e)))
      .finally(() => Set_loadingApiKey(false));
  }, [getAccessToken]);

  return (
    <div
      className={"col-8"}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      {apiKey && (
        <div className={"row g-0 mb-4"}>
          <div className={"col"}>
            <h3 className={"fs-6 pb-3"}>Your API Key:</h3>
            <span
              className={"p-2 d-inline-flex"}
              style={{ backgroundColor: "lightgray" }}
            >
              {apiKey}
              <div
                title={"Copy API key"}
                role="button"
                className={"ms-2 d-flex align-items-center"}
              >
                <CopyToClipboard
                  text={apiKey}
                  onCopy={() => {
                    Set_apiKeyCopied(true);
                    setTimeout(() => Set_apiKeyCopied(false), 3000);
                  }}
                >
                  {apiKeyCopied ? (
                    <CheckIcon className={"public-key-copy"} />
                  ) : (
                    <ContentCopyIcon className={"public-key-copy"} />
                  )}
                </CopyToClipboard>
              </div>
            </span>
          </div>
        </div>
      )}
      {!loadingApiKey && !apiKey && (
        <div className={"row g-0"}>
          <div className={"col"}>
            <h3 className={"fs-6"}>
              Click the button below to generate your API Key
            </h3>
          </div>
        </div>
      )}
      {!loadingApiKey && (
        <>
          <div className={"row g-0 mt-2"}>
            <div className={"col"}>
              <ButtonGenerateApiKey
                label={apiKey ? "REGENERATE API KEY" : "CREATE API KEY"}
                onGenerating={() => Set_loadingApiKey(true)}
                onSuccess={(apiKey) => {
                  Set_apiKey(apiKey);
                  Set_loadingApiKey(false);
                  Set_error(undefined);
                }}
                onError={(error) => {
                  Set_error(JSON.stringify(error));
                  Set_loadingApiKey(false);
                }}
              />
            </div>
          </div>
          <div className={"row g-0 mt-3"}>
            <ExternalLinkCard
              isSelected={false}
              icon={<MenuBookOutlinedIcon sx={{ fontSize: 35 }} />}
              title={"API Documentation"}
              description={"Learn and explore our API endpoints"}
              linkTo={`${process.env.REACT_APP_API_URL}/api/schema/swagger-ui/`}
            />
          </div>
        </>
      )}
      {error && (
        <div className={"row g-0 mt-2"}>
          <div className={"col"}>
            <span className={"text-danger"}>{`${error}`}</span>
          </div>
        </div>
      )}
      {loadingApiKey && <LinearProgress className={"mb-3"} />}
    </div>
  );
};

export default SettingsApiKey;

const SettingsIntegrations = ({ ...props }) => {
  return (
    <>
      <div className="col-8 ps-5 pt-3 pb-3 pe-2">
        <div className={"row"}>
          <h2 className={"fs-5 fw-bold"}>Zapier</h2>
        </div>
        <div className="row">
          <zapier-zap-templates
            apps="bp-pay"
            create-without-template="hide"
            limit="10"
            use-this-zap="show"
            theme="light"
          />
        </div>
        <div className="row"></div>
      </div>
    </>
  );
};

export default SettingsIntegrations;

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { Box, Drawer, Skeleton } from "@mui/material";
import classNames from "classnames";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarContext } from "../SidebarContext";
import { ReactComponent as Stellar } from "../Stellar.svg";
import WalletStatus from "../types/WalletStatus";
import useStellarPay from "../useStellarPay";
import "./Sidebar.css";

const drawerWidth = 251;

const Sidebar = (props: any) => {
  const pathname = useLocation().pathname;
  const { wallet, walletStatus, remitoneHook, remitoneOutboundHook, profile } =
    useStellarPay();
  const { window } = props;
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const { handleDrawerToggle, mobileOpen } = React.useContext(SidebarContext);

  const drawer = (
    <div
      className={"d-flex flex-column flex-shrink-0 p-4 bg-white"}
      style={{
        height: "100vh",
        width: "250px",
        borderRight: "solid #e7e7ef 2px",
      }}
    >
      <Link
        to="/wallet"
        className={
          "d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
        }
      >
        <img
          src={profile?.icon_45x45 ? profile.icon_45x45 : "/bpventures.jpg"}
          alt={profile?.brand_name ? profile.brand_name : "BP Pay"}
        />
        <span className={"fs-5 ms-2 fw-semibold"}>
          {profile?.brand_name ? profile.brand_name : "BP Pay"}
        </span>
      </Link>
      <ul className={"nav nav-pills flex-column mt-5 mb-auto"}>
        {walletStatus === WalletStatus.PENDING ||
        walletStatus === WalletStatus.FETCHING ? (
          <>
            {[1, 2, 3, 4].map((number) => (
              <Skeleton
                variant="rectangular"
                width={"90%"}
                className={"mb-2"}
                height={40}
                key={number}
              />
            ))}
          </>
        ) : (
          <>
            {wallet && (
              <li className={"nav-item"}>
                <Link
                  to="/wallet"
                  className={classNames("d-flex align-items-center nav-link", {
                    active: pathname === "/wallet",
                    "link-dark": pathname !== "/wallet",
                  })}
                  aria-current={"page"}
                >
                  <Stellar
                    width={24}
                    height={24}
                    fill={pathname === "/wallet" ? "#0226b5" : "black"}
                  />
                  <span className={"ms-3"}>Wallet</span>
                </Link>
              </li>
            )}
            {!wallet && (
              <li className={"nav-item"}>
                <Link
                  to={"/get-started"}
                  className={classNames("d-flex align-items-center nav-link", {
                    active: pathname === "/get-started",
                    "link-dark": pathname !== "/get-started",
                  })}
                  aria-current={"page"}
                >
                  <ArrowForwardIcon />
                  <span className={"ms-3"}>Get Started</span>
                </Link>
              </li>
            )}
            {(remitoneHook?.enabled || remitoneOutboundHook?.enabled) && (
              <li className={"nav-item"}>
                <Link
                  to={"/remitone"}
                  className={classNames("d-flex align-items-center nav-link", {
                    active: pathname === "/remitone",
                    "link-dark": pathname !== "/remitone",
                  })}
                  aria-current={"page"}
                >
                  <img
                    alt={"RemitONE"}
                    src={`/remitone-${
                      pathname === "/remitone" ? "blue" : "black"
                    }.png`}
                    height={24}
                  />
                  <span className={"ms-3"}>RemitONE</span>
                </Link>
              </li>
            )}
            <hr />
            <li className={"nav-item"}>
              <Link
                to={"/settings"}
                className={classNames("d-flex align-items-center nav-link", {
                  active: pathname === "/settings",
                  "link-dark": pathname !== "/settings",
                })}
                aria-current={"page"}
              >
                <SettingsOutlinedIcon />
                <span className={"ms-3"}>Settings</span>
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
  // Code based on https://getbootstrap.com/docs/5.0/examples/
  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen as boolean}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};
export default Sidebar;

import { useState } from "react";
import AddPayoutAccountForm from "./AddPayoutAccountForm";
import PayoutAccounts from "./PayoutAccounts";
import ScheduledPayouts from "./ScheduledPayouts";
import NewScheduledPayout from "./NewScheduledPayout";

const SettingsPayouts = ({ ...props }) => {
  const [showAddPayoutAccountForm, Set_showAddPayoutAccountForm] = useState<
    boolean | undefined
  >(false);
  const [showNewScheduledPayout, Set_showNewScheduledPayout] = useState<
    boolean | undefined
  >(false);

  return (
    <>
      {showAddPayoutAccountForm ? (
        <AddPayoutAccountForm
          onBack={(e: any) => Set_showAddPayoutAccountForm(false)}
          onSaved={() => Set_showAddPayoutAccountForm(false)}
        />
      ) : showNewScheduledPayout ? (
        <NewScheduledPayout
          onBack={(e: any) => Set_showNewScheduledPayout(false)}
          onSaved={() => Set_showNewScheduledPayout(false)}
        />
      ) : (
        <>
          <div className="col-8">
            <div className="row">
              <PayoutAccounts
                onAddClick={(e: any) => Set_showAddPayoutAccountForm(true)}
              />
            </div>
            <div className="row">
              <ScheduledPayouts
                onNewClick={(e: any) => Set_showNewScheduledPayout(true)}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default SettingsPayouts;

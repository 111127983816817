import { useAuth0 } from "@auth0/auth0-react";
import React, { FunctionComponent, useEffect, useState } from "react";
import AppWithContext from "./AppWithContext";
import StellarPayProvider from "../StellarPayProvider";
import { Skeleton } from "@mui/material";

type AppProps = {
  path: string;
};

const App: FunctionComponent<AppProps> = ({ path }) => {
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  const [accessToken, Set_accessToken] = useState("");

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, loginWithRedirect, isAuthenticated]);

  useEffect(() => {
    (async (): Promise<void> => {
      if (isAuthenticated) {
        Set_accessToken(await getAccessTokenSilently());
      }
    })();
  }, [isAuthenticated, getAccessTokenSilently]);

  return (
    <>
      {isLoading && (
        <div className={"container-fluid"}>
          <div className={"row"}>
            <div className={"col-12 col-sm-2"}>
              <div className={"pt-4 pb-4"}>
                <img src={"/bpventures.jpg"} alt={"BP Pay"} />
                <span className={"fs-5 ms-2 fw-semibold"}>BP Pay</span>
              </div>
              {[1, 2, 3, 4].map((number) => (
                <Skeleton
                  variant="rectangular"
                  className={"mb-2"}
                  width={"100%"}
                  height={40}
                  key={number}
                />
              ))}
            </div>
            <div className={"col-12 col-sm-10"}>
              <Skeleton
                variant="rectangular"
                className={"mt-4"}
                width={"100%"}
                height={300}
              />
            </div>
          </div>
        </div>
      )}
      {accessToken && (
        <StellarPayProvider accessToken={accessToken}>
          <AppWithContext path={path} />
        </StellarPayProvider>
      )}
    </>
  );
};

export default App;

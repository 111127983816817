import { useAuth0 } from "@auth0/auth0-react";
import { Button, Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { Navigate } from "react-router-dom";
import PublicPagesHeader from "../components/PublicPagesHeader";

const LandingPage = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  return (
    <>
      {isLoading ? (
        <></>
      ) : (
        <>
          {isAuthenticated ? (
            <Navigate to={"/wallet"} />
          ) : (
            <div>
              <PublicPagesHeader />
              <Container className="mt-4 mt-md-5" maxWidth="xl">
                <Grid container spacing={5}>
                  <Grid item xs={12} md={6} className="">
                    <Typography variant="h4" component="h4">
                      Accept USDC Payments in as fast as 5 seconds. Get lightning fast, no chargeback payments and
                      withdrawals with our powerful API.
                      <br />
                      <br />
                      Featuring 1 click integration with RemitONE
                    </Typography>
                    <Button
                      size="large"
                      className="mx-auto d-flex mt-5"
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => loginWithRedirect()}
                    >
                      Get started
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6} className="mt-3 mt-md-0">
                    <img alt="Activity" className="img-fluid" src={"/screen-shot.png"} />
                  </Grid>
                </Grid>
              </Container>
            </div>
          )}
        </>
      )}
    </>
  );
};
export default LandingPage;

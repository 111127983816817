import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import classnames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import InvalidPayloadError from "../../../errors/InvalidPayloadError";
import RemitoneOutboundHookStatus from "../../../types/RemitoneOutboundHookStatus";
import useStellarPay from "../../../useStellarPay";
import {
  fetchHorizonAccount,
  getAssetKey,
  getHorizonUrl,
  isUsdc,
} from "../../../utils";
import { IconButtonToggle } from "../../custom-styled-components";
import IOSSwitch from "../../IOSSwitch";

const _ = require("lodash");

declare var StellarSdk: any;

type SettingsRemitoneOutboundFormProps = {
  className?: string;
  onBack: (e: unknown) => void;
  enableLoading: boolean;
  showSettingsRemitoneOutboundForm: boolean;
  createRemitoneOutboundHookLoading: boolean;
  Set_createRemitoneOutboundHookLoading: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const SettingsRemitoneOutboundForm: FunctionComponent<
  SettingsRemitoneOutboundFormProps
> = ({
  onBack,
  showSettingsRemitoneOutboundForm,
  enableLoading,
  createRemitoneOutboundHookLoading,
  Set_createRemitoneOutboundHookLoading,
}) => {
  const {
    remitoneOutboundHook,
    remitoneOutboundHookStatus,
    createRemitoneOutboundHook,
    hasRemitoneOutboundCredentials,
    createRemitoneOutboundCredentials,
    showAlertError,
    showAlertSuccess,
  } = useStellarPay();
  const [enabled, Set_enabled] = useState<boolean>(false);
  const [hotwalletSecret, Set_hotwalletSecret] = useState<string | undefined>(
    undefined
  );
  const [testnet, Set_testnet] = useState<boolean>(false);
  const [hotwalletSecretError, Set_hotwalletSecretError] = useState<
    string | undefined
  >(undefined);
  const [showHotwallet, Set_showHotwallet] = useState<boolean>(false);
  const [horizonAccount, Set_horizonAccount] = useState<any | undefined>(
    undefined
  );
  const [asset, Set_asset] = useState<string | undefined>(undefined);
  const [lowFundsEmailEnabled, Set_lowFundsEmailEnabled] =
    useState<boolean>(false);
  const [lowFundsEmail, Set_lowFundsEmail] = useState<string | undefined>(
    undefined
  );
  const [lowFundsEmailBalance, Set_lowFundsEmailBalance] = useState<
    string | undefined
  >(undefined);
  const [url, Set_url] = useState<string | undefined>(undefined);
  const [currency, Set_currency] = useState<string | undefined>(undefined);
  const [username, Set_username] = useState<string | undefined>(undefined);
  const [password, Set_password] = useState<string | undefined>(undefined);
  const [showPassword, Set_showPassword] = useState<boolean>(false);
  const [pin, Set_pin] = useState<string | undefined>(undefined);
  const [showPin, Set_showPin] = useState<boolean>(false);
  const [createRemitoneOutboundHookError, Set_createRemitoneOutboundHookError] =
    useState<InvalidPayloadError | undefined>(undefined);

  const [
    createRemitoneOutboundCredentialsError,
    Set_createRemitoneOutboundCredentialsError,
  ] = useState<InvalidPayloadError | undefined>(undefined);

  useEffect(() => {
    Set_enabled(!!remitoneOutboundHook?.enabled);
  }, [showSettingsRemitoneOutboundForm, remitoneOutboundHook]);

  useEffect(() => {
    Set_hotwalletSecret(undefined);
    Set_hotwalletSecretError(undefined);
    Set_horizonAccount(undefined);
    Set_asset(undefined);
    Set_lowFundsEmailEnabled(false);
    Set_lowFundsEmail(undefined);
    Set_lowFundsEmailBalance(undefined);
    Set_url(undefined);
    Set_currency(undefined);
    Set_username(undefined);
    Set_password(undefined);
    Set_pin(undefined);
    Set_createRemitoneOutboundHookError(undefined);
    Set_createRemitoneOutboundCredentialsError(undefined);
    Set_createRemitoneOutboundHookLoading(false);
    Set_createRemitoneOutboundHookError(undefined);
  }, [
    enabled,
    showSettingsRemitoneOutboundForm,
    Set_createRemitoneOutboundHookLoading,
  ]);

  useEffect(() => {
    Set_hotwalletSecretError(undefined);
    Set_horizonAccount(undefined);
    Set_asset(undefined);
    Set_lowFundsEmailEnabled(false);
    Set_lowFundsEmail(undefined);
    Set_lowFundsEmailBalance(undefined);
    Set_url(undefined);
    Set_currency(undefined);
    Set_username(undefined);
    Set_password(undefined);
    Set_pin(undefined);
    Set_createRemitoneOutboundHookLoading(false);
    Set_createRemitoneOutboundHookError(undefined);

    (async function () {
      if (hotwalletSecret) {
        const keypair = StellarSdk.Keypair.fromSecret(hotwalletSecret);
        fetchHorizonAccount(getHorizonUrl(testnet), keypair.publicKey())
          .then((account) => {
            Set_hotwalletSecretError(undefined);
            Set_horizonAccount(account);
            account.balances
              .filter((balance: any, index: number) => {
                return (
                  balance.asset_type === "credit_alphanum4" ||
                  balance.asset_type === "credit_alphanum12"
                );
              })
              .forEach((balance: any) => {
                if (isUsdc(testnet, balance.asset_code, balance.asset_issuer)) {
                  Set_asset(getAssetKey(balance));
                }
              });
          })
          .catch((e) => {
            if (e instanceof StellarSdk.NotFoundError) {
              Set_hotwalletSecretError(
                "Account does not exist in Stellar chain. If this is a testnet account, make sure to enable TESTNET"
              );
            } else {
              Set_hotwalletSecretError(`${e}`);
            }
          });
      } else {
        Set_horizonAccount(undefined);
      }
    })();
  }, [hotwalletSecret, testnet, Set_createRemitoneOutboundHookLoading]);

  const assetCodeFromAsset = (asset: string) => {
    return asset.split(":")[0];
  };

  return (
    <div
      className={classnames("pb-4", {
        disabled:
          remitoneOutboundHookStatus === RemitoneOutboundHookStatus.PENDING ||
          remitoneOutboundHookStatus === RemitoneOutboundHookStatus.FETCHING ||
          createRemitoneOutboundHookLoading,
      })}
    >
      <div className={"row g-0 pb-3"}>
        <div className={"col"}>
          <div className="arrow-title-container">
            <div onClick={(e: any) => onBack(e)}>
              <ArrowBackIcon className="arrow-back-icon" />
            </div>
            <h1 className={"fs-5 fw-bold"}>
              Connect RemitONE Outbound Account
            </h1>
          </div>
        </div>
      </div>

      {showSettingsRemitoneOutboundForm &&
        !enableLoading &&
        !remitoneOutboundHook && (
          <div className={"col-8 mt-2 mb-4 w-100 "}>
            <Box borderRadius={"4px"} border={"1px solid lightgray"}>
              <div className={"row g-0 align-items-start"}>
                <div className={"col p-3"}>
                  <div
                    className={
                      "d-flex justify-content-between align-items-baseline g-0"
                    }
                  >
                    <FormControl
                      style={{ width: "80%", marginBottom: "1rem" }}
                      variant="outlined"
                    >
                      <InputLabel htmlFor="remitone-outbound-hotwallet">
                        {!!hotwalletSecretError ||
                        !!createRemitoneOutboundCredentialsError?.stellar_secret ? (
                          <span className={"text-danger"}>
                            Stellar hotwallet secret key. E.g. SBY...ACG
                          </span>
                        ) : (
                          <span>
                            {"Stellar hotwallet secret key. E.g. SBY...ACG"}
                          </span>
                        )}
                      </InputLabel>
                      <OutlinedInput
                        id={"remitone-outbound-hotwallet"}
                        type={showHotwallet ? "text" : "password"}
                        error={
                          !!hotwalletSecretError ||
                          !!createRemitoneOutboundCredentialsError?.stellar_secret
                        }
                        label={"Stellar hotwallet secret key. E.g. SBY...ACG"}
                        aria-describedby="remitone-outbound-hotwallet-helper-text"
                        autoFocus={true}
                        onChange={(event) => {
                          Set_createRemitoneOutboundCredentialsError(
                            _.omit(
                              createRemitoneOutboundCredentialsError,
                              "stellar_secret"
                            )
                          );
                          if (
                            StellarSdk.StrKey.isValidEd25519SecretSeed(
                              event.target.value
                            )
                          ) {
                            Set_hotwalletSecretError(undefined);
                            Set_hotwalletSecret(event.target.value);
                          } else if (!event.target.value) {
                            Set_hotwalletSecretError(undefined);
                            Set_hotwalletSecret(undefined);
                          } else {
                            Set_hotwalletSecretError(
                              "Please enter a valid Stellar secret key. E.g. SBY...ACG"
                            );
                            Set_hotwalletSecret(undefined);
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle hotwallet visibility"
                              onClick={() =>
                                Set_showHotwallet((prevValue) => !prevValue)
                              }
                              onMouseDown={(event) => event.preventDefault()}
                              edge="end"
                              color={"primary"}
                              sx={{ borderRadius: 2 }}
                            >
                              {showHotwallet ? (
                                <IconButtonToggle>Hide</IconButtonToggle>
                              ) : (
                                <IconButtonToggle>Show</IconButtonToggle>
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <FormHelperText id="remitone-outbound-hotwallet-helper-text">
                        {hotwalletSecretError ? (
                          <span className={"text-danger"}>
                            {hotwalletSecretError}
                          </span>
                        ) : createRemitoneOutboundCredentialsError?.stellar_secret ? (
                          <span className={"text-danger"}>
                            {
                              createRemitoneOutboundCredentialsError.stellar_secret
                            }
                          </span>
                        ) : (
                          "When a payment is received in RemitONE, this is the Stellar account that will send the " +
                          "Stellar payment to the beneficiary Stellar account. E.g. SBY...ACG"
                        )}
                      </FormHelperText>
                    </FormControl>
                    <FormControlLabel
                      control={
                        <IOSSwitch
                          checked={testnet}
                          onChange={async (event, checked) =>
                            Set_testnet(checked)
                          }
                        />
                      }
                      label={
                        <Typography
                          variant="caption"
                          display="block"
                          className="mt-1"
                        >
                          TESTNET
                        </Typography>
                      }
                      labelPlacement="bottom"
                    />
                  </div>

                  {hotwalletSecret &&
                    !horizonAccount &&
                    !hotwalletSecretError && <LinearProgress />}

                  {horizonAccount && (
                    <>
                      <FormControl
                        style={{
                          width: "99.5%",
                          marginBottom: "1rem",
                        }}
                      >
                        <InputLabel id="remitone-asset-label">
                          {!!createRemitoneOutboundHookError?.asset_code ||
                          !!createRemitoneOutboundHookError?.asset_issuer ? (
                            <span className={"text-danger"}>Stellar Asset</span>
                          ) : (
                            <span>Stellar Asset</span>
                          )}
                        </InputLabel>
                        <Select
                          labelId="remitone-asset-label"
                          id="remitone-asset"
                          value={asset ?? ""}
                          label={"Stellar Asset"}
                          aria-describedby="remitone-outbound-asset-helper-text"
                          error={
                            !!createRemitoneOutboundHookError?.asset_code ||
                            !!createRemitoneOutboundHookError?.asset_issuer
                          }
                          onChange={(e) => {
                            Set_createRemitoneOutboundHookError(
                              _.omit(
                                _.omit(
                                  createRemitoneOutboundHookError,
                                  "asset_issuer"
                                ),
                                "asset_code"
                              )
                            );
                            Set_asset(e.target.value);
                          }}
                        >
                          {horizonAccount.balances
                            .filter((balance: any, index: number) => {
                              return (
                                balance.asset_type === "credit_alphanum4" ||
                                balance.asset_type === "credit_alphanum12"
                              );
                            })
                            .map((balance: any, index: number) => {
                              const value = getAssetKey(balance);
                              return (
                                <MenuItem value={value} key={index}>
                                  {balance.asset_code}
                                  &nbsp;
                                  <span className={"text-muted"}>
                                    {balance.asset_issuer}
                                  </span>
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText
                          id={"remitone-outbound-asset-helper-text"}
                        >
                          {createRemitoneOutboundHookError?.asset_code ? (
                            <span className={"text-danger"}>
                              {" "}
                              createRemitoneOutboundHookError?.asset_code
                            </span>
                          ) : createRemitoneOutboundHookError?.asset_issuer ? (
                            <span className={"text-danger"}>
                              createRemitoneOutboundHookError?.asset_issuer
                            </span>
                          ) : (
                            <span>
                              Stellar asset to be used in the Stellar payment
                            </span>
                          )}
                        </FormHelperText>
                      </FormControl>

                      <div className={"row g-0 align-items-start"}>
                        {asset && (
                          <>
                            <div
                              className={
                                "d-flex align-items-center justify-content-start"
                              }
                              style={{ width: "99.5%", marginBottom: "1rem" }}
                            >
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={lowFundsEmailEnabled ?? false}
                                onChange={async (event, checked) =>
                                  Set_lowFundsEmailEnabled(checked)
                                }
                              />
                              <span>
                                Send email alert when hotwallet{" "}
                                {assetCodeFromAsset(asset)} balance is low
                              </span>
                            </div>
                            {lowFundsEmailEnabled && (
                              <TextField
                                required
                                label={"Email to receive low funds alert"}
                                helperText={
                                  createRemitoneOutboundHookError?.low_funds_notif_to_email
                                    ? createRemitoneOutboundHookError?.low_funds_notif_to_email
                                    : "E.g. myemail@mydomain.com"
                                }
                                error={
                                  !!createRemitoneOutboundHookError?.low_funds_notif_to_email
                                }
                                style={{ width: "99.5%", marginBottom: "1rem" }}
                                onChange={(e) => {
                                  Set_createRemitoneOutboundHookError(
                                    _.omit(
                                      createRemitoneOutboundHookError,
                                      "low_funds_notif_to_email"
                                    )
                                  );
                                  Set_lowFundsEmail(e.target.value);
                                }}
                              />
                            )}
                            {lowFundsEmailEnabled && (
                              <TextField
                                required
                                label={`Minimum hotwallet ${assetCodeFromAsset(
                                  asset
                                )} balance`}
                                helperText={
                                  createRemitoneOutboundHookError?.low_funds_notif_balance
                                    ? createRemitoneOutboundHookError?.low_funds_notif_balance
                                    : `If hotwallet ${assetCodeFromAsset(
                                        asset
                                      )} balance is below this value, you'll receive a low funds email alert`
                                }
                                error={
                                  !!createRemitoneOutboundHookError?.low_funds_notif_balance
                                }
                                style={{ width: "99.5%", marginBottom: "1rem" }}
                                onChange={(e) => {
                                  Set_createRemitoneOutboundHookError(
                                    _.omit(
                                      createRemitoneOutboundHookError,
                                      "low_funds_notif_balance"
                                    )
                                  );
                                  Set_lowFundsEmailBalance(e.target.value);
                                }}
                              />
                            )}
                          </>
                        )}

                        <TextField
                          required
                          label={"RemitONE Admin WebServices URL"}
                          helperText={
                            createRemitoneOutboundCredentialsError?.url
                              ? createRemitoneOutboundCredentialsError?.url
                              : "E.g. https://test4.remit.by/mycompanytest/ws"
                          }
                          error={!!createRemitoneOutboundCredentialsError?.url}
                          style={{ width: "99.5%", marginBottom: "1rem" }}
                          onChange={(e) => {
                            Set_createRemitoneOutboundCredentialsError(
                              _.omit(
                                createRemitoneOutboundCredentialsError,
                                "url"
                              )
                            );
                            Set_url(e.target.value);
                          }}
                        />
                        <div className="row g-0">
                          <div className="col-12 col-md-6 ">
                            <TextField
                              required
                              label={"RemitONE Currency"}
                              helperText={
                                createRemitoneOutboundHookError?.r1_currency
                                  ? createRemitoneOutboundHookError?.r1_currency
                                  : "RemitONE currency. E.g. USD, EUR, IDR, JPY."
                              }
                              style={{ width: "99.5%", marginBottom: "1rem" }}
                              error={
                                !!createRemitoneOutboundHookError?.r1_currency
                              }
                              onChange={(e) => {
                                Set_createRemitoneOutboundHookError(
                                  _.omit(
                                    createRemitoneOutboundHookError,
                                    "r1_currency"
                                  )
                                );
                                Set_currency(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6 pe-md-1">
                          <TextField
                            required
                            label={"Admin WebServices Username"}
                            style={{ width: "99.5%", marginBottom: "1rem" }}
                            error={
                              !!createRemitoneOutboundCredentialsError?.username
                            }
                            helperText={
                              createRemitoneOutboundCredentialsError?.username
                                ? createRemitoneOutboundCredentialsError?.username
                                : "RemitONE Admin WebServices Username"
                            }
                            onChange={(e) => {
                              Set_createRemitoneOutboundCredentialsError(
                                _.omit(
                                  createRemitoneOutboundCredentialsError,
                                  "username"
                                )
                              );
                              Set_username(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-12 col-md-6 ps-md-1">
                          <FormControl
                            style={{ width: "99.5%", marginBottom: "1rem" }}
                            variant="outlined"
                          >
                            <InputLabel htmlFor="remitone-password">
                              {!!createRemitoneOutboundCredentialsError?.password ? (
                                <span className={"text-danger"}>
                                  {
                                    createRemitoneOutboundCredentialsError.password
                                  }
                                </span>
                              ) : (
                                <span>RemitONE Admin WebServices Password</span>
                              )}
                            </InputLabel>
                            <OutlinedInput
                              required
                              id="remitone-password"
                              type={showPassword ? "text" : "password"}
                              aria-describedby="remitone-outbound-password-helper-text"
                              onChange={(e) => {
                                Set_createRemitoneOutboundCredentialsError(
                                  _.omit(
                                    createRemitoneOutboundCredentialsError,
                                    "password"
                                  )
                                );
                                Set_password(e.target.value);
                              }}
                              error={
                                !!createRemitoneOutboundCredentialsError?.password
                              }
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle remitone password visibility"
                                    onClick={() =>
                                      Set_showPassword(
                                        (prevValue) => !prevValue
                                      )
                                    }
                                    onMouseDown={(event) =>
                                      event.preventDefault()
                                    }
                                    edge="end"
                                    color={"primary"}
                                    sx={{ borderRadius: 2 }}
                                  >
                                    {showPassword ? (
                                      <IconButtonToggle>Hide</IconButtonToggle>
                                    ) : (
                                      <IconButtonToggle>Show</IconButtonToggle>
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              }
                              label="Admin WebServices Password"
                            />
                            <FormHelperText
                              id={"remitone-outbound-password-helper-text"}
                            >
                              {createRemitoneOutboundCredentialsError?.password ? (
                                <span className={"text-danger"}>
                                  {
                                    createRemitoneOutboundCredentialsError.password
                                  }
                                </span>
                              ) : (
                                "RemitONE Admin WebServices Password"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </div>

                        <FormControl
                          style={{ width: "99.5%", marginBottom: "1rem" }}
                          variant="outlined"
                        >
                          <InputLabel htmlFor="remitone-pin">
                            {!!createRemitoneOutboundCredentialsError?.pin ? (
                              <span className={"text-danger"}>
                                {createRemitoneOutboundCredentialsError?.pin}
                              </span>
                            ) : (
                              <span>Admin WebServices PIN</span>
                            )}
                          </InputLabel>
                          <OutlinedInput
                            required
                            id="remitone-pin"
                            type={showPin ? "text" : "password"}
                            aria-describedby="remitone-outbound-pin-helper-text"
                            onChange={(e) => {
                              Set_createRemitoneOutboundCredentialsError(
                                _.omit(
                                  createRemitoneOutboundCredentialsError,
                                  "pin"
                                )
                              );
                              Set_pin(e.target.value);
                            }}
                            error={
                              !!createRemitoneOutboundCredentialsError?.pin
                            }
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle remitone pin visibility"
                                  onClick={() =>
                                    Set_showPin((prevValue) => !prevValue)
                                  }
                                  onMouseDown={(event) =>
                                    event.preventDefault()
                                  }
                                  edge="end"
                                  color={"primary"}
                                  sx={{ borderRadius: 2 }}
                                >
                                  {showPin ? (
                                    <IconButtonToggle>Hide</IconButtonToggle>
                                  ) : (
                                    <IconButtonToggle>Show</IconButtonToggle>
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            label="Admin WebServices PIN"
                          />
                          <FormHelperText
                            id={"remitone-outbound-pin-helper-text"}
                          >
                            {createRemitoneOutboundCredentialsError?.pin ? (
                              <span className={"text-danger"}>
                                {createRemitoneOutboundCredentialsError.pin}
                              </span>
                            ) : (
                              "RemitONE Admin WebServices PIN"
                            )}
                          </FormHelperText>
                        </FormControl>
                      </div>
                      <div className="d-grid gap-2 d-md-flex justify-content-end align-items-center">
                        {createRemitoneOutboundHookLoading && (
                          <CircularProgress
                            size={"1.5rem"}
                            className={"me-2"}
                          />
                        )}

                        <Button
                          variant={"contained"}
                          color={"error"}
                          onClick={() => {
                            Set_enabled(false);
                            onBack(false);
                          }}
                        >
                          CANCEL
                        </Button>
                        <Button
                          variant={"contained"}
                          onClick={async () => {
                            Set_createRemitoneOutboundHookLoading(true);
                            let hasCredentials = false;
                            try {
                              hasCredentials =
                                await hasRemitoneOutboundCredentials();
                            } catch (e) {
                              Set_createRemitoneOutboundHookLoading(false);
                              showAlertError(`${e}`);
                              return;
                            }
                            if (!hasCredentials) {
                              try {
                                await createRemitoneOutboundCredentials({
                                  url: url ?? "",
                                  username: username ?? "",
                                  password: password ?? "",
                                  pin: pin ?? "",
                                  stellar_secret: hotwalletSecret ?? "",
                                });
                              } catch (e) {
                                Set_createRemitoneOutboundHookLoading(false);
                                if (e instanceof InvalidPayloadError) {
                                  Set_createRemitoneOutboundCredentialsError(e);
                                } else {
                                  showAlertError(`${e}`);
                                }
                                return;
                              }
                            }

                            try {
                              await createRemitoneOutboundHook({
                                enabled: true,
                                testnet: testnet,
                                asset_code: asset ? asset?.split(":")[0] : "",
                                asset_issuer: asset ? asset.split(":")[1] : "",
                                r1_currency: currency ?? "",
                                low_funds_email_enabled: lowFundsEmailEnabled,
                                low_funds_email: lowFundsEmail ?? "",
                                low_funds_email_balance: lowFundsEmailBalance
                                  ? lowFundsEmailBalance
                                  : undefined,
                              });
                              Set_createRemitoneOutboundHookLoading(false);
                              showAlertSuccess(
                                "RemitONE Outbound integration enabled"
                              );
                              onBack(true);
                            } catch (e) {
                              Set_createRemitoneOutboundHookLoading(false);
                              if (e instanceof InvalidPayloadError) {
                                Set_createRemitoneOutboundHookError(e);
                              } else {
                                showAlertError(`${e}`);
                              }
                            }
                          }}
                        >
                          DONE
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Box>
          </div>
        )}
    </div>
  );
};

export default SettingsRemitoneOutboundForm;

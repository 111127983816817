import { faArrowRight, faShuffle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { Skeleton } from "@mui/material";
import dateFormat from "dateformat";
import { useEffect, useState } from "react";
import { ReactComponent as Stellar } from "../Stellar.svg";
import Payment from "../types/Payment";
import useStellarPay from "../useStellarPay";
import {
  ellipsisString,
  exportToCsv,
  memoizedFetchStellarExpertAccount,
  shortPubkey,
} from "../utils";
import PaymentCard from "./PaymentCard";

const Payments = ({ ...props }) => {
  const { ...rest } = props;
  const { wallet, fetchPayments } = useStellarPay();
  const [payments, Set_payments] = useState<Payment[] | undefined>(undefined);
  const [stellarExpertAccounts, Set_stellarExpertAccounts] = useState<
    any | undefined
  >(undefined);
  const [allFetched, Set_allFetched] = useState<boolean>(false);
  const [error, Set_error] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (wallet && payments === undefined && error === undefined) {
      fetchPayments()
        .then((payments) => {
          Set_payments(payments);
          if (wallet.testnet) {
            Set_allFetched(true);
          }
        })
        .catch((e) => Set_error(JSON.stringify(e)));
    }
  }, [wallet, payments, error, fetchPayments]);

  useEffect(() => {
    if (wallet && payments !== undefined && error === undefined) {
      if (!wallet.testnet && stellarExpertAccounts === undefined) {
        Set_stellarExpertAccounts({});
        payments.forEach((payment) => {
          for (const account of [payment.from_account, payment.to_account]) {
            memoizedFetchStellarExpertAccount(account)
              .then((stellarExpertAccount) => {
                Set_stellarExpertAccounts((prev: any) => {
                  return {
                    ...prev,
                    [account]: stellarExpertAccount,
                  };
                });
              })
              .catch((e) => Set_error(JSON.stringify(e)));
          }
        });
      }
      if (stellarExpertAccounts !== undefined) {
        let _allFetched = true;
        payments.forEach((payment) => {
          for (const account of [payment.from_account, payment.to_account]) {
            if (!(account in stellarExpertAccounts)) {
              _allFetched = false;
            }
          }
        });
        if (_allFetched) {
          Set_allFetched(true);
        }
      }
    }
  }, [stellarExpertAccounts, wallet, payments, error]);

  const refresh = () => {
    if (wallet) {
      Set_allFetched(false);
      fetchPayments()
        .then((payments) => {
          Set_payments(payments);
          if (wallet.testnet) {
            Set_allFetched(true);
          }
        })
        .catch((e) => Set_error(JSON.stringify(e)));
    }
  };

  const exportPaymentsToCsv = () => {
    if (payments) {
      const _payments = [];
      for (const payment of payments) {
        _payments.push({
          id: payment.id,
          stellar_payment_id: payment.stellar_payment_id,
          created_at: payment.created_at,
          from_account: payment.from_account,
          to_account: payment.to_account,
          type: payment.type,
          asset_type: payment.asset_type,
          asset_code: payment.asset_code,
          asset_issuer: payment.asset_issuer,
          amount: payment.amount,
          memo: payment.transaction.memo,
        });
      }

      const now = new Date();
      const filename = `wallet-payments-${dateFormat(
        now,
        "yyyy-mm-dd_HH-MM-ss"
      )}.csv`;
      exportToCsv(filename, _payments);
    }
  };

  return (
    <div {...rest}>
      <div className={"row g-0"}>
        <div className={"col-3 pb-2"}>
          <h2 className={"fs-5 p-3"}>Payments</h2>
        </div>
        <div className={"col-9"}>
          {allFetched && (
            <div
              className={"d-flex justify-content-end align-items-center"}
              style={{ flexDirection: "row" }}
            >
              <RefreshIcon
                className={"me-3"}
                style={{ cursor: "pointer" }}
                onClick={() => refresh()}
              />
              {payments !== undefined && payments.length > 0 && (
                <SaveAltIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => exportPaymentsToCsv()}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {error ? (
        <div className={"row g-0"}>
          <div
            className={"col d-flex justify-content-center text-danger pb-4"}
          >{`${error}`}</div>
        </div>
      ) : (
        <>
          {wallet === undefined || payments === undefined || !allFetched ? (
            <div className={"row g-0"}>
              <div className={"col"}>
                <Skeleton
                  variant="rectangular"
                  width={"95%"}
                  height={50}
                  className={"my-2 ms-3"}
                />
                <Skeleton
                  variant="rectangular"
                  width={"95%"}
                  height={50}
                  className={"my-2 ms-3"}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="d-lg-none">
                {payments
                  .filter((payment) => {
                    return (
                      (payment.asset_type === "credit_alphanum4" ||
                        payment.asset_type === "credit_alphanum12" ||
                        payment.asset_type === "native") &&
                      payment.from_account !== payment.to_account &&
                      payment.to_account === wallet.account //TODO allow filtering received or all payments
                    );
                  })
                  .map((payment, index) => {
                    const fromAccountName = wallet.testnet
                      ? undefined
                      : stellarExpertAccounts[payment.from_account].name;
                    const toAccountName = wallet.testnet
                      ? undefined
                      : stellarExpertAccounts[payment.to_account].name;
                    return (
                      <PaymentCard
                        className="mb-3"
                        payment={payment}
                        fromAccountName={fromAccountName}
                        toAccountName={toAccountName}
                      />
                    );
                  })}
              </div>
              <div className={"d-none d-lg-block row g-0 "}>
                <div className={"col ps-3 pe-2"}>
                  <table className={"table text-break"}>
                    <thead>
                      <tr>
                        <th scope={"col"}>Date</th>
                        <th scope={"col"}>From</th>
                        <th scope={"col"}>To</th>
                        <th scope={"col"}>Asset</th>
                        <th scope={"col"}>Amount</th>
                        <th scope={"col"}>Memo</th>
                        <th scope={"col"}>Links</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments
                        .filter((payment) => {
                          return (
                            (payment.asset_type === "credit_alphanum4" ||
                              payment.asset_type === "credit_alphanum12" ||
                              payment.asset_type === "native") &&
                            payment.from_account !== payment.to_account &&
                            payment.to_account === wallet.account //TODO allow filtering received or all payments
                          );
                        })
                        .map((payment, index) => {
                          const fromAccountName = wallet.testnet
                            ? undefined
                            : stellarExpertAccounts[payment.from_account].name;
                          const toAccountName = wallet.testnet
                            ? undefined
                            : stellarExpertAccounts[payment.to_account].name;
                          return (
                            <tr key={index}>
                              <td style={{ paddingRight: 0 }}>
                                <div
                                  className={
                                    "row g-0 d-flex align-items-center justify-content-between"
                                  }
                                >
                                  <div style={{ width: "80%" }}>
                                    {payment.created_at}
                                  </div>
                                  {payment.from_account === wallet.account && (
                                    <div
                                      style={{ width: 15 }}
                                      className={
                                        "d-flex p-0 align-items-center justify-content-end"
                                      }
                                    >
                                      <Stellar
                                        width={15}
                                        height={15}
                                        fill={"darkslategray"}
                                        title={
                                          payment.from_account ===
                                          wallet.account
                                            ? "This is your Wallet account"
                                            : ""
                                        }
                                      />
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td style={{ paddingRight: 0 }}>
                                <div className={"col"}>
                                  <div
                                    className={
                                      "row g-0 d-flex align-items-center justify-content-between "
                                    }
                                  >
                                    <div
                                      className={
                                        "d-flex p-0 align-items-center justify-content-between"
                                      }
                                      style={{ width: "70%" }}
                                    >
                                      <span>
                                        {shortPubkey(payment.from_account)}
                                      </span>
                                      {payment.type === "payment" && (
                                        <div
                                          style={{
                                            width: 15,
                                            display: "block",
                                          }}
                                          className={
                                            "d-flex p-0 ps-1 align-items-center"
                                          }
                                        >
                                          <FontAwesomeIcon
                                            size={"1x"}
                                            icon={faArrowRight}
                                            title={"Payment"}
                                          />
                                        </div>
                                      )}
                                      {payment.type ===
                                        "path_payment_strict_send" && (
                                        <div
                                          style={{ width: 15 }}
                                          className={
                                            "d-flex p-0 ps-1 align-items-center"
                                          }
                                        >
                                          <FontAwesomeIcon
                                            size={"1x"}
                                            icon={faShuffle}
                                            title={"Path Payment"}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        "d-flex p-0 align-items-center justify-content-end"
                                      }
                                      style={{ width: "30%" }}
                                    >
                                      {payment.to_account ===
                                        wallet.account && (
                                        <div
                                          style={{ width: 20 }}
                                          className={
                                            "d-flex p-0 align-items-center justify-content-end"
                                          }
                                        >
                                          <Stellar
                                            width={15}
                                            height={15}
                                            fill={"darkslategray"}
                                            title={
                                              payment.to_account ===
                                              wallet.account
                                                ? "This is your Wallet account"
                                                : ""
                                            }
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {fromAccountName && (
                                    <div
                                      className={
                                        "row g-0 d-flex align-items-center justify-content-between"
                                      }
                                      title="Account name"
                                    >
                                      <span
                                        className={"text-muted"}
                                        style={{ fontSize: "0.8em" }}
                                      >
                                        {ellipsisString(fromAccountName, 50)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className={"col"}>
                                  <div
                                    className={
                                      "row g-0 d-flex align-items-center justify-content-between"
                                    }
                                    title="To Stellar account"
                                  >
                                    {shortPubkey(payment.to_account)}
                                  </div>
                                  {toAccountName && (
                                    <div
                                      className={
                                        "row g-0 d-flex align-items-center justify-content-between"
                                      }
                                      title="Account name"
                                    >
                                      <span
                                        className={"text-muted"}
                                        style={{ fontSize: "0.8em" }}
                                      >
                                        {ellipsisString(toAccountName, 50)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td>
                                {payment.asset_type === "native" ? (
                                  "XLM"
                                ) : (
                                  <span>{payment.asset_code}</span>
                                )}
                              </td>
                              <td>{payment.amount}</td>
                              <td>{payment.transaction.memo}</td>
                              <td>
                                <a
                                  href={`https://stellar.expert/explorer/${
                                    payment.testnet ? "testnet" : "public"
                                  }/op/${payment.stellar_payment_id}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                  title={"View in Stellar.Expert"}
                                >
                                  <img
                                    alt="Stellar Expert"
                                    height={18}
                                    src={"/stellar-expert-blue.svg"}
                                  />
                                </a>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Payments;

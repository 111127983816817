import { createContext } from "react";
import StellarPayState, { initialStellarPayState } from "./StellarPayState";
import AccountSnapshot from "./types/AccountSnapshot";
import BankAccount from "./types/BankAccount";
import CreateBankAccount from "./types/CreateBankAccount";
import CreatePayoutAccount from "./types/CreatePayoutAccount";
import CreateRemitoneCredentials from "./types/CreateRemitoneCredentials";
import CreateRemitoneHook from "./types/CreateRemitoneHook";
import CreateRemitoneOutboundCredentials from "./types/CreateRemitoneOutboundCredentials";
import CreateRemitoneOutboundHook from "./types/CreateRemitoneOutboundHook";
import CreateScheduledPayout from "./types/CreateScheduledPayout";
import CreateWallet from "./types/CreateWallet";
import Payment from "./types/Payment";
import PayoutAccount from "./types/PayoutAccount";
import Profile from "./types/Profile";
import RemitoneHook from "./types/RemitoneHook";
import RemitoneOutboundHook from "./types/RemitoneOutboundHook";
import RemitoneOutboundPayment from "./types/RemitoneOutboundPayment";
import RemitonePayment from "./types/RemitonePayment";
import ScheduledPayout from "./types/ScheduledPayout";
import Wallet from "./types/Wallet";

export interface StellarPayContextInterface extends StellarPayState {
  createWallet: (payload: CreateWallet) => Promise<Wallet>;
  deleteWallet: () => Promise<void>;
  createRemitoneHook: (payload: CreateRemitoneHook) => Promise<void>;
  updateRemitoneHook: (payload: RemitoneHook) => Promise<void>;
  deleteRemitoneHook: () => Promise<void>;
  hasRemitoneCredentials: () => Promise<boolean>;
  createRemitoneCredentials: (
    payload: CreateRemitoneCredentials
  ) => Promise<void>;
  deleteRemitoneCredentials: () => Promise<void>;
  createRemitoneOutboundHook: (
    payload: CreateRemitoneOutboundHook
  ) => Promise<void>;
  updateRemitoneOutboundHook: (payload: RemitoneOutboundHook) => Promise<void>;
  deleteRemitoneOutboundHook: () => Promise<void>;
  hasRemitoneOutboundCredentials: () => Promise<boolean>;
  createRemitoneOutboundCredentials: (
    payload: CreateRemitoneOutboundCredentials
  ) => Promise<void>;
  deleteRemitoneOutboundCredentials: () => Promise<void>;
  fetchAccountSnapshots: () => Promise<AccountSnapshot[]>;
  fetchPayments: () => Promise<Payment[]>;
  fetchRemitonePayments: () => Promise<RemitonePayment[]>;
  fetchRemitoneOutboundPayments: () => Promise<RemitoneOutboundPayment[]>;
  fetchBankAccounts: () => Promise<BankAccount[]>;
  createBankAccount: (payload: CreateBankAccount) => Promise<BankAccount>;
  fetchPayoutAccounts: () => Promise<PayoutAccount[]>;
  createPayoutAccount: (payload: CreatePayoutAccount) => Promise<PayoutAccount>;
  createScheduledPayout: (
    payload: CreateScheduledPayout
  ) => Promise<ScheduledPayout>;
  fetchScheduledPayouts: () => Promise<ScheduledPayout[]>;
  fetchProfile: () => Promise<void>;
  uploadProfileFile: (file: File, field: string) => Promise<Profile>;
  partialUpdateProfile: (id: string, payload: any) => Promise<Profile>;
  showAlertSuccess: (alertSuccess: string) => Promise<void>;
  showAlertError: (alertError: string) => Promise<void>;
  hideAlert: () => Promise<void>;
  getAccessToken: () => string;
}

/**
 * @ignore
 */
const stub = (): never => {
  throw new Error("You forgot to wrap your component in <StellarPayProvider>.");
};

/**
 * @ignore
 */
const initialContext = {
  ...initialStellarPayState,
  createWallet: stub,
  deleteWallet: stub,
  createRemitoneHook: stub,
  updateRemitoneHook: stub,
  deleteRemitoneHook: stub,
  hasRemitoneCredentials: stub,
  createRemitoneCredentials: stub,
  deleteRemitoneCredentials: stub,
  createRemitoneOutboundHook: stub,
  updateRemitoneOutboundHook: stub,
  deleteRemitoneOutboundHook: stub,
  hasRemitoneOutboundCredentials: stub,
  createRemitoneOutboundCredentials: stub,
  deleteRemitoneOutboundCredentials: stub,
  fetchAccountSnapshots: stub,
  fetchPayments: stub,
  fetchAsset: stub,
  fetchRemitonePayments: stub,
  fetchRemitoneOutboundPayments: stub,
  fetchBankAccounts: stub,
  createBankAccount: stub,
  fetchPayoutAccounts: stub,
  createPayoutAccount: stub,
  createScheduledPayout: stub,
  fetchScheduledPayouts: stub,
  fetchProfile: stub,
  uploadProfileFile: stub,
  partialUpdateProfile: stub,
  showAlertSuccess: stub,
  showAlertError: stub,
  hideAlert: stub,
  getAccessToken: stub,
};

const initialStellarPayContext: StellarPayContextInterface = initialContext;

const StellarPayContext = createContext<StellarPayContextInterface>(
  initialStellarPayContext
);

export default StellarPayContext;

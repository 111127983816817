import useStellarPay from "../useStellarPay";
import ContentLoader from "react-content-loader";
import * as React from "react";
import { useEffect, useState } from "react";
import WalletStatus from "../types/WalletStatus";
import { fetchAssetResults, getAssetKey } from "../utils";
import { Skeleton } from "@mui/material";
import { AssetCard, SmallSpan } from "./custom-styled-components";
import RefreshIcon from "@mui/icons-material/Refresh";
import { ReactComponent as Stellar } from "../Stellar.svg";
import AssetIconSvg from "./AssetIconSvg";
import AssetResults from "../types/AssetResults";

type BalanceOverviewProps = {
  marketAsset?: any; // StellarSdk.Asset
  marketAssetSymbol?: string;
  marketAssetDisplayDigits?: number;
  maxAssetsDisplayed?: number;
  className?: string;
  style?: React.CSSProperties;
};

const BalanceOverview: React.FC<BalanceOverviewProps> = (props) => {
  const {
    marketAsset,
    marketAssetDisplayDigits,
    marketAssetSymbol,
    maxAssetsDisplayed,
    ...rest
  } = props;
  const { wallet, walletStatus, getAccessToken } = useStellarPay();
  const [error, Set_error] = useState<any | undefined>(undefined);
  //const [balances, Set_balances] = useState<any[] | undefined>(undefined);
  const [horizonAccount, Set_horizonAccount] = useState<any | undefined>(
    undefined
  );
  const [assetResults, Set_assetResults] = useState<AssetResults>({});

  useEffect(() => {
    if (wallet && !error) {
      fetchAssetResults(
        true,
        getAccessToken(),
        wallet.testnet,
        wallet.account,
        Set_horizonAccount,
        Set_assetResults,
        Set_error,
        marketAsset
      );
    }
  }, [wallet, marketAsset, error, getAccessToken]);

  const refresh = async () => {
    if (wallet) {
      Set_horizonAccount(undefined);
      Set_assetResults({});
      Set_error(undefined);
      fetchAssetResults(
        false,
        getAccessToken(),
        wallet.testnet,
        wallet.account,
        Set_horizonAccount,
        Set_assetResults,
        Set_error,
        marketAsset
      );
    }
  };

  return (
    <div {...rest}>
      {error ? (
        <div className={"row g-0 d-flex align-content-between"}>
          <div className={"col d-flex justify-content-center text-danger pb-4"}>
            {`${error}`}
          </div>
        </div>
      ) : (
        <>
          {(walletStatus === WalletStatus.PENDING ||
            walletStatus === WalletStatus.FETCHING ||
            !horizonAccount) && (
            <div className={"row g-0 justify-content-evenly"}>
              <div className={"col-4 p-2"}>
                <ContentLoader viewBox="0 0 400 100" width={400} height={100}>
                  <circle cx="155" cy="40" r="22" />
                  <rect x="190" y="18" width="80" height="20" />
                  <rect x="190" y="42" width="60" height="20" />
                </ContentLoader>
              </div>
              <div className={"col-4 p-2"}>
                <ContentLoader viewBox="0 0 400 100" width={400} height={100}>
                  <circle cx="155" cy="40" r="22" />
                  <rect x="190" y="18" width="80" height="20" />
                  <rect x="190" y="42" width="60" height="20" />
                </ContentLoader>
              </div>
              <div className={"col-4 p-2"}>
                <ContentLoader viewBox="0 0 400 100" width={400} height={100}>
                  <circle cx="155" cy="40" r="22" />
                  <rect x="190" y="18" width="80" height="20" />
                  <rect x="190" y="42" width="60" height="20" />
                </ContentLoader>
              </div>
            </div>
          )}

          {wallet && horizonAccount && (
            <>
              <div
                className={
                  "row g-0 d-flex align-content-between pb-3 pt-1 pe-3"
                }
              >
                {horizonAccount.balances
                  .filter((balance: any, index: number) => {
                    return (
                      balance.asset_type === "credit_alphanum4" ||
                      balance.asset_type === "credit_alphanum12" ||
                      balance.asset_type === "native"
                    );
                  })
                  .map((balance: any, index: number) => {
                    if (
                      !!props.maxAssetsDisplayed &&
                      index >= props.maxAssetsDisplayed
                    ) {
                      return <div key={index}></div>;
                    }
                    const assetKey = getAssetKey(balance);
                    return (
                      <div
                        className={"col-12 col-xl-6 col-xxl-4 px-3 pt-3"}
                        key={index}
                      >
                        <AssetCard
                          className={"d-flex justify-content-start p-3"}
                        >
                          <div className={"row g-0"}>
                            <div
                              className={
                                "col d-flex align-items-center justify-content-center"
                              }
                            >
                              {balance.asset_type === "native" ? (
                                <Stellar
                                  width={40}
                                  height={40}
                                  fill={"black"}
                                />
                              ) : assetResults[assetKey]?.asset?.image ? (
                                <img
                                  src={`${process.env.REACT_APP_API_URL}${assetResults[assetKey]?.asset?.image}`}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = "/stellar.svg";
                                  }}
                                  alt={balance.asset_code}
                                  width={40}
                                  height={40}
                                />
                              ) : (
                                <AssetIconSvg
                                  width={40}
                                  height={40}
                                  assetCode={balance.asset_code}
                                />
                              )}
                            </div>
                            <div className={"col ps-3"}>
                              <div className={"row g-0"}>
                                <div className={"col"}>
                                  <SmallSpan
                                    className={
                                      "text-muted fs-6 text-nowrap pe-2"
                                    }
                                  >
                                    {balance.asset_type === "native"
                                      ? "XLM"
                                      : balance.asset_code}
                                  </SmallSpan>
                                  <SmallSpan
                                    className={"text-muted fs-6 text-nowrap"}
                                  >
                                    {balance.asset_type === "native"
                                      ? "—"
                                      : assetResults[assetKey]?.error
                                      ? `${assetResults[assetKey]?.error}`
                                      : assetResults[assetKey]?.asset
                                          ?.home_domain
                                      ? assetResults[assetKey]?.asset
                                          ?.home_domain
                                      : ""}
                                  </SmallSpan>
                                </div>
                              </div>
                              <div className={"row g-0"}>
                                <div
                                  className={"col d-flex align-items-center"}
                                >
                                  <SmallSpan className={"fs-4 text-nowrap"}>
                                    {assetResults[assetKey]?.asset
                                      ?.display_decimals !== undefined
                                      ? `${Number(balance.balance).toFixed(
                                          assetResults[assetKey]?.asset
                                            ?.display_decimals
                                        )}`
                                      : `${balance.balance}`}
                                  </SmallSpan>
                                  {marketAsset &&
                                    (assetResults[assetKey]?.marketAssetRate ===
                                    undefined ? (
                                      <Skeleton
                                        variant="rectangular"
                                        className={"px-4 d-inline ms-2"}
                                      />
                                    ) : (
                                      <SmallSpan
                                        className={
                                          "text-muted fs-6 text-nowrap ms-2"
                                        }
                                      >
                                        ≈
                                        {` ${
                                          marketAssetSymbol === undefined
                                            ? ""
                                            : marketAssetSymbol
                                        }`}
                                        {(
                                          Number(balance.balance) *
                                          Number(
                                            assetResults[assetKey]
                                              ?.marketAssetRate
                                          )
                                        ).toFixed(
                                          marketAssetDisplayDigits === undefined
                                            ? 2
                                            : marketAssetDisplayDigits
                                        )}
                                      </SmallSpan>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </div>
                        </AssetCard>
                      </div>
                    );
                  })}
              </div>
              <div className={"row g-0"}>
                <div
                  className={
                    "col ps-3 d-flex align-items-center justify-content-start"
                  }
                >
                  <div onClick={() => refresh()}>
                    <RefreshIcon
                      style={{ paddingLeft: "0.1em", cursor: "pointer" }}
                      sx={{ width: "0.7em" }}
                    />
                    <span style={{ fontSize: "0.8em", cursor: "pointer" }}>
                      Refresh Balances
                    </span>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default BalanceOverview;

import { Grid } from "@mui/material";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import PublicPagesHeader from "../components/PublicPagesHeader";

const TermsAndConditions = () => {
  return (
    <div>
      <PublicPagesHeader />
      <Container className="mt-4 mt-md-5" maxWidth="xl">
        <Grid container spacing={5}>
          <Grid item xs={12} md={6} className="mx-auto mb-5">
            <Typography variant="h5" component="h5" className="text-center mb-3">
              BP VENTURES LLC SaaS TERMS AND CONDITIONS OF SERVICES
            </Typography>

            <Typography variant="subtitle2" component="p">
              Thank you for selecting the Services offered by BP Ventures and/or its subsidiaries and affiliates
              (referred to as "BP Ventures", "we", "our", or "us"). Review these Terms of Service ("Agreement")
              thoroughly. This Agreement is a legal agreement between you and BP Ventures. By accepting electronically
              (for example, clicking "I Agree"), installing, accessing or using the Services, you agree to these terms.
              If you do not agree to this Agreement, then you may not use the Services.
            </Typography>

            <Typography variant="h6" component="h6" className="fw-bold mt-3">
              A. GENERAL TERMS
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold my-3">
              1. AGREEMENT
            </Typography>
            <Typography variant="body1" component="p">
              This Agreement describes the terms governing your use of the BP Ventures’ Software as a Service (SaaS)
              services provided to you on this website which may include BP PAY, BP Anchor in a BOX, and the BP DEFI
              DASHBOARD, including content, updates and new releases, (collectively, the "Services"). It includes by
              reference:
              <ul className="mt-2">
                <li>
                  BP Ventures’ Privacy Statement provided to you in the Services available on the website or provided to
                  you otherwise.
                </li>
                <li>Additional Terms and Conditions, which may include those from third parties.</li>
                <li>
                  Any terms provided separately to you for the Services, including product or program terms, ordering,
                  activation, payment terms, etc.
                </li>
              </ul>
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              2. YOUR RIGHTS TO USE THE SERVICES
            </Typography>
            <Typography variant="body1" component="p">
              2.1 The Services are protected by copyright, trade secret, and other intellectual property laws. You are
              only granted the right to use the Services and only for the purposes described by BP Ventures. BP Ventures
              reserves all other rights in the Services. Until termination of this Agreement and as long as you meet any
              applicable payment obligations and comply with this Agreement, BP Ventures grants to you a personal,
              limited, nonexclusive, nontransferable right and license to use the Services.
              <br />
              <br />
              2.2 You agree not to use, nor permit any third party to use, the Services or content in a manner that
              violates any applicable law, regulation or this Agreement. You agree you will not:
              <ul className="mt-2">
                <li>Provide access to or give any part of the Services to any third party.</li>
                <li>Reproduce, modify, copy, deconstruct, sell, trade or resell the Services.</li>
                <li>Make the Services available on any file-sharing or application hosting service.</li>
              </ul>
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              3. PAYMENT
            </Typography>
            <Typography variant="body1" component="p">
              For Services offered on a payment or subscription basis, the following terms apply, unless BP Ventures or
              its third party affiliate notifies you otherwise in writing. This Agreement also incorporates by reference
              and includes program ordering and payment terms provided to you on the website for the Services:
              <br />
              <br />
              <ol type="a" className="mt-2">
                <li>
                  Payments will be billed to you in U.S. dollars, and your account will be debited when you subscribe
                  and provide your payment information, unless stated otherwise in the program ordering or payment terms
                  on the website for the Services.
                </li>
                <br />
                <li>
                  You must pay with one of the following:
                  <ol>
                    <li>A valid credit card acceptable to BP Ventures;</li>
                    <li>A valid debit card acceptable to BP Ventures;</li>
                    <li>
                      Sufficient funds in a checking or savings account to cover an electronic debit of the payment due;
                      or
                    </li>
                    <li>By another payment option BP Ventures provides to you in writing.</li>
                  </ol>
                </li>
                <br />

                <li>
                  If your payment and registration information is not accurate, current, and complete and you do not
                  notify us promptly when such information changes, we may suspend or terminate your account and refuse
                  any use of the Services.
                </li>
                <br />

                <li>
                  If you do not notify us of updates to your payment method (e.g., credit card expiration date), to
                  avoid interruption of your service, we may participate in programs supported by your card provider
                  (e.g., updater services, recurring billing programs, etc.) to try to update your payment information,
                  and you authorize us to continue billing your account with the updated information that we obtain.
                </li>
                <br />

                <li>
                  BP Ventures will automatically renew your monthly, quarterly, or annual Services at the then-current
                  rates, unless the Services are cancelled or terminated under this Agreement.
                </li>
                <br />

                <li>
                  Additional cancellation or renewal terms may be provided to you on the website for the Services.
                </li>
              </ol>
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              4. USE WITH YOUR MOBILE DEVICE
            </Typography>
            <Typography variant="body1" component="p">
              Use of these Services may be available through a compatible mobile device, Internet access and may require
              software. You agree that you are solely responsible for these requirements, including any applicable
              changes, updates and fees as well as the terms of your agreement with your mobile device and
              telecommunications provider.
              <br />
              <br />
              BP VENTURES MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO:
              <ol type="i" className="mt-2">
                <li>
                  THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND ACCESS TO THE SERVICES AT ANY
                  TIME OR FROM ANY LOCATION;
                </li>
                <li>ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES; AND</li>
                <li>
                  ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS OR
                  SETTINGS CONNECTED WITH THE SERVICES.
                </li>
              </ol>
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              5. YOUR PERSONAL INFORMATION
            </Typography>
            <Typography variant="body1" component="p">
              You can view BP Venture’s Privacy Statement provided with the Services and on the website for the
              Services. You agree to the applicable BP Ventures’ Privacy Statement, and any changes published by BP
              Ventures. You agree that BP Ventures may use and maintain your data according to the BP Ventures Privacy
              Statement, as part of the Services. You give BP Ventures permission to combine information you enter or
              upload for the Services with that of other users of the Services and/or other BP Ventures services. For
              example, this means that BP Ventures may use your and other users' non-identifiable, aggregated data to
              improve the Services or to design promotions and provide ways for you to compare business practices with
              other users. BP Ventures is a global company and may access or store personal information in multiple
              countries, including countries outside of your own country to the extent permitted by applicable law.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              6. CONTENT
            </Typography>
            <Typography variant="body1" component="p">
              6.1 <b>You are responsible for your content.</b> You are responsible for all materials ("Content")
              uploaded, posted or stored through your use of the Services. You grant BP Ventures a worldwide,
              royalty-free, non-exclusive license to host and use any Content provided through your use of the Services.
              Archive your Content frequently. You are responsible for any lost or unrecoverable Content. You must
              provide all required and appropriate warnings, information and disclosures. BP Ventures is not responsible
              for the Content or data you submit through the Services.
              <br />
              <br />
              You agree not to use, nor permit any third party to use, the Services to upload, post, distribute, link
              to, publish, reproduce, engage in or transmit any of the following, including but not limited to:
              <ol type="a" className="mt-2">
                <li>
                  Illegal, fraudulent, defamatory, obscene, pornographic, profane, threatening, abusive, hateful,
                  harassing, offensive, inappropriate or objectionable information or communications of any kind,
                  including without limitation conduct that would encourage "flaming" others, or criminal or civil
                  liability under any local, state, federal or foreign law;
                </li>
                <li>
                  Content that would impersonate someone else or falsely represent your identity or qualifications, or
                  that constitutes a breach of any individual's privacy;
                </li>
                <li>
                  Except as permitted by BP Ventures in writing, investment opportunities, solicitations, chain letters,
                  pyramid schemes, other unsolicited commercial communication or engage in spamming or flooding;
                </li>
                <li>Virus, trojan horse, worm or other disruptive or harmful software or data; and</li>
                <li>
                  Any information, software or Content which is not legally yours and without permission from the
                  copyright owner or intellectual property rights owners.
                </li>
              </ol>
              6.2 <b>Community forums.</b> The Services may include a community forum or other social features to
              exchange Content and information with other users of the Services and the public. BP Ventures does not
              support and is not responsible for the content in these community forums. Please use respect when you
              interact with other users. Do not reveal information that you do not want to make public. Users may post
              hypertext links to content of third parties for which BP Ventures is not responsible.
              <br />
              <br />
              6.3 BP Ventures <b>may freely use feedback you provide.</b> You agree that BP Ventures may use your
              feedback, suggestions, or ideas in any way, including in future modifications of the Services, other
              products or services, advertising or marketing materials. You grant BP Ventures a perpetual, worldwide,
              fully transferable, sub-licensable, non-revocable, fully paid-up, royalty free license to use the feedback
              you provide to BP Ventures in any way.
              <br />
              <br />
              6.4 <b>BP Ventures may monitor your Content.</b> BP Ventures may, but has no obligation to, monitor
              content on the Services. We may disclose any information necessary to satisfy our legal obligations,
              protect BP Ventures or its customers, or operate the Services properly. BP Ventures, in its sole
              discretion, may refuse to post, remove, or refuse to remove, any Content, in whole or in part, alleged to
              be unacceptable, undesirable, inappropriate, or in violation of this Agreement.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              7. ADDITIONAL TERMS
            </Typography>
            <Typography variant="body1" component="p">
              7.1 <b>BP Ventures does not give professional advice.</b> Unless specifically included with the Services,
              BP Ventures is not in the business of providing legal, financial, accounting, tax, health care, real
              estate or other professional services or advice. Consult the services of a competent professional when you
              need this type of assistance.
              <br />
              <br />
              7.2 <b>We may tell you about other BP Ventures services.</b> You may be offered other services, products,
              or promotions by BP Ventures ("BP Ventures Services"). Additional terms and conditions and fees may apply.
              With some BP Ventures Services you may upload or enter data from your account(s) such as names, addresses
              and phone numbers, purchases, etc., to the Internet. You grant BP Ventures permission to use information
              about your business and experience to help us to provide the BP Ventures Services to you and to enhance
              the Services. You grant BP Ventures permission to combine your business data, if any, with that of others
              in a way that does not identify you or any individual personally. You also grant BP Ventures permission to
              share or publish summary results relating to research data and to distribute or license such data to third
              parties.
              <br />
              <br />
              7.3 <b>Communications.</b> BP Ventures may be required by law to send you communications about the
              Services or Third Party Products. You agree that BP Ventures may send these communications to you via
              email or by posting them on our websites
              <br />
              <br />
              7.4 <b>You will manage your passwords and accept updates.</b> You are responsible for securely managing
              your password(s) for the Services and to contact BP Ventures if you become aware of any unauthorized
              access to your account. The Services may periodically be updated with tools, utilities, improvements,
              third party applications, or general updates to improve the Services. You agree to receive these updates.
              <br />
              <br />
              7.5 <b>Telephone Numbers.</b> You may provide us with your telephone number as part of your customer
              record or registration or via other methods. You understand and agree that BP Ventures may use your
              telephone number for "multi-factor authentication" ("MFA"), to confirm your identity and help protect the
              security of your account. Part of the MFA identity verification process may involve BP Ventures sending
              text messages containing security codes to your telephone number. You agree to receive these texts from BP
              Ventures containing security codes as part of the MFA process. In addition, you agree that BP Ventures may
              send automated text messages and pre-recorded voice messages to the telephone number you provide for other
              limited purposes, including: providing you with important critical notices regarding your use of the
              Services, or fulfilling a request made by you through the Services.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              8. DISCLAIMER OF WARRANTIES
            </Typography>
            <Typography variant="body1" component="p">
              8.1 YOUR USE OF THE SERVICES, SOFTWARE, AND CONTENT IS ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN
              THIS AGREEMENT, THE SERVICES ARE PROVIDED "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, BP
              VENTURES, ITS AFFILIATES, AND ITS THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS
              (COLLECTIVELY,"SUPPLIERS") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY THAT THE
              SERVICES ARE FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, DATA LOSS, NON-INTERFERENCE WITH OR
              NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OR CONTENT IN
              OR LINKED TO THE SERVICES. BP VENTURES AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SERVICES
              ARE SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF THE EXCLUSIONS FOR
              IMPLIED WARRANTIES DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE DATE OF
              PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER.
              <br />
              <br />
              8.2 BP VENTURES, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF
              THE SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              9. LIMITATION OF LIABILITY AND INDEMNITY.
            </Typography>
            <Typography variant="body1" component="p">
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF BP VENTURES, ITS AFFILIATES AND
              SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR THE
              SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM. SUBJECT TO APPLICABLE LAW, BP VENTURES, ITS
              AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL,
              PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OF TELECOMMUNICATIONS, THE INTERNET,
              ELECTRONIC COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES, SPYWARE, LOSS OF
              BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF SOFTWARE OR HARDWARE THAT DOES NOT MEET BP VENTURES
              SYSTEMS REQUIREMENTS. THE ABOVE LIMITATIONS APPLY EVEN IF BP VENTURES AND ITS AFFILIATES AND SUPPLIERS
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF BP
              VENTURES, ITS AFFILIATES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS USE.
              <br />
              <br />
              You agree to indemnify and hold BP Ventures and its Affiliates and Suppliers harmless from any and all
              claims, liability and expenses, including reasonable attorneys' fees and costs, arising out of your use of
              the Services or breach of this Agreement (collectively referred to as "Claims"). BP Ventures reserves the
              right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any
              Claims. You agree to reasonably cooperate as requested by BP Ventures in the defense of any Claims.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              10. CHANGES.
            </Typography>
            <Typography variant="body1" component="p">
              We reserve the right to change this Agreement at any time, and the changes will be effective when posted
              through the Services, on our website for the Services or when we notify you by other means. We may also
              change or discontinue the Services, in whole or in part. Your continued use of the Services indicates your
              agreement to the changes.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              11. TERMINATION.
            </Typography>
            <Typography variant="body1" component="p">
              BP Ventures may, in its sole discretion and without notice, restrict, deny, terminate this Agreement or
              suspend the Services effective immediately, in whole or in part, for suspicion of fraud, security, illegal
              activity or unauthorized access issues to protect the integrity of our Services or systems and comply with
              applicable BP Ventures policy, or if you fail to comply with this Agreement or if you no longer agree to
              receive electronic communications. Upon termination you must immediately stop using the Services and any
              outstanding payments will become due. Any termination of this Agreement shall not affect BP Ventures’
              rights to any payments due to it. BP Ventures may terminate a free account at any time. Sections 2.2, 3
              through 15 will survive and remain in effect even if the Agreement is terminated.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              12. EXPORT RESTRICTIONS.
            </Typography>
            <Typography variant="body1" component="p">
              You acknowledge that the Services, including the mobile application, delivered by BP Ventures are subject
              to restrictions under applicable US export control laws, including US trade embargoes and sanctions and
              security requirements, and applicable country or local laws to the extent compatible with US laws. You
              agree that you will comply with these laws and regulations and will not export, re-export, import or
              otherwise make available products and/or technical data in violation of these laws, directly or
              indirectly.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              13. GOVERNING LAW.
            </Typography>
            <Typography variant="body1" component="p">
              Wyoming State law governs this Agreement without regard to its conflicts of laws provisions.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              14. DISPUTES.
            </Typography>
            <Typography variant="body1" component="p">
              ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THE SERVICES OR THIS AGREEMENT WILL BE RESOLVED BY BINDING
              ARBITRATION, RATHER THAN IN COURT, except that you may assert claims in small claims court if your claims
              qualify. The Federal Arbitration Act governs the interpretation and enforcement of this provision; the
              arbitrator shall apply Wyoming State law to all other matters. Notwithstanding anything to the contrary,
              any party to the arbitration may at any time seek injunctions or other forms of equitable relief from any
              court of competent jurisdiction. WE EACH AGREE THAT ANY AND ALL DISPUTES MUST BE BROUGHT IN THE PARTIES'
              INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
              PROCEEDING. BY ENTERING INTO THIS AGREEMENT AND AGREEING TO ARBITRATION, YOU AGREE THAT YOU AND BP
              VENTURES ARE EACH WAIVING THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU
              AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE
              THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS.
              <br />
              <br />
              To begin an arbitration proceeding, send a letter requesting arbitration and describing your claim to BP
              Ventures LLC., via its registered office address located at 30 North Gould Street, Suite 1883, Sheridan,
              WY, 82801, USA. Arbitration will be conducted by the American Arbitration Association (AAA) before a
              single AAA arbitrator under the AAA's rules, which are available at www.adr.org or by calling
              1-800-778-7879. Payment of all filing, administration and arbitrator fees and costs will be governed by
              the AAA's rules. Each Party shall bear its costs of the arbitration including attorney fees. If you lodge
              a claim which is adjudged as frivolous by the arbitrator, you shall reimburse bear our costs of the
              arbitration including attorney fees. You may choose to have the arbitration conducted by telephone, based
              on written submissions, or in person in the county where you live or at another mutually agreed location.
              The decision of the arbitrator shall be final and not appealable, and judgment on the arbitration award
              may be entered in any court having jurisdiction thereof. This Section 14 shall survive expiration,
              termination or rescission of this Agreement.
            </Typography>
            <Typography variant="h6" component="h6" className="fw-bold mt-4 mb-3">
              15. GENERAL.
            </Typography>
            <Typography variant="body1" component="p">
              This Agreement, including the Additional Terms below, is the entire agreement between you and BP Ventures
              and replaces all prior understandings, communications and agreements, oral or written, regarding its
              subject matter. If any court of law, having the jurisdiction, rules that any part of this Agreement is
              invalid, that section will be removed without affecting the remainder of the Agreement. The remaining
              terms will be valid and enforceable. The United Nations Convention on Contracts for the International Sale
              of Goods does not apply to this Agreement. You cannot assign or transfer ownership of this Agreement to
              anyone without written approval of BP Ventures. However, BP Ventures may assign or transfer it without
              your consent to (a) an affiliate, (b) a company through a sale of assets by BP Ventures or (c) a successor
              by merger. Any assignment in violation of this Section shall be void. If you want to request a transfer of
              this Agreement, contact BP Ventures via an email to:{" "}
              <a href="mailto:info@bpventures.us" className="text-decoration-none">
                <mark className="text-underline-none">info@bpventures.us</mark>
              </a>
              <br />
              <br />
              <b> August 2022</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
export default TermsAndConditions;

import * as React from "react";
import { createContext } from "react";

export const SidebarContext = createContext<SidebarContextProviderProps>({ mobileOpen: false });

export interface SidebarContextProviderProps {
  children?: React.ReactNode;
  mobileOpen?: Boolean;
  handleDrawerToggle?: () => void;
}

const SidebarContextProvider = (props: SidebarContextProviderProps) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return <SidebarContext.Provider value={{ mobileOpen, handleDrawerToggle }}>{props.children}</SidebarContext.Provider>;
};
export default SidebarContextProvider;

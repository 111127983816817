import { useAuth0 } from "@auth0/auth0-react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { FunctionComponent, useContext, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import CheckIcon from "@mui/icons-material/Check";
import "./Navbar.css";
import useStellarPay from "../useStellarPay";
import classnames from "classnames";
import { SidebarContext } from "../SidebarContext";
import { shortPubkey } from "../utils";
import { Badge, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

type NavbarProps = {
  title: string;
};

const Navbar: FunctionComponent<NavbarProps> = ({ title }) => {
  const { wallet } = useStellarPay();
  const { user, isAuthenticated, logout } = useAuth0();
  const { handleDrawerToggle } = useContext(SidebarContext);
  const [pubkeyCopied, Set_pubkeyCopied] = useState<boolean>(false);

  const onPubKeyCopy = () => {
    Set_pubkeyCopied(true);
    setTimeout(() => Set_pubkeyCopied(false), 3000);
  };

  return (
    <nav className={"navbar p-3 bg-white"} style={{ borderBottom: "solid #e7e7ef 2px" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: "none" } }}
      >
        <MenuIcon />
      </IconButton>
      <span className={"navbar-brand ps-4 fw-semibold"}>{title}</span>
      {isAuthenticated && (
        <div className={"ms-auto d-flex align-items-center justify-content-evenly"}>
          {wallet && (
            <div
              className={"bg-light border p-1 px-2 rounded-2 me-4 public-key-wrapper d-flex align-items-center"}
              title={"Wallet Stellar account - Click to copy"}
            >
              <div
                role="button"
                className={"public-key-full  "}
                onClick={() => {
                  navigator.clipboard.writeText(wallet.account);
                  onPubKeyCopy();
                }}
              >
                <div className={"fs-6 d-flex align-items-center"}>
                  <p
                    className={classnames("mb-0", {
                      "me-2 pe-1": wallet.testnet,
                    })}
                  >
                    {wallet.account}
                  </p>
                  {wallet.testnet && (
                    <Badge
                      classes={{ badge: "bg-secondary text-white" }}
                      component="div"
                      className="mx-4"
                      badgeContent="TESTNET"
                    />
                  )}
                </div>
              </div>
              <div className={"fs-6 public-key-partial align-items-center "}>
                <p
                  className={classnames("mb-0", {
                    "me-2 pe-1": wallet.testnet,
                  })}
                >
                  {shortPubkey(wallet.account)}
                </p>
                {wallet.testnet && (
                  <Badge
                    classes={{ badge: "bg-secondary text-white" }}
                    component="div"
                    className="mx-4 "
                    badgeContent="TESTNET"
                  />
                )}
              </div>
              <div
                title={"Copy Wallet account"}
                className={classnames("d-flex align-items-center", {
                  "ms-1": wallet.testnet,
                })}
              >
                <CopyToClipboard text={wallet.account} onCopy={onPubKeyCopy}>
                  {pubkeyCopied ? (
                    <CheckIcon className={"public-key-copy"} />
                  ) : (
                    <ContentCopyIcon className={"public-key-copy"} />
                  )}
                </CopyToClipboard>
                <a
                  href={`https://stellar.expert/explorer/${wallet.testnet ? "testnet" : "public"}/account/${
                    wallet.account
                  }`}
                  className="ms-2 d-flex"
                  rel="noopener noreferrer"
                  target="_blank"
                  title={"View in Stellar.Expert"}
                >
                  <img alt="Stellar Expert" height={19} src={"/stellar-expert-blue.svg"} />
                </a>
              </div>
            </div>
          )}
          <NotificationsNoneOutlinedIcon />
          <div className={"dropdown ms-4"}>
            <span
              className={"dropdown-toggle"}
              id={"navbar-user-dropdown"}
              data-bs-toggle={"dropdown"}
              aria-expanded={"false"}
            >
              <img src={user!.picture} alt={"User profile"} width={"35"} height={"35"} className={"rounded-circle"} />
            </span>
            <ul className={"dropdown-menu dropdown-menu-end"} aria-labelledby={"navbar-user-dropdown"}>
              <li className={"bg-light"}>
                <p className={"m-0 p-3"}>
                  Logged in as:
                  <br />
                  <strong>{user!.email}</strong>
                </p>
                <hr style={{ margin: "8px 0" }} />
              </li>
              <li>
                <button
                  style={{ border: "none" }}
                  className={"dropdown-item d-flex align-items-center"}
                  onClick={() => logout()}
                >
                  <LogoutIcon />
                  <span className={"ms-2"}>Logout</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

import { Skeleton } from "@mui/material";
import { Navigate } from "react-router-dom";
import BalanceGraph from "../components/BalanceGraph";
import BalanceOverview from "../components/BalanceOverview";
import Payments from "../components/Payments";
import WalletStatus from "../types/WalletStatus";
import useStellarPay from "../useStellarPay";

declare var StellarSdk: any;

const DashboardPage = () => {
  const { wallet, walletStatus, walletError } = useStellarPay();
  return (
    <>
      {walletStatus === WalletStatus.ERROR && walletError && (
        <div className={"row g-0"}>
          <div className={"col px-5 pt-4 pb-0"}>
            <span className={"text-danger"}>{walletError}</span>
          </div>
        </div>
      )}
      {walletStatus === WalletStatus.FETCHED && !wallet && (
        <Navigate to="/get-started" />
      )}
      {(walletStatus === WalletStatus.PENDING ||
        walletStatus === WalletStatus.FETCHING) && (
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={200}
          className={"m-4"}
        />
      )}
      {walletStatus === WalletStatus.FETCHED && wallet && (
        <>
          <div className={"col"}>
            <BalanceOverview
              maxAssetsDisplayed={6}
              marketAsset={
                new StellarSdk.Asset(
                  "USDC",
                  "GA5ZSEJYB37JRC5AVCIA5MOP4RHTM335X2KGX3IHOJAPP5RE34K4KZVN"
                )
              }
              marketAssetDisplayDigits={2}
              marketAssetSymbol={"$"}
            />
            <Payments className={"component-card"} />
            <BalanceGraph className={"component-card"} />
          </div>
        </>
      )}
    </>
  );
};

export default DashboardPage;

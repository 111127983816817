import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import classnames from "classnames";
import { FunctionComponent, useState } from "react";
import { COUNTRIES } from "../../../countries";
import Country from "../../../types/Country";
import "./AddPayoutAccountForm.css";
import AddPayoutBankWireUsForm from "./AddPayoutBankWireUsForm";

type AddPayoutAccountFormProps = {
  onBack: (e: unknown) => void;
  onSaved: () => void;
};

const AddPayoutAccountForm: FunctionComponent<AddPayoutAccountFormProps> = ({
  onBack,
  onSaved,
}) => {
  const [saving, Set_saving] = useState<boolean>(false);
  const [type, Set_type] = useState<string | undefined>(undefined);
  const [bankAccountType, Set_bankAccountType] = useState<string | undefined>(
    undefined
  );
  const [bankCountry, Set_bankCountry] = useState<string | undefined>(
    undefined
  );
  return (
    <div
      className={classnames("col-8", {
        disabled: saving,
      })}
      style={{ paddingLeft: "2.5rem", paddingTop: "2rem" }}
    >
      <div className={"row g-0 pb-3"}>
        <div className={"col"}>
          <div className="arrow-title-container">
            <div onClick={(e: any) => onBack(e)}>
              <ArrowBackIcon className="arrow-back-icon" />
            </div>
            <h1 className={"fs-5 fw-bold"}>Add Payout Account</h1>
          </div>
        </div>
      </div>
      <div className={"row g-0"}>
        <div className="col-12 col-sm-6 pe-3">
          <FormControl className={"mb-3 w-100"}>
            <InputLabel id="payout-type-label">Type</InputLabel>
            <Select
              labelId="payout-type-label"
              id="payout-type"
              value={type ?? ""}
              label="Type"
              onChange={(e) => {
                Set_type(e.target.value);
              }}
            >
              <MenuItem value={"bank"}>Bank Account</MenuItem>
            </Select>
            <FormHelperText>Payout account type</FormHelperText>
          </FormControl>
        </div>
        {type === "bank" && (
          <div className="col-12 col-sm-6">
            <FormControl className={"mb-3 w-100"}>
              <InputLabel id="payout-bank-type-label">
                Bank Account Type
              </InputLabel>
              <Select
                labelId="payout-bank-type-label"
                id="payout-bank-type"
                value={bankAccountType ?? ""}
                label="Bank Account Type"
                onChange={(e) => {
                  Set_bankAccountType(e.target.value);
                }}
              >
                <MenuItem value={"BANK_WIRE"}>Wire</MenuItem>
              </Select>
              <FormHelperText>Bank Account Type</FormHelperText>
            </FormControl>
          </div>
        )}
      </div>
      {bankAccountType === "BANK_WIRE" && (
        <div className={"row g-0"}>
          <div className="col-12 col-sm-6 pe-3">
            <FormControl className={"mb-3 w-100"}>
              <InputLabel id="add-payout-account-form-bank-country-label">
                Country of Bank Domicile
              </InputLabel>
              <Select
                labelId="add-payout-account-form-bank-country-label"
                id="add-payout-account-form-bank-country"
                value={bankCountry ?? ""}
                label="Country of Bank Domicile"
                onChange={(e) => {
                  Set_bankCountry(e.target.value);
                }}
              >
                {COUNTRIES.map((country: Country, index: number) => {
                  return (
                    <MenuItem value={country.iso3} key={index}>
                      {country.name} ({country.iso2})
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>Country of Bank Domicile</FormHelperText>
            </FormControl>
          </div>
        </div>
      )}
      {bankAccountType === "BANK_WIRE" && bankCountry === "USA" && (
        <AddPayoutBankWireUsForm
          onBack={onBack}
          onSaving={() => Set_saving(true)}
          onSaved={() => {
            Set_saving(false);
            onSaved();
          }}
          onError={() => Set_saving(false)}
        />
      )}
    </div>
  );
};

export default AddPayoutAccountForm;

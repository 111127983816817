import styled from "styled-components";
import classnames from "classnames";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React, { FunctionComponent, ReactNode } from "react";
import "./SettingsMenuItem.css";

const ItemLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const Item = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 3.5rem;

  &:hover {
    background-color: #edf1ff;
    border-radius: 10px;
  }
`;

type PageItemProps = {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  isSelected: boolean;
  icon: ReactNode;
  title: string;
  arrow: boolean;
  linkTo?: string;
};

const SettingsMenuItem: FunctionComponent<PageItemProps> = (props) => {
  const children = (
    <>
      <div className={"p-0"} style={{ width: 35 }}>
        {props.icon}
      </div>
      <div style={{ width: 150 }}>
        <h1 className={"fs-6 mb-0"}>{props.title}</h1>
      </div>
      <div style={{ width: 10 }}>
        {props.arrow && <ArrowForwardIosIcon sx={{ fontSize: "1em" }} />}
      </div>
    </>
  );
  return (
    <li>
      {props.linkTo ? (
        <Item>
          <ItemLink
            href={props.linkTo}
            target="_blank"
            rel="noopener noreferrer"
            className={classnames("row me-1 px-2 py-2", {
              "page-item-selected": props.isSelected,
            })}
          >
            {children}
          </ItemLink>
        </Item>
      ) : (
        <Item
          onClick={props.onClick}
          className={classnames("row me-1 px-2 py-2", {
            "page-item-selected": props.isSelected,
          })}
        >
          {children}
        </Item>
      )}
    </li>
  );
};

export default SettingsMenuItem;

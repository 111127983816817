import { FunctionComponent } from "react";
import * as React from "react";

type AssetIconSvgProps = {
  width: number;
  height: number;
  assetCode: string;
};

const AssetIconSvg: FunctionComponent<AssetIconSvgProps> = (props) => {
  return (
    <svg height={40} width={40}>
      <g>
        <circle cx="20" cy="20" r="20" fill="lightgray"></circle>
        <text
          x="50%"
          y="50%"
          textAnchor="middle"
          stroke="black"
          strokeWidth="1px"
          dy=".3em"
        >
          {props.assetCode[0]}
        </text>
      </g>
    </svg>
  );
};


export default AssetIconSvg;
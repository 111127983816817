async function fetchApikey(token: string): Promise<string> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/apikey/`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { apikey, detail } = await response.json();
  if (response.ok) {
    return apikey;
  } else {
    return Promise.reject(detail);
  }
}

async function generateApikey(token: string): Promise<string> {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/apikey/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const { apikey, detail } = await response.json();
  if (response.ok) {
    return apikey;
  } else {
    return Promise.reject(detail);
  }
}

export { fetchApikey, generateApikey };

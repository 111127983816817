export class InvalidPayloadError {
  [index: string]: string;
}

export const invalidPayloadErrorFromJsonResponse = (
  payload: object,
  resp: any
): InvalidPayloadError => {
  const error: InvalidPayloadError = new InvalidPayloadError();
  Object.keys(payload).forEach((key) => {
    if (key in resp) {
      error[key] = resp[key];
    }
  });
  if (!error) {
    throw resp;
  }
  return error;
};

export default InvalidPayloadError;
